module.exports = {
  i18nn_38d84b35d4836a92:"When created",
  i18nn_246e8486702689b8:"Initialize wechat sharing",
  i18nn_c074db443e169104:"Monitor route changes",
  i18nn_5f5d2d5861866f89:"Avoid not getting it for the first time",
  i18nn_70e6e0b7ca198366:"If you use",
  i18nn_58d2c1bf5829535c:"Rewriting of existing page",
  i18nn_7060ca2fc8279505:"So I suggest that you",
  i18nn_4b0727f8db77be1d:"Of",
  i18nn_373222bafdc59d8c:"Call before executing statistical code",
  i18nn_f4b9a92ed6eba312:"The page will be automatically",
  i18nn_7f60c280ca3cf89e:"Statistics off",
  i18nn_f47ba4ab8f8737c3:"Prevent page traffic from being counted",
  i18nn_1e6fc9d258352566:"Used to send a",
  i18nn_ad66ec7a4a1379a4:"Statistics request",
  i18nn_7f8cc5c86bdabe1c:"Execute the first time you enter the page",
  i18nn_470bbdcc42b395ec:"Select commodity category",
  i18nn_f0306a16ca426bbd:"Optional levels",
  i18nn_005085930f991fb0:"Level of drop down display",
  i18nn_3d18b30564d305d7:"Primary classification data",
  i18nn_2eccef01f0c2b163:"Before compiling and mounting",
  i18nn_826e79803d1bc2fe:"Option box size",
  i18nn_f0584015c235d1c1:"Layout size",
  i18nn_a544f827b30a69f4:"Layout interval",
  i18nn_43dc54253f7448e0:"Initial assignment",
  i18nn_d6f79d965aae9814:"Value change",
  i18nn_b9707d860db20227:"Select first level classification",
  i18nn_4c4e546aa3683ff5:"empty",
  i18nn_94b44280fa1d5493:"Empty operation",
  i18nn_00384cd4bb9a2292:"Trigger parent component method",
  i18nn_9a3c1c445117dbb4:"Select secondary classification",
  i18nn_59c682bd0cf21bca:"Qingkong District",
  i18nn_998f2f4329ec0971:"Empty the warehouse",
  i18nn_0897e206b059fa49:"Display district and county",
  i18nn_f8ca169fd7bcd7b8:"Display warehouse",
  i18nn_7fb6ada66a1fccca:"choice",
  i18nn_0298aa6682db2d0d:"First level classification information",
  i18nn_3401b0539851b242:"Category request successful",
  i18nn_758239e08104dfb8:"Product category request failed",
  i18nn_7e17672ef3d69a37:"Secondary classification information",
  i18nn_c08547dbd9309a2e:"empty",
  i18nn_36fc2cb9f1efdfd9:"Secondary classification information request successful",
  i18nn_37cfdcf4c24cce7a:"Error in secondary category request",
  i18nn_50651d1991159001:"Secondary classification information request failed",
  i18nn_add07e4854572ed2:"Category 3",
  i18nn_b1f5481a8729a352:"Level 3 category request successful",
  i18nn_3a7d834707ea72d3:"Level 3 category request failed",
  i18nn_29799f539ee6b906:"Please input mobile phone number",
  i18nn_2f647e039073d7d4:"Please select the international code",
  i18nn_fbc06cfe55187764:"Search your country and region",
  i18nn_7dff8d8ff4a48380:"Common countries",
  i18nn_e691da78ee2e02f5:"region",
  i18nn_cc68ee555944f23d:"manufactor",
  i18nn_31334768d4d5f7cc:"personal",
  i18nn_7c16b2aaeb0ee6e7:"Mobile area code",
  i18nn_09a6f3a7fdcdaae1:"Mobile phone area code succeeded",
  i18nn_6b0ab3e65c3be5f6:"Phone area code request failed",
  i18nn_77616a6cdefbb60c:"Switch to hot",
  i18nn_1e7246dd6ccc5539:"search",
  i18nn_bd2c6213d88d3688:"Input filter",
  i18nn_907adf16d5a6e3f3:"Access to bank cash withdrawal information",
  i18nn_b41baf4161dcbf7a:"Successful bank cash withdrawal",
  i18nn_328771b9159af957:"congratulations",
  i18nn_c4c80676f41cada0:"Successful withdrawal application for bank withdrawal method",
  i18nn_31fa870d0b559f50:"Processing",
  i18nn_204ffab8a6e01870:"determine",
  i18nn_8e7225aa0e809856:"Failed to obtain bank cash withdrawal method",
  i18nn_4eb3309d6692dbd4:"Please try again",
  i18nn_0cf5a5f3ee03c180:"Scroll bar overall style",
  i18nn_7871bf9ad50c6f22:"The height and width correspond to the size of the horizontal and vertical scroll bars respectively",
  i18nn_4fe8f1059a031171:"Small square in scroll bar",
  i18nn_7d4288aac4b8006b:"Scroll bar inside track",
  i18nn_543a597c8b5342e7:"Province data",
  i18nn_9075502784cc4b3e:"Select Province",
  i18nn_c7fee137468893c7:"Empty the city",
  i18nn_e6b1c2a1fc07e7d3:"Choose a city",
  i18nn_82808948d26efcf1:"Select districts and counties",
  i18nn_c6467851c112980c:"Select warehouse",
  i18nn_f74a3a59a7d5364e:"Province Information",
  i18nn_7557b5b6a660da92:"Province information read successfully",
  i18nn_d9b86f5c1d349e5c:"Province information request failed",
  i18nn_5ec78bef9d15434f:"Province information request successful",
  i18nn_538606c127a812d7:"Failed to get province information",
  i18nn_36354660b7fc15b1:"City Information",
  i18nn_ccf8300d69affc7a:"City information request successful",
  i18nn_271b7085dfabfab6:"City information request failed",
  i18nn_ce6f99466b1a54f0:"District and County Information",
  i18nn_205964e5f8c20982:"District and county information request successful",
  i18nn_996c5bb9df173125:"District / county information request failed",
  i18nn_9f969e07af219e35:"Warehouse information",
  i18nn_862c7231ea467e38:"Warehouse information request successful",
  i18nn_de8931d48511795c:"Warehouse information request failed",
  i18nn_f04d208f84e6c343:"province",
  i18nn_e05b5d049b64b040:"city",
  i18nn_6773ddd95bc0757b:"area",
  i18nn_92de017242c28a56:"county",
  i18nn_c944a6686d996ab3:"Warehouse",
  i18nn_ab46b83080b1bef8:"Failed to get city information",
  i18nn_599c4ec0106e9ec1:"Failed to get district and County Information",
  i18nn_084ee4095468a454:"Failed to get warehouse information",
  i18nn_1ecc8be9ba1c940a:"Click upload",
  i18nn_42a17cc85f956d55:"Select file",
  i18nn_a67037f1a5e99c8d:"Upload to server",
  i18nn_2f75d79503294e52:"Upload file size cannot exceed",
  i18nn_35c31bde1e2c8b2c:"be careful",
  i18nn_c0e3a584888c5ea6:"Up to upload",
  i18nn_c70b6428abb8c93d:"piece",
  i18nn_3a74667815adc8bf:"maximum",
  i18nn_24799f4c6ce60dbd:"Please select the file and click upload to the server",
  i18nn_ad4f1f9c62389f04:"Limited size per file",
  i18nn_46ddcffae70a5c3b:"Empty picture",
  i18nn_46312513d60a4d12:"Override default upload behavior",
  i18nn_f815e09651e471f8:"Implementation of custom upload",
  i18nn_dffbf7d48cf860a2:"Upload options",
  i18nn_7d35d659ad32a051:"No return",
  i18nn_97e0de817d74acab:"obtain",
  i18nn_874b22782fe58a33:"configuration information",
  i18nn_b3742c8e82f10c3e:"Parameter failed",
  i18nn_904b1edd118e1b35:"Upload files in pieces",
  i18nn_02a03d22b029f499:"Call here",
  i18nn_2e4e32c5dd570bfa:"Twice",
  i18nn_7f2f052a5da435ca:"upload",
  i18nn_5fba7f16556a9197:"Hook when file status changes",
  i18nn_2a50e3872522d075:"Add file",
  i18nn_8389ed823b47a9c9:"Both successful and failed uploads will be called",
  i18nn_a974598ef531812b:"Do not check format",
  i18nn_d9d6266f40f67534:"Unknown file",
  i18nn_21508344d0cf56ec:"Appears when uploading repeatedly",
  i18nn_486db80b86de2c1a:"Function of successful file upload",
  i18nn_d34e97bbd9523da6:"by",
  i18nn_99d6111748eae069:"The elements of",
  i18nn_b15f1d04b9c614ee:"Is the subscript",
  i18nn_dac6d2e1b7f9595e:"Original array",
  i18nn_f5797a71c8dc42f6:"Number of files and return",
  i18nn_b1e0fb9c9e07cee6:"When the numbers are equal",
  i18nn_5106bcad470f79d3:"Multiple images will call the successful method repeatedly",
  i18nn_b9143f93c45fee7f:"Remove duplicate",
  i18nn_669600b917b45f04:"Method implementation code is quite cool",
  i18nn_563f7ec62931b0d7:"Realization idea",
  i18nn_a1792e462520f7a7:"Get the rightmost value without repetition and put it into a new array",
  i18nn_5e981dea2122a654:"When duplicate values are detected, the current loop is terminated and the next round of judgment of the top loop is entered",
  i18nn_58179f501fd44ca0:"Hook before uploading file",
  i18nn_63bc87384ee1bdc5:"The parameter is the uploaded file",
  i18nn_a7c0a534a79af36b:"If returned",
  i18nn_410278c6a1528e67:"Or go back",
  i18nn_9f7ace149cfcb06d:"And was",
  i18nn_48fbbc37d0b71cb7:"Then stop uploading",
  i18nn_d60a3f24e8329e20:"less than",
  i18nn_027eacedab3f8d19:"Picture size cannot be larger than",
  i18nn_b180cd998ccbe52e:"The number of files exceeds the limit",
  i18nn_987cf2f4c71691ee:"Hook when the number of files exceeds the limit",
  i18nn_10a3c6e1c105c148:"File deletion",
  i18nn_dbcffdf31e86d2d8:"sort",
  i18nn_04ea6d4ff8e5c4bb:"most",
  i18nn_09049355527ef6a2:"Zhang",
  i18nn_dfd0cad4cb8eb442:"support",
  i18nn_b379748a6ce5496b:"format",
  i18nn_f5bcb37f7eeeb184:"Picture upload to server",
  i18nn_925ae664f039f3de:"Drag sort",
  i18nn_ffe5914bcfae348c:"Picture sorting",
  i18nn_5ff6dd524e285e56:"Drag layer",
  i18nn_67fa4fa1b234d4a7:"Please drag and drop pictures to sort",
  i18nn_a6dcecbe146242e2:"Confirm sort",
  i18nn_4b3340f7872b411f:"cancel",
  i18nn_6ae6dd62d24bcb8a:"file type",
  i18nn_f156a9536049f461:"file",
  i18nn_229b11e2f431750a:"Alicloud",
  i18nn_7dcf1197e1d2108c:"File list",
  i18nn_cc8603c183589ab1:"Listen for change initializable",
  i18nn_31439cad15cc246e:"Number of files that can be uploaded",
  i18nn_42d2e83f1c79aa4d:"Container width",
  i18nn_7499c732802bf588:"Picture pop up display",
  i18nn_cbf80fed884d4aad:"Drag plug-ins are not recommended to change the value of the parent component directly",
  i18nn_1e1ed96b29e55429:"So create a new array",
  i18nn_9832bb6f43f34fc5:"Save the array sorted before dragging on",
  i18nn_3421132d2b09ec1d:"Drag on switch",
  i18nn_695da70c3f20778b:"Open drag",
  i18nn_4b543fe0e4c09ea9:"Array deep copy",
  i18nn_df0c51c85504148f:"Undo drag",
  i18nn_3a09b6f334222d7c:"Drag save",
  i18nn_6f579f3a8a1d652f:"Roll slowly",
  i18nn_a3851d8f06926278:"Scroll to the top",
  i18nn_7b108cdca9ee7832:"variable",
  i18nn_25a0d7bacb0096bc:"roll",
  i18nn_f37e9981ad7d9969:"Each breakpoint continuation",
  i18nn_63281f364a610887:"It's not just one picture at a time",
  i18nn_781b09010d31b8f9:"Get pictures",
  i18nn_e7bf2fd407589e91:"Selected picture information",
  i18nn_cdd9a1a7c74dfcfc:"Reading computer file information asynchronously",
  i18nn_d4263545654ce610:"use",
  i18nn_28d985ad0af33256:"Compress picture and convert",
  i18nn_bebed2749046a347:"Convert picture to",
  i18nn_54379cfde85bc70c:"And compress",
  i18nn_5a95a47cf6e694e7:"Indicates that cross domain is allowed",
  i18nn_fa6b7849ba570629:"Convert picture pixel size",
  i18nn_02ccd2cf723f9272:"Yes",
  i18nn_e676df0ca86bb7ae:"Drawing patterns",
  i18nn_00b3af4c32634497:"adopt",
  i18nn_246c1c720a155951:"How to get pictures",
  i18nn_eaf2cb6a7e187ada:"Convert file",
  i18nn_9e0768039755bdd6:"take",
  i18nn_0598f6eca0da5aa1:"Convert to file",
  i18nn_b7a11317ab65382f:"decode",
  i18nn_aa89371f7759a327:"One more",
  i18nn_36ff068066920375:"do",
  i18nn_0f27274d94b7fffb:"transcoding ",
  i18nn_b49b41c94f40954a:"Convert to document",
  i18nn_33e1668b0e24f46f:"Image compression method",
  i18nn_d85b6a83b6f57b2d:"compress",
  i18nn_dfdc82bdeed15ed0:"Minimum compression",
  i18nn_2364f1faf538b459:"After compression, the calling method is carried out.",
  i18nn_602b175d07c4544a:"turn",
  i18nn_14c1c5156019ed76:"The method is written below",
  i18nn_7c805dc66bdc9ed8:"object",
  i18nn_5f690db8971ba973:"Automatic compression",
  i18nn_662d5f7574a5d59b:"Determine whether the image size is less than",
  i18nn_fa56f9fedf271c51:"Call method to get",
  i18nn_e1ac5c1843d0185e:"Define scroll bar tracks",
  i18nn_73f96ab3340fe950:"Inner shadow",
  i18nn_3dca70270d1a74e9:"fillet",
  i18nn_8a8f72a194eeb1f9:"Define slider",
  i18nn_49344113a3b43b1b:"No",
  i18nn_449b3774806c33c3:"overall situation",
  i18nn_260a8c930f333201:"plus",
  i18nn_0a2fdbe73decdee3:"Non Global",
  i18nn_71229f26cb88e30e:"Check format",
  i18nn_a05ac587acc6763a:"Verify file size",
  i18nn_f6e62f9642018cbb:"Home",
  i18nn_e56ddb7ad8d6586e:"Last page",
  i18nn_8e2be23dd3b81a31:"information",
  i18nn_6a82fdcc3fecddc2:"use",
  i18nn_c8840b2ed17fc9ee:"Exchange the world",
  i18nn_1b499d5c744131c7:"Manufacturer sharing platform",
  i18nn_1dc4a69b5a4078eb:"Shopping hall",
  i18nn_8421526d274c0341:"cotton",
  i18nn_5354b8b40c686fc4:"Bound value",
  i18nn_b742c82472e2ef35:"Tabular data",
  i18nn_8ccadbd008827b22:"Paging data",
  i18nn_9eefb7335988c499:"query",
  i18nn_42abc1e524ae69dd:"sort order",
  i18nn_41f871978d079858:"sort field",
  i18nn_229f6b78d63e1bac:"Descending order",
  i18nn_2536c7b6fb36b80c:"Ascending order",
  i18nn_bbc49a6941f59913:"classification",
  i18nn_b8d1610b4726176f:"Product Name",
  i18nn_b3853278ad6d5611:"Picking type",
  i18nn_05e29fb623110c69:"Batch number",
  i18nn_ec834692ec6c8e38:"colour",
  i18nn_8d82ee4ab711fb75:"Better than",
  i18nn_ff8a4206aa17e719:"Micronaire value",
  i18nn_6cc2563839443db8:"Resurgence",
  i18nn_73ecfc7be38d4bcf:"Heterogenous",
  i18nn_0bffd1282cb3456e:"Place of delivery",
  i18nn_266d5c5e4cd4a9f2:"Minimum price range",
  i18nn_b0301d3bab660115:"The largest price range",
  i18nn_0d76cd7a958441df:"Changes in the number of displays per page",
  i18nn_dd3b072647434206:"each page",
  i18nn_117aa3f27e5e430c:"strip",
  i18nn_8dfb22636adecc2a:"Number of pages",
  i18nn_b18897c10c4eae3f:"Request data",
  i18nn_abc7a3d149718367:"It's triggered manually here",
  i18nn_6509d9c0082bc83c:"Pagination update",
  i18nn_b5a8e089049df396:"event",
  i18nn_c904eca04ed8d05c:"And pass the value in",
  i18nn_9214ca0902929e8e:"Page number change",
  i18nn_c936d3b74e41b901:"Current page",
  i18nn_8d369ba038658da9:"Request paging data",
  i18nn_2f5702841ed342ab:"Number of current page displays",
  i18nn_168c2364555142eb:"Commodity status",
  i18nn_5780e6a092c399c3:"Off the shelf",
  i18nn_e0199d6ecc3ee81a:"normal",
  i18nn_38e8c4d45d6ca3c1:"In stock for sale",
  i18nn_ed2b2c9b6ff98b31:"Violation",
  i18nn_31e8ec91f4c1e966:"forbid the sale",
  i18nn_303124d23b20ec0a:"Commodity audit",
  i18nn_91cfca219e1b41ce:"No",
  i18nn_f2d95c318721642c:"Under review",
  i18nn_2e3ee00867931849:"Market type",
  i18nn_4cf78c5cac4a485f:"Trade in bulk",
  i18nn_25e25543c26526d5:"Matchmaker Market",
  i18nn_674e5faed21eb561:"Cotton sorting",
  i18nn_776496ec446f21f9:"Request successful",
  i18nn_4217bc1acbacd2ba:"Table display data",
  i18nn_bc4bced036fdb495:"Total number of current data",
  i18nn_2854e5bf29a64ed3:"Current pages",
  i18nn_d34cf82368ba6d89:"Current number of pages",
  i18nn_323ee425eca7208c:"request was aborted",
  i18nn_ee5a0b69f9323d4a:"Bold",
  i18nn_d656c6a9c3cbd795:"Italics",
  i18nn_15881fe9a4aa9352:"Underline",
  i18nn_412470089bec1dfd:"Delete line",
  i18nn_c812fd61ee56d868:"quote",
  i18nn_21414c06d3e4ff2c:"code",
  i18nn_7431e76029678ec7:"title",
  i18nn_57a7de477b2ebe97:"Ordered list",
  i18nn_49132db903eec70f:"Unordered list",
  i18nn_4b9484089a14fff2:"format paragraph",
  i18nn_653d92fa8f1011d3:"paragraph",
  i18nn_32badce0902c46a7:"font size",
  i18nn_d48da5af833f1c63:"typeface",
  i18nn_dbd7312ad2825e55:"Font color",
  i18nn_03e7724664b15858:"background color ",
  i18nn_2c6b89b19200de97:"alignment",
  i18nn_5ed646cd1d97c310:"Hyperlinks",
  i18nn_104709397ba68e7e:"form",
  i18nn_33d4cfe5cfd81678:"Compatibility issues",
  i18nn_2674282277c3714e:"picture",
  i18nn_f5817042904f4a12:"video",
  i18nn_407a5bd47a532136:"reduction",
  i18nn_27ae8de28d3c3821:"View the source code",
  i18nn_04e722a308964860:"preview",
  i18nn_fae4e00e66853b23:"Currently entered",
  i18nn_4ebc25f9925a902b:"Characters",
  i18nn_daaaeb1b7b22b126:"Tips",
	daaaeb1b7b22b126:"Tips",
  i18nn_6576ce14f2a92b83:"Remember to click after sorting",
  i18nn_a0cf2fef18330d96:"How to add more than one picture at a time",
  i18nn_cc504b75f45b6c8e:"system",
  i18nn_f9f65e4fadee5183:"mode",
  i18nn_92988957fcbb8456:"key",
  i18nn_ed635542b3fb8fba:"Select multiple pictures",
  i18nn_754c78f5c569030e:"Mouse drag",
  i18nn_5f612e85ebd5642e:"Select picture and drag to",
  i18nn_38f3e4e3906f4cde:"Office No",
  i18nn_513129bb8856e39f:"Yes",
  i18nn_138162c29671c3cd:"set",
  i18nn_fbf379655e68a5a2:"Article source code",
  i18nn_4e9fc68608c60999:"close",
  i18nn_c517fbaec10de990:"Rich text editor style",
  i18nn_3f0ecebbd9d2f23b:"available",
  i18nn_c388459f4b4d4b1a:"however",
  i18nn_5811faae5c52bdc0:"Mobile terminal",
  i18nn_88f9b3417c8cba24:"Applets",
  i18nn_750494addd790b4a:"When the table is too long",
  i18nn_d0b19bf1ee29a341:"It's not good to show",
  i18nn_f86a8104e2fb8111:"The picture is shrinkable",
  i18nn_576dbfd7de5c8816:"incompatible",
  i18nn_736b9d8693e29999:"Custom font size",
  i18nn_c53d0b17c0399897:"Custom font type",
  i18nn_481e4318f3bb1637:"Add font to whitelist",
  i18nn_399a045892a8f9a0:"Drag the image to",
  i18nn_d79b54a98b79b8c1:"position",
  i18nn_ce6e0b43e891250d:"Back end not supported",
  i18nn_cc446c9868b9bda0:"Image transfer and upload",
  i18nn_e24642aea868fc42:"Front end upload converted to",
  i18nn_8bf1de8dc482bdf1:"Label is OK",
  i18nn_5b27f3993d58438d:"Please input the content",
  i18nn_06f0c71344e7183c:"Add picture",
  i18nn_3547ab0eda19fe64:"There's a problem adding pictures",
  i18nn_b79cb734afe08e40:"Lose focus",
  i18nn_2d0a131edd6feb69:"Returned",
  i18nn_67acefa1d6a78e23:"Get focus",
  i18nn_5a3cd7da611f6553:"It can be entered",
  i18nn_2c94eb948dd388f4:"Input text change event",
  i18nn_5d2f8ccdc5abf8e7:"Returned edit object",
  i18nn_ed1dbfbdb704fc24:"Add form",
  i18nn_c5329b923a1a6213:"Upload pictures",
  i18nn_9b1a4a3b2513a12d:"Upload video",
  i18nn_b83b775c30e763e6:"Add video",
  i18nn_6c47edbd9e030ff5:"Submit upload file",
  i18nn_fd7d693ca5ce068b:"Upload picture callback",
  i18nn_cd47fe86827ac5fc:"Upload file succeeded",
  i18nn_6f1f00781c566d8f:"Upload file returned",
  i18nn_34d2cb61b69affe1:"Set the cursor at the end",
  i18nn_4c871e84a0564f9c:"This method can get the cursor position",
  i18nn_a9c5de692b5d3d8f:"Multi image upload",
  i18nn_24c8f46012a25c89:"newly added",
  i18nn_e33c9b93c36fd250:"delete",
  i18nn_760f75dc397a9cb8:"OK to add",
  i18nn_598ade86cbdf4868:"Place the cursor behind",
  i18nn_217d41539ee86758:"Otherwise, it will move forward automatically",
  i18nn_1aa9b398f37906a4:"file name",
  i18nn_9cad0c89a033c539:"initialization",
  i18nn_ee97f044c1f043b9:"stay",
  i18nn_d1c90aa33249c258:"In the middle",
  i18nn_2918e54ff488ec32:"Tag addition",
  i18nn_c4395ffd696b7a01:"And listen for the event",
  i18nn_ea5174c2d4777af3:"Error opening upload file",
  i18nn_4a4497edde0d7206:"Please contact customer service",
  i18nn_ca1c2845b3c08111:"authentication",
  i18nn_166108de2d7c2027:"Orders, contracts, etc",
  i18nn_bdaf0b4164ce179e:"head portrait",
  i18nn_2bbc6b12fbdf9019:"Parameter configuration",
  i18nn_b2fd3b9c18f697dd:"File size settings",
  i18nn_23ffd33f78e6f41f:"Delete picture",
  i18nn_a3159ee97638c70b:"Image processing",
  i18nn_2f2b1c77f45d1a43:"Rotate automatically according to picture information",
  i18nn_f17d5c1c032e68c3:"Automatic image clipping",
  i18nn_ae4b2d07e3367db4:"reference resources",
  i18nn_d30b41ab51955fc4:"Tailoring",
  i18nn_156c6fd2ea701c2d:"No cutting",
  i18nn_c378a7bc81d60366:"Proportional scaling",
  i18nn_263c9f297c574aff:"Fill transparent",
  i18nn_af98bda0cb94f7f5:"Non Avatar",
  i18nn_1f60b8e1b3488db6:"Clear video",
  i18nn_b13a5c1f6801e639:"Video width",
  i18nn_d7f57908ee860528:"Video height",
  i18nn_14599c81fe433b35:"Are you sure you want to clear this video",
  i18nn_235e127fd6efba7d:"Switch display",
  i18nn_e6244665d3792248:"Certification list",
  i18nn_85e8e84f3e8ab46b:"Access to warehouse",
  i18nn_10986afa1c3bc094:"Please fill in the information below",
  i18nn_39fd599a04a43a76:"All information is required",
  i18nn_3de99fdf145a9ee8:"Please fill in strictly according to the information on the business license",
  i18nn_43f9ebb5f4690181:"Ensure the accuracy of data information",
  i18nn_896ba18e1a7c9a07:"Company information",
  i18nn_1df68c34677c2aae:"corporate name",
  i18nn_00b8608bd21b2160:"introducer",
  i18nn_ed00b7cc66d3c1c0:"contact information ",
  i18nn_ea4756bc1642e0f1:"name",
  i18nn_4cf3d3e9cdca9259:"Contact address",
  i18nn_8292010ff4193c50:"WeChat",
  i18nn_b2d4c7fffe79258e:"mailbox",
  i18nn_032abee95f72da18:"Other information",
  i18nn_2a2e2fbcc567efc6:"Attachment upload",
  i18nn_3d5ce01c5f6e820a:"Business license",
  i18nn_e47aee84d771133a:"Not expired and stamped with the company's colored official seal",
  i18nn_6f45df3d183b85ca:"Non ferrous contract seal",
  i18nn_d9479d928db0e37e:"Application for permit or general household certificate",
  i18nn_176b98a165621d5f:"The branch information of the corresponding bank must be displayed",
  i18nn_dcac03939c37a894:"With the official seal of the bank",
  i18nn_5c22aef16867441f:"legal person",
  i18nn_7c0f5566e47f193d:"operator",
  i18nn_888660c2b94c2d80:"self-employed person",
  i18nn_59978c21e5b43454:"The front and back photos of ID card should be clear",
  i18nn_4442771eba7574b1:"Upload business license",
  i18nn_9f904564c255cde6:"Permit to open an account",
  i18nn_0b3f536c7bea3feb:"Front of ID card",
  i18nn_9436668b05dbd114:"Reverse side of ID card",
  i18nn_62c64c2d0f877b17:"Submit application",
  i18nn_7a644041c4fde483:"Upload manufacturer's license",
  i18nn_20ba48c609b26cfa:"E-mail",
  i18nn_15b3627faddccb1d:"remarks",
  i18nn_c38333badc226309:"user",
  i18nn_ff2df2d80b2fdce5:"Unified social credit code",
  i18nn_137894ffbbae8cc9:"Starting time of business license",
  i18nn_2f0c59a45ff9eb9f:"Business license deadline",
  i18nn_22fcf6ba10fde069:"Province code",
  i18nn_24060d47f29be600:"City Code",
  i18nn_6e7fe1f78f2137ed:"District Code",
  i18nn_06b62801ebebb86e:"Company address",
  i18nn_c5d19fba2f94f8e7:"Name of legal person",
  i18nn_7d4d10199f48fbd1:"Corporate ID number",
  i18nn_d8b1fe0ba2d66d2d:"Legal person's mobile phone number",
  i18nn_08ec14c52d8f156c:"Name of person in charge of business",
  i18nn_64ee4e517274c0d2:"Mobile number of person in charge of business",
  i18nn_99f4ad64860f91cd:"Creator code",
  i18nn_8ed435ced73dc9bf:"essential information",
  i18nn_2ad108ab2c560530:"Please select",
  i18nn_245b9e94e95f7e86:"Please add",
  i18nn_35573b806a56a6f9:"Application status details",
  i18nn_77e27940d6adbaa6:"Recertification",
  i18nn_5241f70c3b7363f2:"Re application information",
  i18nn_ace52c1bdd99c30b:"Inquire about the application details of the manufacturer",
  i18nn_3dd482a4ea3922d4:"Initial application",
  i18nn_45cfcf29840849e8:"Query authentication information",
  i18nn_c384a1e43378299e:"Inquire about the manufacturer's Application",
  i18nn_68f3d2d4957ee429:"Bank list",
  i18nn_0dd064b09f5fc5ff:"Query user information",
  i18nn_5ac45dfa3fa44417:"Get partner's mobile number",
  i18nn_8d7ea163fec625ee:"form ",
  i18nn_a236026c65338284:"This is the value passed by the subcomponent",
  i18nn_5e5e7e2d16922ed3:"Certification submission",
  i18nn_1cedf24d2e4fd2a0:"apply",
  i18nn_aec73adff10d4d74:"Application request",
  i18nn_5039985a20eec943:"The information of the controlling shareholder is converted into a string",
  i18nn_912d4a62f89a6ee2:"Successful application",
  i18nn_7b7f9a6febb66cbe:"Go to the exchange warehouse",
  i18nn_333b175b5093e881:"Application failed",
  i18nn_d1ce84becd11893b:"Application request failed",
  i18nn_66092bebf417bdb8:"Query warehouse application details",
  i18nn_d816fbcc52e724d5:"Request for authentication details",
  i18nn_514f4e80f3e21b6c:"Authentication details inquired successfully",
  i18nn_8a1c7e5df328a455:"You don't have permission to open the warehouse",
  i18nn_223f38a02e72fd91:"Please apply for opening first",
  i18nn_2daa844434da27d2:"Set warehouse permissions",
  i18nn_9f7aa354186d068e:"Please login again",
  i18nn_a9e4df17d34ba1ea:"Your warehouse permission has been approved",
  i18nn_257b96627dab5b4f:"Failed to query authentication details",
  i18nn_510f64662337e624:"Query application details",
  i18nn_6bcf61241d8b0acf:"The application has passed",
  i18nn_342b6b21301193e6:"Request for factory application details",
  i18nn_56b46351e946246b:"Access to payment account information of manufacturers",
  i18nn_356db73daadf580d:"Failed to query factory application details",
  i18nn_b6bc23d88757aecf:"Failed to obtain personal payment account information",
  i18nn_1d89b3f39386d872:"Find the password",
  i18nn_ace46c7b30cb9a6e:"Get verification code",
  i18nn_4e37193770939b6b:"Congratulations",
  i18nn_70c9cf3bb62dbfbd:"Password recovered successfully",
  i18nn_d1d7b80ea41c31bd:"Please remember your new password",
  i18nn_08806c68bf7fcf6a:"Jump to the login page in seconds",
  i18nn_ced8f3a726d82f7b:"Log in",
  i18nn_7a695ba20b15ed95:"Retrieve failure",
  i18nn_0107586ac6351faa:"Please go back and try again",
  i18nn_db25bc1cbe55dd27:"Introduce the encryption plug-in in the used page",
  i18nn_04152d1213c1a758:"enter one user name",
  i18nn_7cded08b7116a70d:"Length in",
  i18nn_a1f1ba9fb3797498:"reach",
  i18nn_b978bd71e959b620:"Please keep the same password",
  i18nn_f1ee1d97e869e447:"Please enter the verification code",
  i18nn_eae360b6837f15e9:"Please select login type",
  i18nn_ffbcb95f39aa1b47:"Select area code",
  i18nn_2acd08433821c0f8:"International area code of mobile phone",
  i18nn_1b3345f9dd965016:"Send verification code successfully",
  i18nn_ba97bd1c15259293:"count down",
  i18nn_122ac6751288f906:"Countdown five seconds to the landing page",
  i18nn_4733de26e1c2e985:"Verification code sent",
  i18nn_cdf92fd92be6533d:"The effective time is five minutes",
  i18nn_6e3a89fd80c16b60:"Go and land",
  i18nn_9c572a3ef9b36757:"Mobile phone gets verification code",
  i18nn_a034ab2810999eb7:"Verification code requested successfully",
  i18nn_0f00eaea829fd543:"Failed to get verification code",
  i18nn_7ed6be9d24f606ec:"Verify request",
  i18nn_2e3c70a1029b0f4e:"Verification successful",
  i18nn_ec04cac591a1850c:"Error in verification code",
  i18nn_7e012b5e969da937:"Password front end encryption",
  i18nn_b2474240b4563afa:"Password change request",
  i18nn_6bc538df4da5b85e:"Password format restrictions",
  i18nn_881860104a8f271e:"Is the comparison password consistent",
  i18nn_86f62ce872f87de1:"Modified successfully",
  i18nn_aaf11ed618ec2053:"login was successful",
  i18nn_dffe304884c9a6ad:"Password login",
  i18nn_5ae52617ed71ffb7:"Auto login next time",
  i18nn_a2dce77193d8d064:"Board",
  i18nn_f0b2eacbbd5eafa9:"record",
  i18nn_f657f7be1cc33bd2:"Forget the password",
  i18nn_0f12dbc1cd5f49e2:"New user registration",
  i18nn_2f973bff4e3b4e88:"Default password login",
  i18nn_792ac745850d2622:"timer",
  i18nn_03be70c48bd28eb1:"Clear login information",
  i18nn_9b94946c061f6b48:"Leave this page",
  i18nn_873c848bc07ac0e6:"Clear timer",
  i18nn_b57a6fcc49b7bbc1:"polling query ",
  i18nn_4d815ad327e8b95d:"QR code",
  i18nn_e4438caa92dbb748:"Polling login",
  i18nn_e0857a20198a5892:"Run page run time",
  i18nn_4979c49c828afa62:"Page dwell time accumulation",
  i18nn_d71806f07427e99e:"Switch login mode",
  i18nn_2ec7e785f09c336f:"Two dimensional code for obtaining scannable code",
  i18nn_9aa7aa827e01b057:"Get login QR code successfully",
  i18nn_bb456d91d96d66fa:"Failed to get login QR code",
  i18nn_52afb9bd150ddc88:"Polling call QR code login",
  i18nn_34adc74240d41576:"Call QR code to log in",
  i18nn_5fe6cc0473e0d313:"Login failed",
  i18nn_067069df240beace:"To register",
  i18nn_617157aee3084320:"Submit login",
  i18nn_e5368dfd7865a5f1:"Login successful",
  i18nn_3e1538efa1088ddb:"SMS verification code",
  i18nn_1163c8b809761f29:"No certification",
  i18nn_112bee240f7d0858:"Reinitialize customer service components",
  i18nn_0fff44c827a4f3b6:"Sign in",
  i18nn_fcfa7c04e26f129a:"Judge whether a partner opens an account",
  i18nn_4f5ff4a5043e10fb:"Does the manufacturer open an account",
  i18nn_f0e1c61956db8a10:"Judge whether the partnership convention has been read",
  i18nn_185ac08ed6acf361:"Determine warehouse permissions",
  i18nn_ce3c2e3ca1cfe363:"There is a historical jump",
  i18nn_fa491681fd06faa1:"Jump to history",
  i18nn_160c39ee3203a740:"Failed to get login information",
  i18nn_1064ae7463ff50c0:"If the cache cannot be saved the first time",
  i18nn_1ce0bd70ee14c382:"Carry out the second time",
  i18nn_06807a01d43e37a9:"It can't be saved the second time",
  i18nn_4913ccaad8c4cae5:"Refresh browser",
  i18nn_d119d3d93509a03d:"Unable to refresh browser again",
  i18nn_9ad1e57df6ecca3c:"Judge partner",
  i18nn_3f621a26b8218f81:"partner",
  i18nn_f302ed3011ec4e3a:"and",
  i18nn_a85c3196b1042a5e:"Manufacturer certification",
  i18nn_cf92715a60fabee5:"all",
  i18nn_b5270f958518c444:"Not approved",
  i18nn_94c23ce72c716f50:"Approved by the manufacturer",
  i18nn_44d6f2dd8045c564:"Certification tips",
  i18nn_8eeb82ba339f0daf:"Complete partner or manufacturer certification",
  i18nn_19e39d2317257787:"Can enjoy more benefits",
  i18nn_81815e947bb7793b:"Click here to authenticate",
  i18nn_988794ecaef531dd:"or",
  i18nn_b759369dd86a4c7c:"Query account opening status data",
  i18nn_9219b1f73e2ff140:"Successful query of account opening status data",
  i18nn_7ce573968e11cebc:"Tips for opening an account",
  i18nn_283e0a94f1fa2944:"Your personal account has not been opened",
  i18nn_11f7edf30a51cfbd:"Click here to open an individual account",
  i18nn_492ff5e77f754b18:"Personal account opening tips",
  i18nn_0d6e3e4d92b1b3ca:"Tips for opening an account",
  i18nn_6ab0930920f92646:"Your factory account has not been opened yet",
  i18nn_356d4449fc72c887:"Click here to open an account with the manufacturer",
  i18nn_7d842f63515c8e9b:"Failed to query account opening status data",
  i18nn_0fa3a046baf31028:"Check whether the partnership convention has been read",
  i18nn_4fb77494835ade50:"Dear partner",
  i18nn_f8d73650da15a0f9:"Hello!",
  i18nn_bc8601f4ad01775c:"Platform partners Convention",
  i18nn_638885bb94d879b4:"Updated",
  i18nn_96353aa3ead60f0f:"Please go to read and confirm",
  i18nn_07750f84b8e86475:"After confirmation, we will issue you the partner certificate",
  i18nn_63a2c5ecd34724fb:"Go to read",
  i18nn_0fb7ba49e82b597b:"Failed to query whether the partner convention has been read",
  i18nn_b360807dbe727e6e:"About us",
  i18nn_199ac0d41bf80eaa:"Talent recruitment",
  i18nn_07c9d82ce42fdb94:"contact us",
  i18nn_e077a24a00af68c3:"Transaction process",
  i18nn_b7a2279756eebc0a:"Legal explanation",
  i18nn_bb4053a733c92425:"Business settlement",
  i18nn_259b11dcddccfbb6:"Shanghai",
  i18nn_f2bba2fa536a7ce7:"prepare",
  i18nn_4335d39df11a6971:"number",
  i18nn_3c16e728db4f24e0:"The bottom is about us",
  i18nn_e6a250894a04db4c:"details",
  i18nn_a707d7e7b661c902:"non-existent",
  i18nn_db54eaad9d29d07f:"About our links at the bottom",
  i18nn_c64f10fd6de5dce3:"Information request failed",
  i18nn_f7d3f280c292a8d0:"head",
  i18nn_0f0de129ce37f7a9:"Content area",
  i18nn_395c9ca9564361b8:"tail",
  i18nn_5b6f28d3282af680:"switch language",
  i18nn_20fb9e9f515afc13:"Switch language successfully",
  i18nn_f58968344270d7b8:"It is recommended to refresh the browser again",
  i18nn_8d2f2e05f1d8ff5e:"Registration Agreement",
  i18nn_d98d75ed0b1e7fef:"Partnership Convention",
  i18nn_e016a1c7bf268efb:"first party",
  i18nn_430449303cf0f5c9:"Mutual World Network Technology Co., Ltd",
  i18nn_6a8f37cb38523424:"Party B",
  i18nn_2a8f1b7f2305a877:"Reciprocal World Network Technology Co., Ltd. is a manufacturer to manufacturer to my home",
  i18nn_cd6132f37ab005dd:"factory",
  i18nn_3f0b8b1f9d5df2d7:"A trading service platform set up by home sharing",
  i18nn_082356af685374e6:"Hereinafter referred to as",
  i18nn_e504b9c36ebc2f6d:"platform",
  i18nn_6c5121995163832b:"Platform website",
  i18nn_35e4b20da5235989:"Reciprocity world mobile phone",
  i18nn_6e4e8f8497b94273:"WeChat official account",
  i18nn_cb4356ed8dde861b:"Reciprocity program",
  i18nn_08a4ff9a64b61b9b:"Reciprocal world micro business",
  i18nn_cd9cf6180fb17e0b:"Get to know",
  i18nn_3a509053ffe958c0:"The platform passes through the factory",
  i18nn_eb545e5819745929:"Home order production",
  i18nn_efebecf3564feb7f:"Customer centralized purchase and customization",
  i18nn_e88e791b562d63e5:"Joint venture",
  i18nn_18109d6bbd4380f1:"Logistics of stored goods",
  i18nn_8a967bde547fff46:"Financial allocation and other functional integration services",
  i18nn_d7d1aaa9f07d87ce:"with",
  i18nn_773c597e2c3d4a01:"Let thousands of manufacturers interact",
  i18nn_28b1d6118eeb2b23:"For the mission",
  i18nn_9e641ee4ec777702:"really",
  i18nn_0df90bded79afc49:"product",
  i18nn_8bc133c0c6fe6e99:"Real price",
  i18nn_741c45ff6e53699d:"Zero distance",
  i18nn_c2203ee5dfae4d5c:"For the purpose",
  i18nn_cd3930ce22517879:"Serve everything with everything",
  i18nn_102efe214a898d5b:"Use everything to benefit",
  i18nn_9f13c329037e62f3:"everything",
  i18nn_3fd643ad8df4fae1:"To realize the grand vision of halving the living cost of 300 million families",
  i18nn_328cc4e89ad30fa4:"In order to comply with",
  i18nn_9418be86066b60a7:"The People's Republic of China",
  i18nn_71307d89ef052d78:"Relevant laws",
  i18nn_69b07f8efc9989a3:"Laws and regulations as the principle",
  i18nn_d4801fd5d75ec644:"real",
  i18nn_8fbed2331b57d1a1:"simple",
  i18nn_da5a251c55247f12:"transparent",
  i18nn_9d4ceba61eaa1abb:"Common values as the premise",
  i18nn_88f0539ec2a2c26f:"Stand on the platform and consider different positions and common interests with partners",
  i18nn_d35f532cf9e60897:"Make the world of reciprocity",
  i18nn_bd1ff10dc0bf303a:"Yizhao's common commitment",
  i18nn_db2a83c04d0d85ee:"one",
  i18nn_fa6e7d1f41d39100:"Definition of partner",
  i18nn_4db1716be0ea59bd:"Reciprocity platform partners refer to",
  i18nn_2f6bd901322272fa:"Identify the cultural connotation of the platform of reciprocity",
  i18nn_864d00076a814fa0:"Unswervingly implement the platform mode of reciprocity",
  i18nn_9a9ded56d1676f80:"And has",
  i18nn_d2fb068a76ed195c:"A person who has full or limited capacity for civil conduct and who has obtained the consent of or is represented by his legal representative for civil activities",
  i18nn_5fb08ff5d5491fec:"Can be a day of exchange",
  i18nn_544d970d1844a46c:"Partner of offline platform",
  i18nn_c9e78713e31e5c0c:"two",
  i18nn_cc277817a865fb65:"Types of partners",
  i18nn_a8ee05f2241e32ab:"There are four types of partners in the platform",
  i18nn_86e64c86a65fa0cc:"Full time system",
  i18nn_8f4498e538e7f052:"Partnership",
  i18nn_a6eadfa2120bdbda:"Cooperative system",
  i18nn_a2340b22cc0900a8:"adviser",
  i18nn_afa25ce3ec064f71:"Four types of partnership",
  i18nn_ae02aee2d329424d:"Full time partner",
  i18nn_a1b2fa40008dc02a:"Such partners have established labor relations with the platform",
  i18nn_482bbc1edfb4212b:"Namely",
  i18nn_9070762c69ee216f:"As a platform employee",
  i18nn_d58b6fd167ce23f3:"He is also a partner of the platform",
  i18nn_f0172799d05770b5:"Its main responsibility is platform",
  i18nn_fc6fe7dce4d7869f:"Programme implementation and services",
  i18nn_9c2dd6d92e53081e:"Partnership partner",
  i18nn_0c60ea2141a1d830:"Such partners and platforms have no intention of establishing any relationship",
  i18nn_1fd152641b716e8b:"Qualitative labor relations or employment relations",
  i18nn_69987c10a79424ed:"However, it agrees with the platform model",
  i18nn_fd2e503d7f311ad2:"Willing to work together with the platform",
  i18nn_9f403a997905a3f8:"Create the future",
  i18nn_1decd776deac80a4:"Its main responsibility is to sign the manufacturer's upload platform for sales",
  i18nn_fe7856ecd128e1aa:"Service providers match deals to participate in co operation",
  i18nn_a0da04db4f9323b6:"three",
  i18nn_e2965b00b95ade08:"Partnership partner",
  i18nn_9e7b43bd051ccd6e:"Such partners share resources with the platform",
  i18nn_1e250536b237c7a1:"Total risk",
  i18nn_f466a0b5543471e8:"Bear",
  i18nn_90a1cd47c5e78b72:"Become the common destiny of the platform",
  i18nn_1fd665c408f54616:"Its responsibilities are centralized purchasing and co marketing cooperation transactions",
  i18nn_11071e8d339b611c:"four",
  i18nn_87be271d737f2641:"Consultant partner",
  i18nn_d48ae53d526a6ef6:"Such partners have strong social resources and expertise",
  i18nn_c716cbc3d235e289:"Capacity",
  i18nn_42c06f1fff7eb653:"Put forward guiding suggestions or opinions for the development of frequency station",
  i18nn_f373aca4d3dd4352:"Its main responsibilities are",
  i18nn_bcfcc3a8f3358c60:"Introduce funds and contacts for the platform",
  i18nn_fb5dbbb1d9dee111:"Put forward reasonable suggestions and guidance for the platform",
  i18nn_4b414c64a0518911:"The above four types of partners",
  i18nn_c812fa907d921480:"Without the written authorization or approval of the platform",
  i18nn_a2e03d3b3202d9a4:"The partners shall bear the legal liabilities arising from any commitment or expression of intention made by them",
  i18nn_a5ad37739b6be6ec:"Platform independent",
  i18nn_396ed5fde95a69b9:"How to become a platform partner",
  i18nn_238d22b88394aad3:"First of all",
  i18nn_a937e3ffc6bd147d:"computer",
  i18nn_71a8a4989ac2f256:"Website",
  i18nn_7d96efaefe09f6bd:"mobile phone",
  i18nn_fe2e06b044b1b893:"common",
  i18nn_d3cf51da3d884626:"Public name",
  i18nn_083712f321b18cf5:"Micro business",
  i18nn_34273845f42f425f:"Apply for partner registration on the platform of reciprocity world",
  i18nn_c7da087ac749280b:"sign",
  i18nn_e475afa432358ba0:"The content includes",
  i18nn_10d17b36777cbaab:"Partner practice",
  i18nn_cf076b50ebdf2cc0:"What to do",
  i18nn_44c4363e6e150a6d:"benefit",
  i18nn_12a5eff3be7934b7:"Promotion",
  i18nn_7ef158db3b9455aa:"Commission distribution",
  i18nn_d9dd1c1febd19fe3:"Payment of credit deposit",
  i18nn_6ca336250aa9d0cd:"Elimination and exit",
  i18nn_6f1f4f57ff384b50:"Platform audit issuing partner credit code",
  i18nn_0d16e6ec2ffed2ea:"Become a formal partner of the platform",
  i18nn_6180bde89c6b9b46:"What partners should do",
  i18nn_89dd9ca495acc0dd:"Promotion platform sign manufacturer",
  i18nn_912cdf1562b4eb64:"Match transaction to participate in joint operation",
  i18nn_eb1a70c1286b62ed:"Participate in distribution and get commission",
  i18nn_ba63a51c7e0b156c:"specific working means",
  i18nn_888fa171ec33b1f1:"Partner promotion platform sign manufacturer to platform",
  i18nn_728648c80c48afe5:"Assist manufacturers to register on the platform",
  i18nn_fdf7f58baa50347b:"Upload products",
  i18nn_d01646d3514e6d41:"Include product name",
  i18nn_043132e6ae3f8a83:"Specification and model",
  i18nn_6867f16a2ac5e825:"Price",
  i18nn_845120789f11f174:"Ex factory price",
  i18nn_7ee2103a7671ec55:"trade price",
  i18nn_cc855b641bb8f525:"Agency price",
  i18nn_b3e2dd1d22720f53:"retail price",
  i18nn_2ecf13188fa0631c:"quality",
  i18nn_81821301b16c8a7f:"return goods",
  i18nn_d0a8aa1d10016d23:"Receipt and delivery",
  i18nn_0d5eafa1fa619354:"Collection and payment requirements and standards",
  i18nn_8a20a92f41c62d31:"Manufacturers' convention",
  i18nn_fa23a64d3dae273e:"Sign a contract",
  i18nn_d93c3f2ed0f6b693:"Charging standard of transaction service fee charged by the platform",
  i18nn_7357ebb73b1215a7:"Help manufacturers",
  i18nn_a664406e8c731da6:"promoting products",
  i18nn_efaccf8bb55d83bb:"Assist customers to order",
  i18nn_09834ed12622607a:"Collective purchase and marketing",
  i18nn_77c043016d325c68:"five",
  i18nn_620b6f3a382ecc0b:"Become a platform partner and a platform partner",
  i18nn_0a3ba2889627f990:"Benefits of secondary partner status",
  i18nn_0cfcd0903095a00f:"Match a deal to get a commission",
  i18nn_9d4f80aa5fcf2600:"Profit from joint operation",
  i18nn_a914fd1fc9a283d2:"Partners sign more manufacturers and users",
  i18nn_45f7ba081ac491b5:"The greater the volume",
  i18nn_14cf9fb9d400716a:"Transaction service fee is more",
  i18nn_c5c48b89fc36472e:"Commission",
  i18nn_7683dda9d0e8b37e:"The more",
  i18nn_d5a677daf96988c8:"Become a",
  i18nn_1e683ce4087f9954:"The more secondary partners are allocated",
  i18nn_72afe7fb4bdd4229:"The more profits you can earn by participating in joint operation",
  i18nn_0dc4093ceeda8961:"large",
  i18nn_21d38395dfa6bfdc:"More dividends",
  i18nn_3d86ac98afd3c98b:"six",
  i18nn_b3b24749a91c0f44:"Partner promotion",
  i18nn_97340a49a87440e3:"All through the platform registration application and pass",
  i18nn_114a976e83dba35a:"Signed",
  i18nn_f8e2481d45f58642:"Acquisition of partnership",
  i18nn_f4fd62977f5e219d:"Partner of Credit Code",
  i18nn_5d78dda700452eb4:"They are all formal partners of the platform",
  i18nn_a5d2f00920f5e634:"Full partner",
  i18nn_90e44f0cd824c6fe:"Sign the same product",
  i18nn_268e50d838264ca8:"Category",
  i18nn_6928ab4690f07fc8:"Manufacturers of",
  i18nn_9b01a3f56b339e8b:"Three months",
  i18nn_efba320d0caba717:"More than home to the platform and upload products for sale",
  i18nn_b569b77b36cab65f:"Secondary agent",
  i18nn_90022e7e07067117:"Full partner",
  i18nn_7a0a7af295124dca:"More than one manufacturer in the same industry can",
  i18nn_26580997df11462c:"Apply for secondary agency qualification",
  i18nn_679b1f787ae60390:"First class agent",
  i18nn_74d9d1935c5da8b6:"from",
  i18nn_5a393ac00b703b18:"Second level agency qualification",
  i18nn_e2eda3a58e77a1d3:"Industrial chain of more than one family",
  i18nn_7efb93c6892a72e8:"The manufacturer can apply for the first level agency qualification",
  i18nn_d4165e218d09f19c:"Platform development branch",
  i18nn_c984984f86ade471:"The platform expands branches at home and abroad",
  i18nn_3d47d43d47d3e67d:"Each branch must be owned by",
  i18nn_32aabce6a48fa9c2:"First level agent",
  i18nn_058c0cfc47bc6d33:"Two secondary agents",
  i18nn_25feef33c757c8ef:"A full partner",
  i18nn_ba8202deab400178:"look for",
  i18nn_80424b99fc81a62f:"Three manufacturers set their sales on the platform",
  i18nn_dbd9bda0b7dae7ae:"Matching services are provided according to the supply and demand information provided by manufacturers on the platform",
  i18nn_ed85fa380d2e164a:"Through the gold on the platform",
  i18nn_565ec323455e7479:"melt",
  i18nn_90354e648ee81925:"storage",
  i18nn_e9c15e7660b4dbf9:"Transportation and other supporting resources",
  i18nn_64c8a8d6619ca8ad:"Deal with service providers",
  i18nn_8f4e0871030b7c68:"seven",
  i18nn_a820da4109959705:"Platform transaction service charge standard",
  i18nn_a8bed931f5b7ad6b:"The platform has formulated five transaction service charge standards",
  i18nn_347c8ce0b0babefd:"The partner of the supplier shall find the manufacturer",
  i18nn_382252986497b355:"Discussion",
  i18nn_7b31746772e8ec31:"The manufacturer should choose the charging standard",
  i18nn_1d946eb520d38b8e:"a tiny bit",
  i18nn_32924b06a28ba407:"Calculation of turnover",
  i18nn_0924078429bf522a:"Two stars",
  i18nn_dffc307c1f23d57b:"Samsung",
  i18nn_4f60a3a57543ab0b:"Five stars",
  i18nn_f3828f9b73412e7d:"custom",
  i18nn_c6576c6d0eac12a8:"eight",
  i18nn_a372852a589b5893:"Distribution proportion of platform transaction service charge",
  i18nn_c8f135cb47650bf3:"Supplier of full partner",
  i18nn_8c0433326b89cc2a:"Partner of demander",
  i18nn_95c6fc87672fdf73:"Half full time",
  i18nn_a089d6570c8a50e6:"Supply and demand partners only take one head for the same person",
  i18nn_b70c8d1f9c0af0f9:"Platform and one",
  i18nn_9eff6efdcf332948:"Secondary agent",
  i18nn_7ac1f356500fba03:"The partners of the supplier and the demander are not in the same second party",
  i18nn_b61c03d043c62e32:"Class agent",
  i18nn_d0f707166bff7c04:"All secondary agents allocate this level of allocation equally",
  i18nn_2cb2400dc38279b9:"All secondary agents are no longer under the same primary agent name",
  i18nn_59b2a2915f3d0b11:"All first level agents allocate this level equally",
  i18nn_af7d031b3b342e2a:"Other distribution",
  i18nn_3e6108abecb95544:"Assign to platform and one",
  i18nn_397d77939b36ff03:"The distribution after the second level agent is decided by each agent",
  i18nn_6ee7a3e31af0a693:"distribution",
  i18nn_5d53f5b6117f5ad8:"nine",
  i18nn_7fd753c6f7d2a5d3:"Credit deposit",
  i18nn_a3b4e8d10ad55be5:"Formal partners pay credit deposit in RMB",
  i18nn_0ffc78c1cedf6eba:"Ten thousand yuan",
  i18nn_0e95d20940921996:"Overseas for",
  i18nn_3a6e2b33b574a286:"Thousand dollars",
  i18nn_1ad9d639d66f654f:"In the amount of commission",
  i18nn_185bd8ab7bc97f6f:"Withholding as payment method of partner's credit deposit",
  i18nn_456eab08dae4f81c:"Until the partner's credit deposit is fully paid",
  i18nn_b977ac60be37cb6d:"Domestic payment",
  i18nn_36eb08b73f29480a:"10000 yuan credit deposit",
  i18nn_d79c7f2072b4dd24:"overseas",
  i18nn_b98c1e3d365c47ca:"Ten thousand dollars",
  i18nn_4c8ff9605e60fe84:"Wanmei",
  i18nn_68fd50d0ed8b84a9:"gold",
  i18nn_170f2d88c49ef1b5:"Platform branch",
  i18nn_71827d3678b69b71:"ten thousand",
  i18nn_46392b6afe7e0471:"dollar",
  i18nn_0b7774e764cf1000:"ten",
  i18nn_59e36d01a5abbc90:"Account management",
  i18nn_5ac06f05852acdc6:"In order to protect and embody the rights and interests of partners",
  i18nn_99185f86a2fdd4db:"The platform sets up margin account for partners",
  i18nn_fa4d0b41f7b0d4b7:"household",
  i18nn_c2c13bbe7f2a4dae:"Income account",
  i18nn_a30caadc678acc23:"All partners pay the credit deposit to enter the special deposit account",
  i18nn_b72ed8a176eea28e:"All profits, dividends and distribution Commission used by partners will be automatically distributed after the end of the transaction",
  i18nn_5cd481d9924ab7e0:"Access to partner's income account",
  i18nn_8f87224ce0fbd4bb:"Special management of individual households",
  i18nn_f13d130c9587787a:"Based on the principle of confidentiality for partners",
  i18nn_ad8e6a7c2809873b:"After the opening of the partner's credit margin account",
  i18nn_36e9435fb8cc49e5:"From partnership",
  i18nn_7ed28719fc8db8f5:"The amount withheld from each receivable transaction service fee",
  i18nn_93112b5e5d42db9b:"Transfer to its margin account",
  i18nn_d11b2301ab78f749:"Until the amount of its margin account reaches the required credit margin amount of its partner level",
  i18nn_3e5a12305a12edea:"stop",
  i18nn_a0d23526891e0584:"The credit deposit does not bear interest",
  i18nn_9590f060aa9721fd:"The deadline for the execution of the deposit by way of withholding is",
  i18nn_80b0c3338d06194b:"year",
  i18nn_9954ce6437e66f41:"month",
  i18nn_43d13c53a89c5324:"Day end",
  i18nn_17b6fee096c996fb:"The partner does not renew the contract when it is due",
  i18nn_a4ba4bf2fd70ee3d:"After elimination or termination",
  i18nn_c542d75cfbd0fba6:"A partner can only",
  i18nn_a05225eca8f6e206:"Application for withdrawal of funds from its margin account",
  i18nn_d8ebcaf3388ea2fb:"eleven",
  i18nn_0801f008763f8f15:"Elimination mechanism",
  i18nn_117bf276d635ee58:"When any of the following occurs",
  i18nn_feedba7cb76f972d:"The platform has the right to eliminate or terminate cooperation with partners",
  i18nn_3f509a93b32fd953:"And according to the seriousness of the circumstances",
  i18nn_97a55452b2d0116a:"Deduction of credit deposit",
  i18nn_f11df866602c729a:"Within three months after the entry into force of this Convention",
  i18nn_81feaba88f025156:"The number of manufacturers signed by partners is insufficient",
  i18nn_0bc8557f94665a04:"home",
  i18nn_5eecd414376ff2cd:"Signed by the partner and completed by the manufacturer on the platform",
  i18nn_5adc14e6e0fdd421:"Number of effective transactions",
  i18nn_19bfdbea32080db0:"Or amount",
  i18nn_85f98f06e15622f7:"Zero",
  i18nn_9d3ca05d9f9b06ed:"Partner's price of the manufacturer's products on the sign in platform",
  i18nn_056d0abfe8796d95:"False quality",
  i18nn_e7fc4f2b82fba8d9:"Hair production",
  i18nn_56e7d9c7afbbd121:"We are not responsible for negotiating with the manufacturer to solve after-sales problems",
  i18nn_8dca96adb3d48663:"Partners use the platform to engage in illegal business activities",
  i18nn_245d3cb7d46b100a:"Propaganda false information to the platform of adverse effects, etc",
  i18nn_48271ca007768dd9:"Without the consent of the platform",
  i18nn_89c7d6e1ae192ad0:"In the name of the platform or both sides to carry out external peace",
  i18nn_6e7587a110dfc099:"Taiwan is not active",
  i18nn_96a9e71d8a7a0881:"Directly or indirectly bring civil damage to the platform due to the partner's personal behavior",
  i18nn_cc3b41cc72da1911:"administration",
  i18nn_7ba4ade1ccb742da:"Or criminal liability",
  i18nn_0657f64fd9dcd925:"And cause losses",
  i18nn_9f63e166d4834186:"For the",
  i18nn_94d595f0a6a1c12a:"Small strip",
  i18nn_67add2353b457382:"The platform has the right to deduct the partner's deposit",
  i18nn_a74f3266b1a1c772:"twelve",
  i18nn_d72b426cd7130758:"It is agreed by both parties",
  i18nn_1ea51a7f18278909:"The platform has the right to formulate the conditions for the access of partners",
  i18nn_ab9ceae7c811ce60:"Review partner qualification",
  i18nn_32ef4158db3cdf4e:"Formulate operation rules and exit mechanism involved in cooperation",
  i18nn_a24ec27332f767be:"And has the right according to the actual needs",
  i18nn_129b6b0629df7b19:"The corresponding rules should be amended",
  i18nn_adb76a3968136e2d:"mechanism",
  i18nn_bbdb25136b1c49bb:"Formulate the trading rules of products or services on the platform",
  i18nn_bc0940606bc2530c:"Rules for platform operation",
  i18nn_489e93d5e5b2951f:"And its products",
  i18nn_833e79ef081ff3aa:"Service, etc. have the final interpretation right",
  i18nn_b4ab969c28963b46:"All commodity data resources of the platform are shared with partners",
  i18nn_67c250e17be45cfb:"Provide information and services needed by partners in the process of promotion and transaction",
  i18nn_1bc6208e61b1a0c5:"Such as information",
  i18nn_f4c3e91a86d23afe:"service",
  i18nn_e7c5c872e6c2effe:"Warehousing and logistics",
  i18nn_b1d97120606bb674:"Financial services, etc",
  i18nn_052838380b61816c:"Party A has the right of final explanation for all services",
  i18nn_bca49daf0d694e16:"Matchmaking service fee",
  i18nn_12f595dd54e3cabb:"When the manufacturer recommended by the partner trades on the platform",
  i18nn_08dae0e0a7266acb:"Time",
  i18nn_0bf6504606c38c92:"According to the commodity category of the manufacturer's trading order and the role of the supplier and demander in the order",
  i18nn_43d391028e658f35:"The partner will be allocated the corresponding service fee in proportion",
  i18nn_02c1c0a507012ebe:"Both parties shall keep the contents of this Convention and the cooperation matters under it confidential",
  i18nn_663eea3ddfccac53:"If",
  i18nn_86b6709076588aca:"Either party needs to report to any third party",
  i18nn_b934f1cb213adecb:"Including media",
  i18nn_419e57660fa67d89:"Disclosure of key issues related to bilateral cooperation",
  i18nn_bfb1fde233da0fcd:"Prior written consent must be obtained",
  i18nn_b039e8edd989af80:"The two sides should discuss",
  i18nn_b259d9d61753193d:"Sign",
  i18nn_c0251ce6788ee43d:"What is known in the course of the implementation of this Convention is",
  i18nn_74ae946b19ce1319:"Documents and materials of the other party that cannot be obtained through public channels",
  i18nn_3034a6a684fde8fe:"Including trade secrets",
  i18nn_7125c04ad76103fa:"Company plan",
  i18nn_baf85ef9349c05da:"Row",
  i18nn_4633f280b5630071:"Operational activities",
  i18nn_75fba0825ef06612:"financial information ",
  i18nn_6becddeb062aaf22:"Technical information",
  i18nn_45e13f8f60933edc:"Business information and other trade secrets",
  i18nn_9b0066623179dc21:"To",
  i18nn_c79bca7697acbdaa:"In confidence",
  i18nn_d87ddb906729f87b:"Without the written consent of the original supplier of such materials and documents",
  i18nn_710af0f9440ed3f8:"Neither party shall",
  i18nn_03da3028f894f2d2:"Disclose all or part of such documents and information to any third party in any form",
  i18nn_499e1a59b5f256dd:"but",
  i18nn_cdb9a974df82eef1:"law",
  i18nn_2e2edfd4eab56b11:"Unless otherwise stipulated by laws and regulations or required by regulatory authorities",
  i18nn_de488fca42124938:"According to the law",
  i18nn_7626e861baccae67:"Regulations",
  i18nn_040a6b8d39f84bd7:"Or the information required to be disclosed by the regulatory authorities",
  i18nn_12b3046dc70dceff:"Be asked for",
  i18nn_9175ad8cff37c934:"The party seeking to disclose information shall inform the other party before taking any information disclosure action",
  i18nn_f41eb717bc1dfbac:"And take reasonable measures",
  i18nn_316152fc08c0da25:"Strive to minimize such disclosure",
  i18nn_381860a57bd19810:"thirteen",
  i18nn_bd6c2e98769d4620:"other",
  i18nn_cf2db82165da1b4d:"Party A and Party B confirm",
  i18nn_74173fb619507494:"For the purposes of this Convention, the partnership is used",
  i18nn_cc1bb411f7963777:"However, both parties are equal",
  i18nn_5571298be765209a:"Business cooperation between entities",
  i18nn_441ed2abdfb63f6d:"Collaboration",
  i18nn_f92e0cb623da52b5:"Partners as partners of platform promotion",
  i18nn_9fe767cfd3cd3bcc:"It does not constitute a partnership or other investment relationship in the legal sense",
  i18nn_f2c156a2fb44b55a:"This Convention shall be sealed by Party A",
  i18nn_681c55bb14a4d1f2:"It shall be established and take effect from the date of signature by Party B",
  i18nn_2524308e07d746a1:"This Convention is written in Chinese",
  i18nn_e6fdbeb061f9d0d3:"Two in one",
  i18nn_8f3c5dbaaf83e39e:"Each party holds one copy",
  i18nn_4aed7d6987de577c:"Each share has the same law",
  i18nn_4e6db2b4b153efa9:"Legal effect",
  i18nn_979b413670d2860e:"This Convention is made by",
  i18nn_5d7247f43e5c5cfe:"Signed on",
  i18nn_eb5d5a99b240f54b:"Signed by",
  i18nn_c21f80d95586a2f4:"Shoes",
  i18nn_8ee6e5467f6f1538:"All disputes arising from this Convention shall be settled by the people's court under the jurisdiction of Party A",
  i18nn_13cf87fb6937eb46:"fourteen",
  i18nn_e34486fbf5843a7f:"Term of validity",
  i18nn_ac03b4ce8e7e007a:"This Convention shall remain in force for",
  i18nn_8948a5da9ed15431:"since",
  i18nn_95cb0445a7d96ce8:"From January to December",
  i18nn_bab352df1c2bac01:"Partner number of Party B",
  i18nn_3e81fe8dabec8f2c:"Before the expiration of this Convention",
  i18nn_bb3d670df5390f32:"Months",
  i18nn_f5d3c1aa43ac979d:"Both parties fail to raise a written objection to the renewal",
  i18nn_4ab9342118c93729:"Automatic extension of cooperation term",
  i18nn_4bc3c046369fd863:"This Convention shall be interpreted by the platform",
  i18nn_e9b236ea1f7fda4f:"The following pages are for signature and seal",
  i18nn_78834068a3660d26:"No text",
  i18nn_a449d26c32ec2ca0:"Seal",
  i18nn_bb5514b4f2828409:"Authorized representative of Party A",
  i18nn_3b0f44e4c6735d29:"sign",
  i18nn_8758fd50c87d6c9c:"address",
  i18nn_2d13b6f878f30be3:"Telephone",
  i18nn_3578997e3a1087af:"Date of signing",
  i18nn_ac2c5e612fb71dbb:"day",
  i18nn_2b5688384315e441:"Name of Party B",
  i18nn_e4f49ccfbf632041:"Signature of Party B",
  i18nn_5a9fb0915ecea987:"contact number",
  i18nn_5901732d5153a40f:"read",
  i18nn_20a5eb4c1cf66611:"It can be operated in seconds",
  i18nn_c6f16a932dc37e57:"I have read it",
  i18nn_904f5fd4732c0ec1:"Read and confirm",
  i18nn_1f5b8c6eb3a1e9ec:"Start the countdown",
  i18nn_cce168301447d1ba:"have in hand",
  i18nn_9b2077cfd69c98be:"Countdown initial change",
  i18nn_f2d88286137980f3:"Read",
  i18nn_c6a422bfbd7a259f:"The partnership convention has been read and submitted",
  i18nn_2721a95dbb3df010:"Partner convention read failed",
  i18nn_158eb06e6609b0b7:"User registration",
  i18nn_a1805b0f0117f109:"user name",
  i18nn_de0f748d866726e3:"Existing account number",
  i18nn_05682dbef5eeb0a8:"customer service",
  i18nn_08cc7d24752ec42c:"Online Service ",
  i18nn_6839940dac08c794:"register",
  i18nn_79444f5839826bf0:"The length of the user name is",
  i18nn_5a9aefbc03c778f7:"Please input",
  i18nn_22a5895a0978844b:"A combination of letters and numbers",
  i18nn_e097bb9b1216bfd8:"Contact customer service",
  i18nn_7482e9abfd1ac67c:"Verify duplicate password",
  i18nn_c9946fd976ed060f:"Please check the submitted data",
  i18nn_ec6569171730677e:"Play failed sound",
  i18nn_6d1ee1adad751621:"Registration submission",
  i18nn_b97755954a17a764:"Display protocol",
  i18nn_c39705483df9ffdb:"Registration request",
  i18nn_ddaf6df4419b69a5:"Please select the international code",
  i18nn_aa1885a3ade2546d:"login has failed",
  i18nn_a4e96672f059a795:"The cache cannot be saved for the first time",
  i18nn_a358cd5f57ab067c:"Successfully registered",
  i18nn_4639f3a6e07c00b3:"next step",
  i18nn_fbe2f7bf0e7aa139:"What do you want",
  i18nn_cb7e41fd1feae9d5:"Certified manufacturer",
  i18nn_86c45eefdac6e2ea:"After certification",
  i18nn_c7da3d779b7c09ef:"You can show it",
  i18nn_fa3352ed2c26ed60:"extension",
  i18nn_792a7b820e4d978c:"order",
  i18nn_bbee2572b3cf6bba:"sale",
  i18nn_d2fcb76d57206ef6:"Quotation, etc",
  i18nn_13fc2e4b355357dd:"Certified Partner",
  i18nn_bb02b24075180755:"You can invite manufacturers to enter the platform",
  i18nn_d6446677c75949ba:"Enjoy the benefits, etc",
  i18nn_87143dc9fc2e5b2f:"Open warehouse",
  i18nn_a4e93eab295e80f9:"After opening the warehouse",
  i18nn_3e199baddb9a3e4f:"Experience storage",
  i18nn_eeb1f61b0d4e9282:"logistics",
  i18nn_57495c18e2baebdc:"Pick up",
  i18nn_d1b7d51dbf161c83:"Express and other one-stop services",
  i18nn_6416685506bcd152:"Go to the homepage",
  i18nn_3efb3a8f711a3238:"Look around",
  i18nn_ffc145fd3af04e0c:"Purchase manufacturer's goods",
  i18nn_1c598a42b6bb8c77:"After manufacturer certification",
  i18nn_359c834d8f0c25e3:"Only then can we trade",
  i18nn_4d9fdb27057bc527:"Merchant certification will have more surprises waiting for you",
  i18nn_4f5bb4ff8b3d804b:"confirm",
  i18nn_5e31c5dc9f86182e:"Safety tips",
  i18nn_ad56506c11ba83ba:"Please keep your account number and password on the platform",
  i18nn_52cd94bf8bf01ca9:"Thank you for choosing reciprocity",
  i18nn_f08eba7edcb8b649:"Welcome to register",
  i18nn_57810f3904cf54f0:"Reciprocity world user registration agreement",
  i18nn_5be1d9322af32101:"Registration Agreement Registration Agreement Registration Agreement Registration Agreement Registration Agreement Registration Agreement",
  i18nn_f5b58637ea2847ff:"This agreement is for you to exchange with the world",
  i18nn_847f063538ae47c2:"abbreviation",
  i18nn_44cbed56206a3684:"Our website",
  i18nn_1617e69bdeb3610d:"website",
  i18nn_50e396256589cdc2:"Owner reciprocal World Network Technology Co., Ltd",
  i18nn_b1d880b6c53a8b59:"Hereinafter referred to as",
  i18nn_75a9d8d5c827d109:"The contract between the two parties on matters related to the exchange of world services",
  i18nn_d11fa26e00422b29:"Please read this registration agreement carefully",
  i18nn_57f8c68a66b6528a:"You click",
  i18nn_39e4d72bf3f44e5e:"Agree and continue",
  i18nn_540a0fe90cb60295:"After the button",
  i18nn_94fa28fdfe1436a0:"This Agreement constitutes a legal document binding on both parties",
  i18nn_080a48f195815c62:"The third",
  i18nn_56d240221524ba15:"Confirmation and acceptance of our terms of service",
  i18nn_a72391cf0f8d175e:"The ownership and operation right of all online services of this website belong to reciprocity world",
  i18nn_ee488258c67d0751:"You agree to all the terms of the registration agreement and complete the registration process",
  i18nn_f9d30539f422a8f7:"To become the official user of this site",
  i18nn_59ff278506af8adb:"Are you sure",
  i18nn_8c3ba316859326e4:"The terms of this Agreement are the contracts dealing with the rights and obligations of both parties",
  i18nn_d175ff3ab16e95aa:"Always valid",
  i18nn_f749eef5d936255c:"If there are other mandatory provisions in law or special agreements between both parties",
  i18nn_ea1b21d072680ce5:"According to its regulations",
  i18nn_749dec61fc4fa71a:"You agree to this agreement",
  i18nn_423bb7a1d940d1f5:"That is to say, you confirm that you have the right and capacity to purchase goods and services online",
  i18nn_6b12e36363211903:"Be able to bear legal responsibility independently",
  i18nn_db2d3ef553a2e8e8:"Reciprocity world reserves the right to decide the denial of service solely within the scope permitted by the law of the people's Republic of China",
  i18nn_a2886ebc43e802fc:"Close user account",
  i18nn_05f742626881858c:"Right to clear or edit content or cancel order",
  i18nn_69d3dd8956fe8109:"Local service",
  i18nn_c1db3b4dfc3f1380:"Reciprocity world provides steel products for you through the Internet",
  i18nn_5a90bf08ed58d528:"wood",
  i18nn_afb8b6445456be11:"Cotton and other information and services",
  i18nn_01f880df733f7777:"You fully agree to this Agreement and the provisions of the site",
  i18nn_66b14a4891099d9c:"Party B has the right to use the relevant services of this website",
  i18nn_4d20e95ad039b9b3:"User information",
  i18nn_4f141b148cc1f9fd:"You should be on your own",
  i18nn_589c69448c96f2f2:"Factory integrity to provide registration information to the site",
  i18nn_1997bf6f724c8ec8:"You agree",
  i18nn_5c869db57790b0d4:"The registration information provided is true",
  i18nn_ae47d38aaadb40aa:"accuracy",
  i18nn_bca9aee06ca1eabd:"complete",
  i18nn_45ea35f5a291efcb:"Legal and valid",
  i18nn_fa3e9a18afc823c8:"If there is any change in the registration information",
  i18nn_ea45a514c64bd41c:"The registration information should be updated in time",
  i18nn_c4e2c9af94a18570:"If the registration information you provide is illegal and valid",
  i18nn_b23a73776a75c5a6:"untrue",
  i18nn_caa2a52bfea9d262:"inaccurate",
  i18nn_89ecdc30541cd9fd:"Not exhaustive",
  i18nn_4ef986878a954c6a:"You need to bear the corresponding responsibility and consequences",
  i18nn_67daac682d06b34f:"And barter world reserves the right to terminate your use of barter world's services",
  i18nn_8ac21bdef7cfd94a:"You know and agree that",
  i18nn_b73f7ffc29e2cf44:"In order to facilitate you to enjoy the website provides browsing",
  i18nn_e590103d2be0a214:"Order locking and other services",
  i18nn_b5ce31d47062f89c:"This site will store the necessary information when you use it",
  i18nn_1be0f2a3afeff1f6:"Including but not limited to the real name of you and the manufacturer",
  i18nn_b590883f49941893:"mailing address",
  i18nn_c2d9cc88561f8cdc:"contact information",
  i18nn_35eaaae7173876b5:"Business license, etc",
  i18nn_f720ba7209821a76:"This site will be strictly confidential",
  i18nn_eb00b6fa88df4c6b:"Unless otherwise authorized by you or stipulated by laws and regulations",
  i18nn_00c0a029c9a5ac6d:"This site will not disclose your or manufacturer's privacy information to a third party",
  i18nn_82dfae68029e9cce:"After you register successfully",
  i18nn_fc9c4e460812357f:"Account information such as password will be generated",
  i18nn_32b2f589c4aea314:"You can change your password according to the prompts of this website",
  i18nn_8d5d2c47c4995c5b:"You should keep it carefully and reasonably",
  i18nn_4c5418237b921823:"Use password",
  i18nn_f4bf488838ab0b62:"If you find any illegal use of your account or security vulnerabilities",
  i18nn_6725fc584ea69f40:"Please inform us immediately",
  i18nn_70a2579e4c577932:"otherwise",
  i18nn_37ec75a6e88cfd66:"Due to your own negligence, resulting in account information disclosure and other consequences",
  i18nn_81a50fd2c2cfb8fb:"It's up to you",
  i18nn_56dfa540bc7f88c6:"You fully understand and agree",
  i18nn_5b924000df14a370:"Reciprocity world owns by mail",
  i18nn_d939d4dbba69d4e5:"short message",
  i18nn_f17c35875e6d8212:"Telephone, etc",
  i18nn_b9fb1c5b6ca36a08:"Register with us",
  i18nn_f70bea56cd1529db:"You can send order information when shopping",
  i18nn_a11ebcc06e655b59:"The right to inform about promotional activities, etc",
  i18nn_8970a1dc84c478d5:"If you will be registered in this site to obtain the account lent to others",
  i18nn_25b98478c8bbc0c7:"We must bear all the responsibilities arising therefrom",
  i18nn_93aff52c515abf4a:"And the actual users should bear joint liability",
  i18nn_916aec9f00ae8811:"You fully understand",
  i18nn_c9401b95b0a95520:"In order to cooperate with the administrative and regulatory authorities",
  i18nn_f84c87a3e5974d12:"Judicial enforcement",
  i18nn_885a04901305d91b:"Within the scope of the law",
  i18nn_fc86a23586ebf0ea:"Reciprocity world has the right to use your registration information",
  i18nn_40d127140e2b07b5:"Password and other information",
  i18nn_ff714cb2bcf69d6c:"Log in to your registered account",
  i18nn_6f4daff5a477fb65:"Evidence preservation",
  i18nn_e6441e361daa8e8b:"Including but not limited to notarization",
  i18nn_eb81c10d79989051:"Witness, etc",
  i18nn_6355fc03899b758b:"Manufacturer account number",
  i18nn_62be0e5b38d91df3:"In order to enjoy more services and platform operation functions including procurement",
  i18nn_44deb7c398d86106:"You can apply for a factory account",
  i18nn_d9bd7084aa298ef2:"First of all, you need to have the registration qualification",
  i18nn_2d735d596a67d630:"You must ensure that you are a legal organization established in accordance with the laws and regulations of the people's Republic of China",
  i18nn_7202e08a92262d76:"And has obtained the qualification of business entity in the people's Republic of China",
  i18nn_f0e42634e00f9888:"And upload the corresponding certificate according to the platform prompt",
  i18nn_17392f136c88f3b3:"If you do not have the above subject qualification",
  i18nn_09d0e8fe78eae4a8:"Our website has the right to refuse your application for registration or certification of manufacturer's account",
  i18nn_ee5040ed3069d235:"The loss caused by this shall be borne by you",
  i18nn_c87622872c85a469:"Fill in the information according to the prompts on the registration page of reciprocity world",
  i18nn_f57805133dd40d81:"Read and agree to this Agreement and complete all registration procedures",
  i18nn_1be80dcaafc0f92e:"You are the account number of reciprocity world",
  i18nn_ec25c06d1b055cf1:"After you upload the certificate that meets the requirements according to the authentication page of reciprocity world and pass the examination of reciprocity world",
  i18nn_daf2c5e69342a6b7:"You are the account number of the manufacturer after the authentication of reciprocity world",
  i18nn_aae301f0604c7fe0:"After you have successfully completed the factory account authentication",
  i18nn_764c488a4bc81158:"You can trade online through the exchange world platform",
  i18nn_348f6f4fa4b0f5f2:"Publish resource information",
  i18nn_1534e2ceb7641965:"Publish Purchase Message ",
  i18nn_bad66de9d9f0f07a:"Download resource list and other manufacturer member services",
  i18nn_fd340dd763af3114:"When you apply for or authenticate the account number of the manufacturer",
  i18nn_edc3906c2df1c65c:"The manufacturer information you set shall not infringe or be suspected of infringing the legitimate rights and interests of others",
  i18nn_b0d827734869f320:"If you continue",
  i18nn_ea4c0766739039c5:"No use of mobile phone number and password to log in and update the information records or actually use the service",
  i18nn_dcaaf526f0186532:"Reciprocity world reserves the right to suspend or terminate your service and cancel your account",
  i18nn_63feebdf9f8317f6:"Account description",
  i18nn_946af3623f53e067:"You should keep your mobile phone number and password safe",
  i18nn_540e58a650e9014d:"And be responsible for the actions implemented through their mobile phone number and password",
  i18nn_e93f591f99eb7d6b:"Your mobile phone number and password registered in reciprocity world are only used by your own internal staff",
  i18nn_9efc42cbab44a7e2:"It shall not be used by any third party",
  i18nn_8a205a5810035959:"Otherwise, the loss caused by this will be borne by you",
  i18nn_8356d005135c3671:"And reciprocity world reserves the right to suspend or terminate the service",
  i18nn_2fd4605eed633bc1:"Unless there is a law or judicial decision",
  i18nn_3cf4abe1bab9593a:"And obtain the agreement of the exchange world",
  i18nn_28644062d111b04b:"Mobile phone number and password cannot be transferred in any way",
  i18nn_77fa364cf4a6e826:"Gift or inheritance",
  i18nn_e23f00adfc1c40d9:"When you forget or lose the password registered in reciprocity world",
  i18nn_2168ed76503d5311:"Can get in touch with customer service personnel of reciprocity world",
  i18nn_218e22fcebaaac47:"After providing relevant supporting materials and being checked and confirmed by reciprocity world",
  i18nn_4f637ee4a38e94a2:"Password retrievable",
  i18nn_fb349d677c1cdc07:"Modification of information",
  i18nn_04a51265b063cf52:"After you register as a manufacturer account",
  i18nn_0441811b67bace22:"If the registration information changes",
  i18nn_4bb17ed394ba09ad:"You must be in time",
  i18nn_0e7dda6b4ca6c6ad:"Take the initiative to contact the world of reciprocity to update",
  i18nn_6c2e062931c41b66:"Because you didn't update it in time",
  i18nn_c26311abd0a76987:"Causes you to be unable to enjoy the related service",
  i18nn_eeb6e302d6b54ae9:"Or cause the account number and password to divulge and so on all responsibility by yourself",
  i18nn_0314b1e17596d402:"User's legal obligation",
  i18nn_5b28c75dd2f53f11:"This agreement is made in accordance with relevant national laws and regulations",
  i18nn_22b8ebf08650b6de:"You agree to strictly abide by the following obligations",
  i18nn_721d7b94cc715f74:"No transmission or publication",
  i18nn_46a477ce2810860f:"Incite resistance",
  i18nn_b76d99503414cbd3:"Breaking the Constitution and the law",
  i18nn_4b28eb649b386cf9:"Comments on the implementation of administrative regulations",
  i18nn_442c09a112856924:"Inciting subversion of state power",
  i18nn_bf911542eabc962f:"Comments on overthrowing the socialist system",
  i18nn_953dcc37a323a844:"Inciting secession",
  i18nn_068ba2308aa6c4ea:"Remarks that undermine national unity",
  i18nn_201aa99ff5cfc514:"Inciting national hatred",
  i18nn_c8d958b19be8c4ee:"Ethnic discrimination",
  i18nn_f4e81d831a14f788:"Remarks undermining national unity",
  i18nn_429818aa524c71a6:"Information transmission from mainland China to the Chinese mainland must comply with relevant laws and regulations in China.",
  i18nn_e34a7819b80a914c:"Do not use this website to engage in money laundering",
  i18nn_7867cfa781410949:"Stealing business secrets",
  i18nn_0e23a34fa9b859bc:"Stealing personal information and other criminal activities",
  i18nn_83a92e9a857d5756:"Do not interfere with the normal operation of the station",
  i18nn_cbca82a8ce59465c:"It is not allowed to intrude into this station and the national computer information system",
  i18nn_0eb3ccfe7db52feb:"It is not allowed to transmit or publish information, materials or opinions that damage the national social and public interests and involve national security",
  i18nn_514434e7272fcae9:"Shall not abet others to engage in the acts prohibited by this article",
  i18nn_0afa127195edc24c:"Shall not use the account registered in the site for illegal profit-making business activities",
  i18nn_37ba1da998009ce8:"You should pay attention to and abide by all kinds of legal rules and regulations published or modified from time to time",
  i18nn_1f4037d9acd6f84b:"We reserve the right to delete all kinds of information that do not conform to laws and policies or are untrue without informing you",
  i18nn_611d8b9bd1772698:"If you do not comply with the above provisions",
  i18nn_c5963da7148011bf:"The website has the right to make independent judgment and take measures such as suspending or closing the account",
  i18nn_b10ac26f4cd53aea:"You must take legal responsibility for your speech and behavior on this website",
  i18nn_44566798e85d4051:"If you spread reactionaries on this website",
  i18nn_a6e757d6312039e4:"Pornography or other information that violates national laws",
  i18nn_23b5f9ce0d48673d:"The system record of this site may be used as evidence of your violation of the law",
  i18nn_b9b850a26aa3fc61:"Product information",
  i18nn_d430ed5f1fd86582:"Price of goods on this site",
  i18nn_f9db93b87e08763b:"number",
  i18nn_0065f7b5452a359b:"Whether there are goods and other commodity information will change at any time according to the market situation and sales situation",
  i18nn_850cd73094d2765e:"There is no special notice",
  i18nn_ae88867720c619c8:"Due to the huge amount of goods information on the website",
  i18nn_661a588c5b9caa79:"Although we will try our best to ensure the accuracy of the information you browse",
  i18nn_ede444f1380c40f7:"However, due to the network and computer terminal compatibility and other objective reasons exist",
  i18nn_7996d7085d24284b:"There may be some lag or error in the information displayed on our website",
  i18nn_528e55db5929b0ee:"Please know and understand this situation",
  i18nn_b02302fc52e11ab7:"Error correction is welcome in the world of reciprocity",
  i18nn_0d17ea8133d5f70e:"And according to the situation, we will give some rewards to the error correctors",
  i18nn_32054cc7f7ee7f36:"Limitation of liability and uncommitted guarantee",
  i18nn_acfdb3a5fcb125c4:"Unless otherwise specified in writing",
  i18nn_31a4bba7da32d20b:"This site and all the information it contains or otherwise provided to you through this site",
  i18nn_e2b0171973515a26:"data",
  i18nn_e17d27d6953f7e51:"goods",
  i18nn_06f19aa00900c2e1:"Including software",
  i18nn_22fe88e60adbafb1:"And services",
  i18nn_a8320eb62b59abe9:"All in",
  i18nn_7edccc097803a6b5:"According to the status quo",
  i18nn_200d41b16c4a78c9:"By existing",
  i18nn_1e02c820196d1d59:"On the basis of",
  i18nn_9cef3573f3925e97:"The operation of this website and the information contained in this website",
  i18nn_5eacb8fa64e8905f:"And services in any form",
  i18nn_23acb0eaddb19069:"Express or implied representation or warranty",
  i18nn_a6f61a0a98b035e4:"Unless otherwise provided by the laws of the people's Republic of China",
  i18nn_da8bb1339f6bb02a:"For example, due to force majeure or other reasons beyond the control of this site, the sales system of this site collapses or cannot be used normally, resulting in the online transaction can not be completed or the loss of relevant information",
  i18nn_583c0f6302128d13:"Records, etc",
  i18nn_42cfc7b2859838a7:"Reciprocity world will reasonably try its best to help deal with the aftermath",
  i18nn_c76acd10925180f7:"Agreement renewal and user's duty of care",
  i18nn_396d9d81b8028d59:"According to the changes of national laws and regulations and the needs of website operation",
  i18nn_71ab57571973f47f:"Reciprocity world reserves the right to amend the terms of this agreement from time to time",
  i18nn_875a73804f3c06a9:"The revised agreement will take effect once it is posted on this website",
  i18nn_43c8834e693d577b:"And replace the original agreement",
  i18nn_86f09fc76744266d:"You can check the latest agreement at any time",
  i18nn_7e3859ed93aa2cff:"You are obliged to pay attention to and read the latest version of the agreement and website announcement from time to time",
  i18nn_a16474cf808d3717:"If you do not agree with the updated agreement",
  i18nn_b81983eb6355dd7b:"May and shall immediately cease to accept the services provided by reciprocity world under this agreement",
  i18nn_b2f70a947a4f8338:"If you continue to use the services provided by this website",
  i18nn_d92c206b1fe1fff5:"It shall be deemed to agree to the updated agreement",
  i18nn_821407e254dd6c00:"We suggest that you read this Agreement and the announcement of our website before using our website",
  i18nn_ea3adaa4544cb4af:"If any of the provisions of this agreement is deemed to be repealed",
  i18nn_0c5541318b646707:"Invalid or unenforceable for any reason",
  i18nn_e64b61ddaf269ac1:"This clause shall be deemed to be divisible and shall not affect the validity or enforceability of any other clause",
  i18nn_79aff516affb1636:"Any user who has violated one or more of the relevant laws and regulations or this agreement or the use rules of reciprocity world confirmed by reciprocity world",
  i18nn_1881093806c26ab5:"Reciprocity world has the right to delete relevant contents at any time without notice",
  i18nn_8815e67ded9ed035:"shield",
  i18nn_b2a368f1e04b4df8:"And depending on the circumstances of the behavior, the illegal account shall be punished, including but not limited to warning",
  i18nn_8b214aeb6795d1f5:"Restrict or prohibit the use of some or all functions",
  i18nn_9b69450aa736a152:"The account is blocked until it is cancelled",
  i18nn_c569de36eb902322:"Decide whether to suspend or terminate the use",
  i18nn_2c427ade9cbf7292:"And all the consequences caused by it shall be borne by you",
  i18nn_828fa526a9adb7e4:"Other written agreements signed by the user and reciprocity world based on transaction cooperation are inconsistent with this agreement",
  i18nn_172a2e0ac17bb8bf:"The written agreement signed by both parties shall prevail",
  i18nn_8fdf57085475a475:"You understand and agree",
  i18nn_dcb5adf4319ad531:"Due to your breach of this agreement or the relevant terms of service",
  i18nn_15351f34c7f1ef6e:"Any claim that results in or gives rise to a third party claim",
  i18nn_00b71a9fcad1312a:"Claim or loss",
  i18nn_3b0ba10bd38ebda4:"You should take the responsibility independently",
  i18nn_87a9ae2f109fbb01:"The world of reciprocity suffered losses as a result",
  i18nn_4ff40e75d2d61877:"You should also make compensation",
  i18nn_75ecb79dc6f5ea71:"Jurisdiction and application of law",
  i18nn_ff0abe69f2fc4d64:"Conclusion of this Agreement",
  i18nn_308ff9434d6bd57a:"The effective laws applicable in the mainland of the people's Republic of China shall apply to the execution, interpretation and settlement of disputes",
  i18nn_00d7f9783b600d66:"But it does not include its conflict of laws rules",
  i18nn_a8e12205ffa12f70:"In case of any conflict between this Agreement and applicable laws",
  i18nn_acde2cb292359539:"These provisions will be reinterpreted in full accordance with the law",
  i18nn_ad5a6dfbc56fcc23:"Other terms in force shall remain in force",
  i18nn_1ac0f8abb3d1a0fe:"In the event of any dispute between the parties regarding the content of this agreement or its implementation",
  i18nn_63394f8be3f81666:"Both sides should try their best to solve the problem through friendly consultation",
  i18nn_3506c985d2c0da52:"When negotiation fails",
  i18nn_d509449d0015d3c7:"Either party may bring a lawsuit to the court of the mainland of the people's Republic of China with jurisdiction",
  i18nn_97e49d00bcc4c17c:"other",
  i18nn_4a07259fcecc1325:"The owner of barter world refers to the business entity of barter world that is licensed or filed by government departments according to law",
  i18nn_fadbffb894d983df:"Any article of this Agreement shall be deemed to be repealed",
  i18nn_0ccb8c44387fd7ca:"Other rights not explicitly authorized in this Agreement shall be reserved by reciprocity world",
  i18nn_79a687415baa4dbf:"In exercising these rights, you must obtain the written permission of reciprocity world",
  i18nn_3f0af30c152f53d8:"If reciprocity world does not exercise any of the above rights",
  i18nn_b5bac76ee0722166:"It does not constitute a waiver of the right",
  i18nn_c0941f9bace00590:"Reciprocity world respects the legitimate rights of users and consumers",
  i18nn_40106d96a3fb5cdb:"This Agreement and all kinds of rules published on this website",
  i18nn_99c54f78e5584cdf:"Statement and other contents",
  i18nn_9a4bd82f308cdf28:"All for the better",
  i18nn_d36f59b156616013:"More convenient for users and consumers to provide services",
  i18nn_17379360b807e5e5:"This website welcomes users and all walks of life to put forward their opinions and suggestions",
  i18nn_86ccd6fccf1c1e7f:"Reciprocity world will accept and modify this Agreement and all kinds of rules on this website",
  i18nn_0f03037ecaf1990d:"Page not found",
  i18nn_ca5a01a5adf20fe7:"return",
  i18nn_e2ef45e045968e11:"Import",
  i18nn_e8c8c5ee90b04380:"Uploaded",
  i18nn_d54012286fece209:"Download address",
  i18nn_fb949604ce64a27d:"Buyer Center",
  i18nn_e127ec3da1353c98:"Receipt bill",
  i18nn_f5d43732e3f6cf4d:"enclosure",
  i18nn_79fd9ff9de54ef02:"Initialize upload control",
  i18nn_6e1b866b2add32bb:"Upload callback",
  i18nn_3144aad544eb9ac4:"Upload succeeded",
  i18nn_d63854ffd4e0f2df:"Total upload",
  i18nn_7f18cb0ceb569eb0:"Attachments",
  i18nn_ad4345dbaabe1479:"Submit",
  i18nn_bc868e024b80d2e3:"Submitted successfully",
  i18nn_de017dafc266aa03:"Successfully deleted",
  i18nn_2e58cb9b52e2a214:"Deletion canceled",
  i18nn_9e5e4dfbc3ec6aa9:"Submit information",
  i18nn_4195755cd53e871a:"query was successful",
  i18nn_d74d0bd89431d6d5:"Query failed",
  i18nn_b79ec1aa21d476b6:"Abnormal items browsing",
  i18nn_41413cddfa61538c:"Operation tips",
  i18nn_5beb6ed7e8fdff3e:"This is the input of the warehouse",
  i18nn_16aa273f2941c9b5:"data",
  i18nn_e0f97fb9747905dc:"code",
  i18nn_209a1dc79c180718:"English title",
  i18nn_9db48ed0e8ec64a6:"value",
  i18nn_96f1a4b17e75892d:"weight",
  i18nn_38bc71c0dc55904b:"Dimension unit",
  i18nn_0e60303b30d5d4b4:"long",
  i18nn_6cd31871f8528dd5:"wide",
  i18nn_93c9ea4a0e52c641:"high",
  i18nn_004617dc47191cb8:"safety stock ",
  i18nn_184333c81babf2f1:"type",
  i18nn_abf9f4b8c93d641e:"order number",
  i18nn_4531476fa35570f0:"Waybill No",
  i18nn_d7315fb8114eb446:"cost",
  i18nn_34bf5c39dd2578eb:"Modify pop up window",
  i18nn_c0246c55b9cac963:"add to",
  i18nn_1b4525c800280581:"Submitting",
  i18nn_039b1175cdb03703:"Support keyword fuzzy search",
  i18nn_66880274fe53fdb2:"Recommended upload size not less than",
  i18nn_38bae5210404815f:"Picture of",
  i18nn_2bbd3825f5d84377:"Equal format",
  i18nn_c9494357a4632b3f:"The recommended size is no more than",
  i18nn_c9b828ca934ba6c6:"Only numbers and letters can be underlined",
  i18nn_d5b3460b4b868e7b:"No more than",
  i18nn_0c9acfecfc4ec2eb:"character",
  i18nn_ad8c1e33ebc7b51c:"No more than",
  i18nn_b6bf0a07fe26f74f:"Company",
  i18nn_2cba96917484569a:"length",
  i18nn_9e24e0e4745f4948:"width",
  i18nn_c5fa0857bc0df2d6:"height",
  i18nn_adbec0a43bad1521:"view picture",
  i18nn_0200bd47bb4bb83d:"today",
  i18nn_84b73bfc6dada445:"yesterday",
  i18nn_e3f3138cf5ba3f6d:"A week ago",
  i18nn_c09a4ec2fe473b0a:"modify",
  i18nn_306b9a84a664e731:"Enlarge the picture",
  i18nn_16853bda54120bf1:"whole",
  i18nn_7e14d2cd3996dcd2:"Submitted",
  i18nn_885c3cdb72cb2e0b:"Signed in",
  i18nn_c24587738eb25651:"Jump to page",
  i18nn_22105cf6781b0f09:"Open new edit",
  i18nn_794cc17f5d24dbe0:"Popup",
  i18nn_dc2f299dc3aeffb3:"Reset",
  i18nn_649d9798f7590713:"Shallow copy",
  i18nn_e386c58d13efe5af:"Merging object properties",
  i18nn_5cbf7e07f157572f:"Are you sure you want to confirm the bill",
  i18nn_fa91b99043676081:"Open edit",
  i18nn_5d43cbeb679e9830:"Single",
  i18nn_1df24953a2a13e57:"Multiple",
  i18nn_7b80e66b535a3732:"Please select the data to operate first",
  i18nn_ed492a621bbf4ecf:"Click the pop-up window in the status of non accounting",
  i18nn_e741d17b1fd891c2:"only",
  i18nn_ec54decc8e274bbc:"Out of account",
  i18nn_d6f109b7bee2f33f:"Status data",
  i18nn_93f5ca01b006206c:"operation",
  i18nn_002e3156942b6047:"Please check",
  i18nn_d1ccda856600ae3a:"Reset input box",
  i18nn_c91d861ccbaddcd5:"Query data",
  i18nn_138ce5945e0e273a:"paging",
  i18nn_e8ec92802315a287:"Are you sure you want to",
  i18nn_e969f83400f78bc5:"Through the bill",
  i18nn_db210f50b5fde38d:"Update specific data",
  i18nn_aefbfa41c779d472:"Specific data",
  i18nn_c524cff2488f2ebd:"success",
  i18nn_7a4aff235c9a01dd:"fail",
  i18nn_55a803f40c4b3790:"Call back after selecting data",
  i18nn_dd8b4f0bf9ce1e74:"Query data dictionary",
  i18nn_9742b74b5a4c77d4:"Query data dictionary interface",
  i18nn_5b14580d813b4a45:"Information matching fee",
  i18nn_1b613e926a1e65dd:"Details of information matching fee",
  i18nn_64fed2851f79d875:"Customer code",
  i18nn_7b27c6b2a3f93412:"Package number",
  i18nn_e0c16ac094cc2bce:"Product code",
  i18nn_877c5a0e44a0eb07:"Product picture",
  i18nn_9eeccc6d6f1ff32f:"Product name",
  i18nn_8e01866868930d09:"weight",
  i18nn_ad4b0047e16d89ae:"Unit weight",
  i18nn_57936a4767ded7f5:"Volume unit",
  i18nn_5cedfd4fa0b91b4f:"Number of incoming pieces",
  i18nn_ebe6f6c25130b09a:"Type of warehouse rent charge",
  i18nn_c8239d27c661e123:"Warehouse rental unit",
  i18nn_b23004db5349dfd2:"Chinese name of product",
  i18nn_05141ac0139eaf01:"English name of product",
  i18nn_791e36963e37617a:"Custom encoding",
  i18nn_198738759379a3c0:"Customs code",
  i18nn_73d237ea940222ce:"Chinese name of declaration",
  i18nn_9894786aad05f849:"English name of declaration",
  i18nn_fe28507421c4c3b3:"Declared value",
  i18nn_11e41b2ec3d872ed:"Unit weight(kg)",
  i18nn_73bbf51487ec69e9:"common",
	"73bbf51487ec69e9":"common",
  i18nn_0a8c7b53565de4f4:"individual",
  i18nn_b7a10fe4e9528362:"Packing list No",
  i18nn_ed47d0c198c61d01:"Port of departure",
  i18nn_d3d95bf498ae6cdb:"Arrive at the port",
  i18nn_04e92fdefcddec84:"Departure date",
  i18nn_b5c03c6f1b9b1e28:"arrival time",
  i18nn_b799f516e3940e3f:"Packing list type",
  i18nn_10229dd4c7509cdb:"If not, the system will generate it automatically",
  i18nn_4ff968abd6e2fc12:"This field cannot be edited when editing",
  i18nn_b954f8829728e9d2:"Loading",
  i18nn_31f887c1ada79a99:"Product code",
  i18nn_a332a38ed3832d9d:"Warehousing number",
  i18nn_209fdd8f47e20fbf:"Customer number",
  i18nn_18d7892498938435:"customer",
  i18nn_ef6eeb5bf7c4a821:"Select warehousing data",
  i18nn_4c0e3495d9042ebf:"View details",
  i18nn_e49d33421617c0da:"Download template",
  i18nn_bb8f8c1e0b6e7000:"Open the stock in selection box",
  i18nn_f4a19c201427a0b1:"Packing number",
  i18nn_e513671dd2474a29:"Arrival date",
  i18nn_643e08ba53f72f39:"Packing type",
  i18nn_5e7740c52e22eefc:"number",
  i18nn_367f1e0c18ec661a:"Packing quantity",
  i18nn_b9d4d3f6183bf3c1:"Packing number",
  i18nn_1108b3e1ecd1fdce:"Forwarder customer",
  i18nn_e6bdd31025bb4f9f:"detailed",
  i18nn_9e22ab6de55df607:"Open attachment upload",
  i18nn_b713a5cc2e38eb89:"Successful callback of attachment upload",
  i18nn_4b038d5cbc643471:"deliver goods",
  i18nn_634b4be09ed29f37:"Are you sure to ship",
  i18nn_cc62f4bf31d661e3:"Prompt information",
  i18nn_34f5f82ff9c257b5:"Open selection data",
  i18nn_fa4b8c97858f967d:"calculation",
  i18nn_a3b3a614f0e2b584:"Open the import pop-up window",
  i18nn_4dadf13ebce2167d:"Unknown file format",
  i18nn_c8e99e36cefd743a:"File read failed",
  i18nn_770533a085119cc0:"Packing list details",
  i18nn_850ab41707d9ec89:"Review status",
  i18nn_ea71165e067be24e:"Total weight(kg)",
  i18nn_5ada761d1917f8bc:"Total volume(m³)",
  i18nn_a386a6933d52dad8:"Chinese name of product",
  i18nn_fe66b0deddea04d6:"Warehouse rental unit price",
  i18nn_3df520fd9dc4bdeb:"Number of pieces in stock",
  i18nn_fdd8426d7b8869c5:"Not received",
  i18nn_619d7e563f879811:"Receiving goods",
  i18nn_1dfb3a065d3ffe39:"Receiving completed",
  i18nn_4280e1909724fc0d:"my",
  i18nn_6a54fe4c349d623e:"Packing",
  i18nn_f410ad11461131d1:"Accounting period",
  i18nn_68efabd029c860a9:"Select a date",
  i18nn_e35175fcc579b438:"Bill number",
  i18nn_b6c654901e9a6632:"Please enter the bill number",
  i18nn_f36cf87cddf3a966:"Incoming product information",
  i18nn_b071e67922639ea1:"Actual number of shelves",
  i18nn_bb3ea0fc187db71d:"Current number of shelves",
  i18nn_bb0b485a98f17aea:"Total volume on shelves",
  i18nn_c8927f1a24eb4f03:"Current total volume",
  i18nn_4157ea5a8c62064b:"Total weight on shelf",
  i18nn_98d11164e0f1d3ba:"Current total weight",
  i18nn_3a6eb04ef8f3327b:"Location",
  i18nn_9fb9a5e4507379f6:"Select location",
  i18nn_b83597729ab29694:"Select customers",
  i18nn_29991afa9781d554:"Customer name",
  i18nn_6cf7440cec94c02d:"Customs and declaration information",
  i18nn_5f86feae97e3658f:"Customs code for export declaration to customs",
  i18nn_045d8c4b6a56fc13:"It's good for customs declaration",
  i18nn_cb16ed92e2156bec:"Please try to maintain the accuracy",
  i18nn_3f0c9d48047a36b8:"Value of goods declared to customs",
  i18nn_ebd4d78a10f62509:"Chinese name of goods declared to customs for export",
  i18nn_7486bac6ef7f5775:"English name of goods declared to customs for export",
  i18nn_d48bb83a82237f60:"Specification information",
  i18nn_f340f0ba85ea230d:"volume",
  i18nn_fa70be657469e09a:"Warehouse rent information",
  i18nn_d552aa9f1c14363f:"Save edit",
  i18nn_0ddb67d8d6d01ad4:"Save new",
  i18nn_f5388c326e8504f1:"Top tip navigation",
  i18nn_129bddcc1b0afba9:"Warehouse distribution center",
  i18nn_01d515661b01a0b3:"Home page of reciprocity",
  i18nn_b4bced00356095dc:"sign out",
  i18nn_738246ccd0ca0224:"drop-down menu ",
  i18nn_2e35c13f129cf855:"Golden cake",
  i18nn_d26ae04e63fe0940:"Lion head",
  i18nn_fec31d0353b1a1db:"Snail powder",
  i18nn_cff7518d379f060f:"double-layer steamed milk custard",
  i18nn_0be9f3f483e1d46d:"Oyster omelet",
  i18nn_c90f53f2b5dda904:"Abnormal packing list",
  i18nn_2f3c98d5d7102a27:"Under development",
  i18nn_12314fc8503ef537:"E-commerce shop configuration",
  i18nn_5646bd8c216616d8:"E-commerce shop",
  i18nn_4de574b0302f8cf5:"Abnormal parts",
  i18nn_095d28bd0cab80f5:"My return",
  i18nn_8460cd05d27dbc2a:"My forecast",
  i18nn_7260d7dba0268bbb:"My transshipment batch",
  i18nn_00b8e68f8e05f85b:"My transfer package",
  i18nn_dbb873b021788040:"My freight",
  i18nn_11f52e2d7184cee5:"My bill",
  i18nn_ac662af32923cd88:"User manual",
  i18nn_ce764b74be1b9a90:"Work order",
  i18nn_2f7a4906f66bffd1:"My work order",
  i18nn_ab91102fbd3b1073:"Accessing component instances",
  i18nn_78d6576e0ca0ee79:"User not logged in",
  i18nn_d0e490d0c3ca7eb0:"Please contact the administrator",
  i18nn_6b57a5faf337b87b:"unknown",
  i18nn_40729ab35d7d0124:"The manufacturer has not been certified or has not passed the audit",
  i18nn_a01c17ca2e680a0a:"From",
  i18nn_30746594c861e3f9:"Partner Center",
  i18nn_cf03031ed0359ac6:"My recommendation",
  i18nn_978549a11051cb4d:"Module entry",
  i18nn_38f0e339b7be1133:"Unknown merchant",
  i18nn_5ae549ae0b885243:"Log in now",
  i18nn_e32f34fdec0dd065:"Jump",
  i18nn_74708f3a8699305a:"Log out",
  i18nn_096e05703cad0846:"Left side navigation",
  i18nn_52f541b927acacc6:"Top pull down",
  i18nn_e8564657bbe9ca53:"Create",
  i18nn_e0c2e9f28f36e148:"Packing list",
  i18nn_581bc71c027386cb:"courier number",
  i18nn_597b3ae5c6de34d0:"Rows to expand",
  i18nn_9ac8c5faf85033de:"The element of the value is",
  i18nn_ed085b86f07e3e99:"value",
  i18nn_c301de1e444a3e90:"To be delivered",
  i18nn_fcf5923a042705f3:"To be recalled",
  i18nn_190dca403b6f540a:"Cancelled",
  i18nn_3cce40fc2b7d333c:"Click row to expand the table",
  i18nn_7414f31517552941:"in",
  i18nn_cbc1a1fd9e6c9d70:"We've set it up",
  i18nn_663d98f9fed21ec3:"Value to each row of data",
  i18nn_3a671c04d1dc0d96:"Select row",
  i18nn_c0a0adbd5c04ac55:"Click to select",
  i18nn_6ebe6ec4eb502f95:"Multiple choice",
  i18nn_2109f5a7ca56dc5b:"Delivery successful",
  i18nn_dca4d33bdc72009f:"This is",
  i18nn_d178c1b96a5987fe:"My expense details",
  i18nn_a4eca6ff2588d791:"Fee name",
  i18nn_3eb79cfe71bbae0b:"Warehousing code",
  i18nn_15f3bfa87d2f503e:"payment",
  i18nn_45d95c470c095145:"Write off",
  i18nn_62d1f13b854eb1f7:"Bill management",
  i18nn_4c9736c24ed29b16:"User account number",
  i18nn_046efc85af065a99:"User name",
  i18nn_71e0c6669e3249b9:"Manufacturer",
  i18nn_c5832c81556ae8d3:"Is it valid",
  i18nn_e76d885ae1c74d4f:"No",
  i18nn_360ff6d0a099ab59:"Sub account No",
  i18nn_e82c9fff83ec2d91:"cell-phone number",
  i18nn_44beaa6c8136adc9:"Sub account",
  i18nn_da41bc0c21860fc2:"Main account sub account relationship table",
  i18nn_cad67c74ecedf941:"Sub account name",
  i18nn_b9338a40e13a4b1b:"Sub account status",
  i18nn_61432878e10a2483:"Effective",
  i18nn_8ef98f00486f7801:"invalid",
  i18nn_f5a9e0830d476523:"Mobile phone number is required",
  i18nn_4b7dc78116a958d3:"Please input sub account",
  i18nn_4b168d52df99a996:"Please input the main account sub account relation table",
  i18nn_a47da648bd79c2fc:"Please enter the sub account name",
  i18nn_3821259f4f037ccf:"Please enter sub account status",
  i18nn_1d0edc05a3e3826a:"Billing Pagination",
  i18nn_f52a39ff3d95336f:"My invoice",
  i18nn_0f0d6fbc07d109be:"Import Shipping Order",
  i18nn_a2df50399ce1bf10:"Delivery number",
  i18nn_e44e9a291d80c593:"Recipient name",
  i18nn_5acbec83efb27445:"Warehouse number",
  i18nn_b29bf013aaec9ce6:"Recipient number",
  i18nn_c4913ab43009b365:"Postcode",
  i18nn_b166e4e8fe9513fa:"country",
  i18nn_f9514183570a10fe:"province",
  i18nn_7cab79716236ebb0:"State",
  i18nn_8db45b615b72b7a8:"Address one",
  i18nn_5165f3ee9377af83:"Driving mode",
  i18nn_99b88de52e382ca4:"Expected arrival date",
  i18nn_74ed28f373ea14da:"Commodity number",
  i18nn_2ec0f364e8bbaa4c:"Number of deliveries",
  i18nn_0f859b9b45732564:"Arrive at warehouse",
  i18nn_21140770c20d6f9b:"Delivery instruction",
  i18nn_79784145b384f8da:"Download bill of lading",
  i18nn_a4bf1488f866249b:"Set the data that does not need to be transferred",
  i18nn_73dd0d43f580bc68:"Import succeeded",
  i18nn_6b3b02fd683263ca:"clear",
  i18nn_32f88191102e9648:"Invoice details",
  i18nn_89413403ccaade4a:"Arrival country",
  i18nn_0607c7a5960734f1:"Arrival Province",
  i18nn_ba8ce6a27a2ce7ef:"Get to the city",
  i18nn_aefe1a569c9a9d4c:"Arrival address one",
  i18nn_daa23bc02afc02a7:"Arrival postcode",
  i18nn_f566f50ef680920c:"Work order type",
  i18nn_3e358d140504ab46:"Work order content",
  i18nn_8200a25ab641ba97:"Attachment information",
  i18nn_8bafd5202547e50d:"journal",
  i18nn_1f4c28db7c80a5f5:"Rich text",
  i18nn_a38569d545d8e238:"Create work order",
  i18nn_89221ad15d2ec113:"File name",
  i18nn_bf0a1c4ff90aadcb:"Allocated",
  i18nn_2f09651c43c2a375:"Processed",
  i18nn_8039faf6d63be278:"return",
  i18nn_89d50ef8fe430e48:"Select or search product categories",
  i18nn_b9aa51e503858bf1:"It is recommended to upload pictures with size not less than",
  i18nn_a8f12f1452cf72c8:"Changes in commodity categories",
  i18nn_462f2e7b92066e43:"The basic attributes of commodities in this category",
  i18nn_b93f7c8c4e2346f8:"Saved successfully",
  i18nn_a28112c8cca3514f:"Historical total inventory",
  i18nn_c5820dce4b05019f:"Total stock price",
  i18nn_6b3c6618251b6db5:"One dimensional code generation failed",
  i18nn_315a0042286142d5:"New only",
  i18nn_25dd68f4fe19dc05:"Do not read original record",
  i18nn_dc8d3ceec6b8c3cd:"All upload successful callback",
  i18nn_42b5b20194a2f1e3:"Error in saving to background",
  i18nn_31dba98ac03ecd2a:"Reexecution",
  i18nn_eb74668dabb99d8d:"File upload and callback",
  i18nn_ff6fd823ffab7e6b:"export",
  i18nn_1d5fb99b98e26365:"bar code",
  i18nn_1903bd7b05bf1edb:"Inventory statistics",
  i18nn_d42355e6aef72227:"time frame",
  i18nn_9eaa906ebc446062:"Storage time",
  i18nn_4d3765c415bb4462:"Storage age",
  i18nn_de53ab10322cd38a:"stock",
  i18nn_61cac7cb94d81cfe:"statistical time",
  i18nn_0e240c5802d17fea:"Unit volume(m³)",
  i18nn_cffd7c9783c11047:"Please select a date",
  "i18nn_f353b1ebbdc25be4": "fuzzy search",
  "i18nn_3ee4c9b76289e93a": "Please enter a keyword",
	i18nn_54153ccc0c756e9f:"Help document",
	i18nn_9fcdcb3a067903d8:"chinese",
	i18nn_bec36a3c4521e2a6:"Download help document",
	i18nn_337e08570aa0d4bb:"Login address",
	i18nn_7cfbd9eb6d861781:"Defend your own interests",
	i18nn_245883c80f181c4a:"download",
	i18nn_e8a005a836fe3f74:"Import template",
	i18nn_de604a12d7c44f86:"And fill in according to the template",
	i18nn_cb117cbadbd7bca6:"Basic information of",
	i18nn_84ac965d0c7ec965:"Select an edited",
	i18nn_3e0e3e20c87c032f:"template file",
	i18nn_2c480b655ec65c73:"Import data",
	i18nn_c1a01d78ceb19a0e:"Submit and save",
	i18nn_127fc656c2c60c96:"Submit warehousing and packing information",
	i18nn_4084248573b3c16b:"Download packing list import template",
	i18nn_f0eccb032002dce7:"And fill in the basic information according to the template",
	i18nn_0fda388bb7e4efa6:"Select the template file to edit",
	i18nn_3fa0edc86d3975ca:"After the application is submitted",
	i18nn_ab0233b8eb35c316:"After receiving the goods in the warehouse",
	i18nn_8a2a9726b2ba15c6:"Will be in",
	i18nn_026bafd47c1c7b53:"You can see the inventory quantity in the menu",
	i18nn_f925d9b48d8c1d45:"explain",
	i18nn_e88cfdf1e83abbf8:"The delivery function mainly corresponds to the customer's delivery application",
	i18nn_e0993db310d9ae88:"among",
	i18nn_36bcd7118257fbcc:"Corresponding to the card dispatch transshipment business",
	i18nn_789610dd6a89b2fb:"The warehouse is right",
	i18nn_684a2afb069b6016:"One for one",
	i18nn_460eb62a18c2a2d8:"Mainly from warehouse to consumer",
	i18nn_99426a7f0ce15317:"My invoice instructions",
	i18nn_50ab009090a8fd27:"The user fills in the delivery order number",
	i18nn_151918b1d3959016:"It's usually an application",
	i18nn_22f9eed64eb44261:"Warehousing",
	i18nn_1887667de3c3e159:"without",
	i18nn_037ffd217472355a:"Press",
	i18nn_61ea88052f6a708a:"Number of applications for the day",
	i18nn_2c1c9a7a65719778:"Warehouse for shipment",
	i18nn_84a37e1d96fceb5b:"Drop down selection",
	i18nn_2697e56984504ef2:"There are two ways of driving",
	i18nn_cff3375bd6822fd8:"Kapai is sent by the warehouse",
	i18nn_62c9dfea123aa953:"Pick up the car for the customer",
	i18nn_0d1e1755421f2f56:"When the vehicle is self lifting",
	i18nn_51627c0df8478273:"Need to enter the approximate time to extract the order",
	i18nn_2335093ed77821fd:"After completing the template",
	i18nn_15fa80fc0e736518:"Just do it in order",
	i18nn_03151bd9eb151c75:"When the next step is finished",
	i18nn_13647b4031487392:"As shown in the figure below",
	i18nn_1b6eeb3796def27e:"Click the Save button directly",
	i18nn_1949974dc095a263:"Submit delivery order",
	i18nn_bb346b646af55bd8:"click",
	i18nn_8730be85c2f45b7c:"Import consignment note",
	i18nn_e3acf0048c7a6c5e:"The import interface pops up",
	i18nn_ab1510a145ebdd7b:"Download delivery template",
	i18nn_fb021d2110d3bbba:"Then edit the shipping template",
	i18nn_12b58cab1beafdb7:"Select the shipment template to edit",
	i18nn_5d24be764d968b53:"Read data and Preview",
	i18nn_79d3e9d13fe8e0a0:"Submit data for distribution",
	i18nn_1d6f1f17cb9ae0ff:"Import Preview",
	i18nn_1a1a5bd37aef792a:"Submit instructions for issuance",
	i18nn_0b31c81f074b836e:"It cannot be deleted after submission",
	i18nn_ef495fdad3f7156f:"It's a single submission",
	i18nn_4dc2da02fb9eefbe:"Yes, batch submission",
	i18nn_71e54c38a374d9dc:"Only stock is sufficient",
	i18nn_0c58aa47afa3ad82:"To submit successfully",
	i18nn_f77f473d27f48bdc:"Proxy user",
	i18nn_556c0672dcecba13:"set up",
	i18nn_b00576fed9b3a1f2:"account number",
	i18nn_6ede9303c2d93ede:"Unbundling",
	i18nn_2c38440ac06aae42:"binding",
	i18nn_2408b14cc2ddba03:"Are you sure you want to unbind",
	i18nn_ef71790e2ba5798c:"To be extracted",
	i18nn_61204dd04df0ad13:"Extracted",
	i18nn_851c121d787993a2:"Inventory statistics details",
	i18nn_350662278f221eee:"Inventory statistics summary",
	i18nn_ec153a16f143e276:"agent",
	i18nn_fd012984453fc8bc:"Auto jump in seconds",
	i18nn_2ecc513d94e4ca25:"on-line",
	i18nn_1e24cf708a14ce81:"test",
	i18nn_d0923f3abe67b5cd:"Test new",
	i18nn_88e500634af9eff9:"Cancel shipment",
	i18nn_4368252da5c6ce3f:"Are you sure to cancel shipment",
	i18nn_d0a75d1ee7f93bb7:"Opening authorization page",
	i18nn_cc1f108900897a83:"please wait a moment",
	i18nn_907aa9302190b8b6:"Open Amazon authorization",
	i18nn_67c2289162532917:"Get authorization information",
	i18nn_294ea7a0c08d79ad:"new",
	i18nn_6b7b1b98db01410f:"Authorizing",
	i18nn_9255f35537b4fa0f:"Do not refresh this page",
	i18nn_c5f937578ab0c290:"Authorization successful",
	i18nn_b2fcb58dd1716049:"Automatically after successful authorization",
	i18nn_4b0b80a8641a28d8:"Jump to",
	i18nn_0698cce1a67a1ff6:"Authorization list",
	i18nn_5ad16932d8972191:"page",
	i18nn_d9e17eb65f5720f7:"View authorization list",
	i18nn_10e868dfd9e69b86:"No authorization information",
	i18nn_fc7197a70838eeeb:"to grant authorization",
	i18nn_7560acdefdcb54e2:"shop",
	i18nn_b7cb4598d6ff9057:"Skipping",
	i18nn_6b2f2af88c6e0080:"privilege grant failed",
	i18nn_5a1dc5ed6de34b73:"Please close and try again",
	i18nn_37181c74559db182:"order",
	i18nn_58169349e34744e8:"Select date and time",
	i18nn_b17342e22700eb32:"Select account",
	i18nn_43ef2ec33ab0b489:"Pull order",
	i18nn_3f514bfb88a0f35b:"Refresh product information",
	i18nn_a1a651a01a9c6815:"Refresh address",
	i18nn_fadd975428c8c511:"Refresh buyer information",
	i18nn_377fa66b0b28ce31:"see",
	i18nn_26fb9a484faddf26:"Successfully pulled order",
	i18nn_6ce6dc58d2111cc9:"Failed to pull order",
	i18nn_a33831a969cda728:"Print order selection",
	i18nn_b77563b9e5567c4a:"Update product information",
	i18nn_fc089dc894530f12:"Please select data first",
	i18nn_10556bca2482ac06:"Update address information",
	i18nn_a7926683203307f0:"Update buyer information",
	i18nn_4326c21ea533d819:"Update alias",
	i18nn_efc15adf80699301:"alias",
	i18nn_2edfb7fc34a339ef:"Open the authorization pop-up window",
	i18nn_fe735bc696bccb15:"Files",
	i18nn_e5c5067678f4eba0:"Type matching",
	i18nn_04079755a623ba4d:"type mismatch",
	i18nn_f27a40383c5678a4:"Multiple files",
	i18nn_fe8bed302a3c7b11:"The selection will trigger multiple times",
	i18nn_d25a9b29d0bb390b:"Login again",
	i18nn_7c9f46a00e89d0e6:"And assignment",
	i18nn_0e96bf9eabc042e1:"Quadratic assignment",
	i18nn_69972ac8a034ea10:"When the first assignment fails",
	i18nn_e93cde152c0070f7:"Amazon account help document",
	i18nn_37a8f56cb013d522:"Product Authorization",
	i18nn_132d087e57aebb7d:"First step",
	i18nn_25e03120b83ee4ed:"complete",
	i18nn_ee1d5c7dbd6cb012:"Click authorization list",
	i18nn_8438576b80e032e5:"There is an authorization button in the page",
	i18nn_cacc0e30a83291ca:"as",
	i18nn_ac2d3a21f0abd506:"chart",
	i18nn_6e7dc8d081d4682f:"Click the authorization button",
	i18nn_18df313d78befd4d:"Then log in to the browser",
	i18nn_119df87dd841c733:"arrive",
	i18nn_5f1f56b8944041d9:"Authorization details page",
	i18nn_6db4a3f3bcbb5b0b:"Obtain authorization after successful authorization",
	i18nn_8cd90c18e5865c62:"Authorization in progress",
	i18nn_95510f1b6c0b6217:"Get",
	i18nn_cf9ad4949e1328e7:"Select the pull time",
	i18nn_ceb22e2ef5790be0:"Time limit not up to",
	i18nn_509c52c5117ea79c:"Day start",
	i18nn_226f4248cd8f59c5:"Select authorized account",
	i18nn_be84aaa170fd32bb:"Then use the refresh button",
	i18nn_3f6f3197d37909e4:"Get separately",
	i18nn_f9390da3c230d098:"Delivery address, etc",
	i18nn_86cbba37d7f69050:"After obtaining the order",
	i18nn_34f6fc628893f509:"function",
	i18nn_c092102e06374272:"Self delivery of completed orders",
	i18nn_324c42c209968b61:"Self distribution",
	i18nn_2071c6e489a10eab:"Pull to order",
	i18nn_4bc64d9bd19c5601:"And after obtaining the shipping address",
	i18nn_d74f828bb2ce544e:"Can be in",
	i18nn_1594d840693215f5:"Submit shipment information in the menu",
	i18nn_d465868412828ea9:"Order delivery is completed by our warehouse",
	i18nn_a40452d0897a05f2:"After order extraction",
	i18nn_ed2f1bf0e1c29fc8:"The system will automatically update the sales inventory",
	i18nn_05dd214cd4839ae7:"Ensure the accuracy of product information",
	i18nn_6235565b185f0725:"Work order No",
	i18nn_1a88bd0f0d22ca01:"Cancel shipment",
	i18nn_e7b4fae7cec06141:"Receipt completion time",
	i18nn_977ed6286b514ff6:"Container size",
	i18nn_b5d15db0d6323e28:"Only all are",
	i18nn_1e2279a00c4336a4:"Shipped",
	i18nn_357a8f4e971d3c85:"Data can only be operated",
	i18nn_b1813b05de28548d:"Total number of torches",
	i18nn_e7b838a996147e21:"Total boxes",
	i18nn_85a10d3e5ea0e103:"Pieces per box",
	i18nn_bad53577db0da2d3:"Total ",
	i18nn_ae802858dbd29efa:"Outer box size",
	i18nn_89d99a1a68608f3b:"Full container weight",
	i18nn_ebd257df8f7efc53:"Polygon import",
	i18nn_bd6d1ded173dc477:"Upload Label",
	i18nn_a363e5f23b1040fe:"check",
	i18nn_e712a380cef4e31e:"The sheet number and file name must be the same before matching",
	i18nn_6c3dd9349932c839:"Label file",
	i18nn_5fefbf45c9ca6464:"Upload and verify",
	i18nn_ac71ea3f789a778c:"Check surLabel",
	i18nn_582446272516f554:"Matching status",
	i18nn_cf0c41b879762cdf:"Label No",
	i18nn_70e058bc23d52c1e:"Previous step",
	i18nn_aaf7f7f3df61a6a5:"Re upload",
	i18nn_a93e095e532cd424:"Save the matching Label",
	i18nn_f9104346c336651d:"Label saved successfully",
	i18nn_e5664b79012a5cc1:"You can go",
	i18nn_df43e4fc4ab96cfb:"Module list view",
	i18nn_826ff6f5d853fee5:"Label",
	i18nn_658d7f0cc43ab866:"I'll send one on behalf",
	i18nn_b172f8fd2a3c5ce4:"I got it!",
	i18nn_ddb207cbff45b38b:"Upload success callback",
	i18nn_12d6e24b3a3cd441:"When the background error is verified",
	i18nn_4ce9979bfb6576d9:"preservation",
	i18nn_ae61193590bd513d:"Odd Numbers",
	i18nn_ad810b5b659860fe:"File address",
	i18nn_7178a20888e962fc:"No matching Label",
	i18nn_b0c50b6ff9674173:"Courier Services Company",
	i18nn_1324a1d91ae3de53:"Face list address",
	i18nn_daf169d1f7dceda0:"Work order No",
	i18nn_43a3586339251494:"date",
	i18nn_52a80831dd9e024b:"Express fee",
	i18nn_65b7d1be808de93a:"Recipient name",
	i18nn_c3755bab459a2cbc:"company",
	i18nn_30e252ba35657802:"Open import",
	i18nn_e5462a1be765de05:"Work order details",
	i18nn_14b5f4f572736efe:"Work order date",
	i18nn_9b73b93c1607cd97:"Submit work order",
	i18nn_299f1f6fef7c640e:"View a consignment",
	i18nn_915541755261249f:"draft",
	i18nn_50f017673319c5db:"open",
	i18nn_c51542d282edda5b:"Delete data",
	i18nn_3930df65e31d907b:"One consignment import",
	i18nn_a0f2924544ff3ad8:"Operation warehouse",
	i18nn_58041bac1fe7346e:"Editable",
	i18nn_548f4ebaed747bd8:"Edit not allowed",
	i18nn_7375026510dd73e9:"Warehousing packing list",
	i18nn_9f11af7bd0eda795:"Shipment import",
	i18nn_a3b1e5efc70b46eb:"Location query",
	i18nn_f2727d808fbeb34d:"View location details",
	i18nn_036240f3e205cce2:"Location details",
	
	i18nn_785ee8efb6d09b2a:"Billing details",
	i18nn_36edafcc55cf50f6:"Label query",
	i18nn_4ca88dd2e7071844:"Cancellation of billing",
	i18nn_06dd7cb65641390b:"Delivery status",
	i18nn_90429ac39f4f4634:"Billing progress",
	i18nn_48ebfc4319cbac59:"Order No",
	// i18nn_b0c50b6ff9674173:"Courier Services Company",
	i18nn_1f90c9eb506ddf15:"Cancel waybill",
	i18nn_3c2605e54bb69e38:"Billing rate",
	i18nn_3d6502c491aca17a:"Submit and print",
	i18nn_d4d1e037938ae65e:"Do you submit the form",
	i18nn_1cb9e763fc0324fa:"Submitted and printed successfully",
	i18nn_f0cb028847831431:"Do you want to check the Label",
	i18nn_d0bb84f55c074e32:"Do you want to cancel the billing",
	i18nn_327144b46bd2854b:"Query face to face list",
	i18nn_e6d7aa8c4f2fc0c7:"Querying",
	i18nn_32aff432f169204e:"Query Label information",
	i18nn_34e1e57771d4bd81:"Refreshed",
	i18nn_ab719d5d91ae7b5b:"Failed to query Label",
	i18nn_0da14d270576fc82:"Query Label interface",
	i18nn_0b3917ac0797b5b3:"Default optional data",
	i18nn_762ffdac60685e7e:"Details of one consignment",
	// i18nn_1324a1d91ae3de53:"Label address",
	i18nn_d99d790ec4383bfb:"Operation time",
	i18nn_e02e5da233bd816b:"intimidate",
	i18nn_db48f5ae63408e0f:"This is a passage",
	i18nn_7ce351f5edbbf8c0:"activation",
	i18nn_f69f323dc7e9ec22:"Los Angeles departure",
	i18nn_65deb1358842a262:"Billing rate query",
	// i18nn_a33831a969cda728:"Order selection",
	i18nn_77cf8937f28bff9b:"Get selected data",
	i18nn_6cf7f1b329fdc67f:"Query billing rate information",
	i18nn_ea8cd49587f7e54a:"Query billing rate information",
	i18nn_0bd52db433d771f1:"Failed to query billing rate information",
	i18nn_0cdf81c9c7f64805:"Interface for querying billing rate information",
	
	"7b1c2b1adc920d9c": "No.",
	"15b3627faddccb1d": "remarks",
	"93f5ca01b006206c": "operation",
	"1e7246dd6ccc5539": "search",
	"16853bda54120bf1": "all",
	"daaaeb1b7b22b126": "tips",
	"47df8be257c59dde": "loading...",
	
	i18nn_15b3627faddccb1d:"remarks",
	i18nn_93f5ca01b006206c:"operation",
	i18nn_1e7246dd6ccc5539:"search",
	i18nn_16853bda54120bf1:"whole",
	hytxs0000029:"Error in list data",
	hytxs0000030:"List data, request failed!",
	hytxs0000031:"Error in submission, please check the submitted data!",
	hytxs0000032:"Failed to query the data dictionary. Please try again",
	hytxs0000033:"Failed to query data dictionary interface, please try again!",
	i18nn_daaaeb1b7b22b126:"Tips",
	
	i18nn_abdbf97e2d5bdb25:"Sunday",
	i18nn_c2acf27a87ce160a:"Monday",
	i18nn_48ecd1e7be525c75:"Tuesday",
	i18nn_62551a706d64f9c9:"Wednesday",
	i18nn_7c08f11a440bfb1c:"Thursday",
	i18nn_38b82f33d30ed560:"Friday",
	i18nn_54904ecee1cf2823:"Saturday",
	i18nn_66e04914310f2490:"Paris",
	i18nn_0883bea3583d9e51:"France",
	i18nn_731cf5a0b64e40df:"Sydney",
	i18nn_1da458ff9d7a7995:"Australia",
	i18nn_ef1714c43c5bd374:"Chinese version",
	i18nn_a429ebc180de1af2:"Preview or download the Chinese version of the help document of the exchange warehouse",
	i18nn_81066ec9baa79bac:"Site map",
	i18nn_a77d479d6331f8b5:"Flow block",
	i18nn_8f606b4d207d593c:"Border background",
	i18nn_8251de0b6f00b704:"Border color",
	i18nn_4a9b3fd7efaf276c:"arrow",
	i18nn_aed61e6845106632:"Calculate each angle and corresponding",
	i18nn_63d3cd696db1697d:"coordinate",
	i18nn_da10f81b7bb1c75b:"technological process",
	i18nn_6543f7501b1c0d2b:"environment",
	i18nn_5292c42dddf02443:"Line",
	i18nn_08a66132125d21df:"Axis starting point",
	i18nn_401ed99ee5d3944a:"Rotation angle",
	i18nn_5d9cf09b355c7846:"Sharp corner",
	i18nn_a4a5c442f196c734:"word",
	i18nn_a069505e87bedd8e:"Li Dazhao",
	i18nn_eb08cee115c3a529:"May Fourth leader",
	i18nn_0f864f84a5e810d6:"Account information",
	i18nn_004a25fe67f297c3:"balance",
	i18nn_831ab31568a78285:"Recharge",
	i18nn_7b7058faea31b99f:"Add recharge record",
	i18nn_a06e84801d6582be:"Recharge amount",
	i18nn_8c49a4bfd3a78dc2:"Recharge remittance materials",
	i18nn_c4ac0560611963af:"Statement of account",
	i18nn_80d0adba3c341069:"Record type",
	i18nn_46c3f6e0f657e7a3:"Expense type",
	i18nn_1466b1690d2ae404:"Cost time",
	i18nn_7d2ffc27a09ff792:"Start date",
	i18nn_46f7be1133b3e2c6:"End date",
	i18nn_380e1c07ce5a1f06:"After background confirmation",
	i18nn_92016b757a1d82a7:"Recharge data",
	i18nn_169a0780408f1cce:"account",
	i18nn_cd173f918d077f02:"Record name",
	i18nn_5706df6392c2b9ef:"Record amount",
	i18nn_60f5b585fd34c2bd:"Export sort",
	i18nn_c688937c592c594f:"Last week",
	i18nn_a2db995132295e05:"Last month",
	i18nn_502cb8bd057f1605:"Last three months",
	i18nn_030010395a4e1d71:"Paged filter item data",
	i18nn_41d2148209b5edc8:"Recharge record",
	i18nn_fdc34fd7121f9c48:"Update time",
	i18nn_7e06b928579ba3cd:"Add edit",
	i18nn_5c9f3500bc7fc4c2:"Confirm entry",
	i18nn_2bfebf0495b9cdcb:"Switch list on this page",
	i18nn_5b7f75f3443354a2:"Submission and printing time",
	i18nn_50dcfc837d43b873:"Precise search",
	i18nn_61fc9e07fff97930:"Service type",
	i18nn_ab6994d16b9b4366:"Waybill fee",
	i18nn_5f6b842a6ff748ab:"Issuing time",
	i18nn_6267f3aedf895209:"edit",
	i18nn_16c5afb8e869abda:"Attachment view",
	i18nn_76cd201aeb72dba2:"Supporting details",
	i18nn_62a1adfe6207b3c5:"Detailed data",
	i18nn_862bfc135547f78e:"Picking list name",
	i18nn_0bd7d983fe70ea56:"Picking quantity",
	i18nn_6d8cbf8c49d6a7e6:"Total picking volume",
	i18nn_3e1652df125209de:"Total picking weight",
	i18nn_9a42179fb9922dc1:"Product location",
	i18nn_fecdd1b2ab21bef3:"When adding",
	i18nn_fde1f90f1930c21b:"Empty",
	i18nn_3193004ef6dd6bbf:"Required for editing",
	i18nn_442a9ac138407a28:"Beneficiary",
	i18nn_bb5ac0c49150f3b0:"Partner type",
	i18nn_f7e0da8748d5c821:"Distribution mode",
	i18nn_9df79d7120a0a741:"Assigned value",
	i18nn_2345771b0cea8487:"entry-into-force time",
	i18nn_4647ba3fc60f0151:"Warehousing date",
	i18nn_2aeed2ab99c446d7:"Expiration date",
	i18nn_10f91e600df6414f:"Display only",
	i18nn_b44a4a27cac7433e:"Not issued",
	i18nn_27372599992ff988:"Issued order",
	i18nn_a0acfaee54f06458:"Completed",
	i18nn_bcef26d98d690aba:"Cancel face order",
	i18nn_d353bf2887ca0603:"service charge",
	i18nn_3c4a40675f59cd1c:"Total expenses",
	i18nn_5e3158c7817c7d9c:"Please select the departure mode first",
	i18nn_07e0f8f59293e78d:"Please select a customer first",
	i18nn_91e192ea5925de92:"Please select the time first",
	i18nn_886072e393710563:"Print",
	i18nn_7b23bac6ffe353b9:"All can be approved only if they are in submitted status",
	i18nn_3e48b039f79c39a0:"Data bar",
	i18nn_7495b1a692c585b5:"Do you",
	i18nn_97939133a6d30da8:"Cancellation succeeded",
	i18nn_493d12b09fa54b95:"All items can be delivered only when they are selected to be delivered",
	i18nn_8d8b751c8f8c7dbc:"Submit edit",
	i18nn_f96b62dfa31cde45:"Confirm submission",
	i18nn_7f12d7a3ac88b55e:"Enter waybill",
	i18nn_a13684cbb78b8e81:"Merchant bill list",
	i18nn_70678b883f1517f5:"Submit data",
	i18nn_a7d2e953195a5588:"Submit failed",
	i18nn_3c144f7fa49aeaf8:"Failed to query data dictionary",
	i18nn_66261f0ce9d34a49:"Unknown file",
	i18nn_f880656430c365f3:"File cannot exceed",
	i18nn_9cdac116e11d2942:"Please select a file first",
	i18nn_3d62ec1999ded0cb:"Single face synchronization",
	i18nn_b60460790c7f8a4b:"Revocation succeeded",
	i18nn_da15e060427e30de:"i 'm sorry",
	i18nn_daef27ebf42d4adb:"Select the columns to export",
	i18nn_3a7c1bda93906fe9:"Select all",
	i18nn_dd3438b8ee62f146:"Export all pages",
	i18nn_055a45ea5fef0d11:"Filter item data",
	i18nn_9bf17a1c9af26b04:"Insufficient",
	i18nn_7fd7036a24def329:"Article time",
	i18nn_0d147cf4d1f9a421:"Export all",
	i18nn_8dd194f4fe9000f0:"exceed",
	i18nn_45605a8587b5efab:"Batch export",
	i18nn_660fe005150483a1:"Maximum export",
	i18nn_83a93e396379a993:"Export all",
	i18nn_be331b9c430a360b:"Export section",
	i18nn_0d9a272b3124998c:"Export current page",
	i18nn_c432271c995f31a5:"OK export",
	i18nn_5fc8144f38fd1ebc:"Shanghai",
	i18nn_9c0ca340eff4fdce:"Beijing",
	i18nn_cb2ec494b0a4d52c:"Guangzhou",
	i18nn_b9a4aed64afd0cf9:"Shenzhen",
	i18nn_0dd4b237e92f6a8f:"Starting number",
	i18nn_f5b1126b82ff45d7:"Number of requests",
	i18nn_84adf48acbd6ad24:"list",
	i18nn_a1761c0e88af75ff:"Data error",
	i18nn_eb763bfab5d5a930:"Label detail",
	i18nn_e716ca411b2142e3:"Channel code",
	i18nn_3ad90a648c6bfa99:"Service level",
	i18nn_310c8b0f4df16e08:"Order or not",
	i18nn_82b1c8e36ef45bb8:"Whether to issue an account",
	i18nn_728bf6a36a68e566:"Billing progress status",
	i18nn_c6b76f2746d7dc6f:"Print serial number",
	i18nn_e5c3fd867ea4c8e6:"Account code",
	i18nn_673767ab6ddb37cd:"Waybill fee description",
	i18nn_778d871227234f32:"Cancellation of print order succeeded",
	i18nn_854a071f3cbf2761:"Cancel face order successfully",
	i18nn_526831ff87464f45:"Labels",
	i18nn_9422c7c846cae2ad:"Offline recharge",
	i18nn_75087cd54a97247c:"Freight query",
	i18nn_79de6d14b424271b:"Import waybill",
	i18nn_7cdf6a7f9798699b:"Add waybill",
	i18nn_85b1942b3831e5fc:"Right container",
	i18nn_6cdece641436d7ab:"state",
	i18nn_2b52b35b48065b9b:"to",
	i18nn_e020fbe62b0c9833:"Channel code",
	i18nn_590686fdff40723b:"Reference number",
	i18nn_daa60c502de1462f:"Logistics order No",
	i18nn_1f4c2f1c8fcb66b7:"Order date",
	i18nn_0a84f4853ab4d1ed:"Waybill fee",
	i18nn_037f5845b7a88b16:"Fee details",
	i18nn_b98aefdd52a12eee:"Package information",
	i18nn_8004917a89ed807b:"heavy",
	i18nn_7b16a04efec1728b:"insurance amount",
	i18nn_b7367a5ccf9facf3:"Sender",
	i18nn_ab3ce045a4241cb0:"addressee",
	i18nn_21fbd798a6ae3e52:"No signature",
	i18nn_4851293ecdd539ed:"Recipient information",
	i18nn_7b8ed340400a6e2b:"name",
	i18nn_549a44bae725f4a2:"surname",
	i18nn_ab653780491c0d4b:"Company name",
	i18nn_8b90c4cfdf339af8:"Two character code",
	i18nn_0bbce74881c893df:"Address type",
	i18nn_a9cbcfc68126f75a:"Business address",
	i18nn_af200a78a2a44559:"Residential address",
	i18nn_6a4dc4a49612041e:"Check address",
	i18nn_479ea0356ec72989:"Sender information",
	i18nn_79dc0cb30753ec26:"Sender name",
	i18nn_594abd886182b662:"Product information",
	i18nn_5841c2904805147a:"Chinese name",
	i18nn_af76f74cc6174a6d:"English name",
	i18nn_ab6ea90b9164b20a:"describe",
	i18nn_903c192091ff3e9a:"Customs code",
	i18nn_19252a10a7c4755e:"Single face size",
	i18nn_7e80cfa637ec486b:"Signature service",
	i18nn_f9a9e686569b8901:"Calculation results",
	i18nn_118a120c399e878f:"Price details",
	i18nn_1ffc6ecc6d0685b6:"When confirming receipt",
	i18nn_107dd8e36bc7c397:"Whether to receive goods on site",
	i18nn_8f3058060ca857e6:"Confirm receipt",
	i18nn_e137950d1897817e:"Unloading on site",
	i18nn_3ff6389dafddca27:"Waybill import",
	i18nn_87912cccd1e9b132:"Packing list printing",
	i18nn_0c8c5123e2631ceb:"Not pass or pass",
	i18nn_3ece0cc00f1da8de:"Received goods",
	i18nn_15e0074dc8713a02:"Must pass",
	i18nn_608b055c301999e8:"Callback after successful import",
	i18nn_ae96feb59a4f25ea:"Callback",
	i18nn_4e5d40d707d90d7d:"Off season charge price",
	i18nn_d5beb7f89f0e6b61:"Off season discount",
	i18nn_b40390257aa6c985:"Charging price in peak season",
	i18nn_30e41d362a584108:"Peak season discount",
	i18nn_fc72e5de884434f5:"The charging method is dead",
	i18nn_820a3a60bc317776:"Failed to format data",
	i18nn_f4bb5eaa25b5de81:"Waybill query",
	i18nn_96b95ef206fadadf:"Open rate query",
	i18nn_b0e16646a7aa97eb:"You have chosen",
	i18nn_b2330ac1b925cbcd:"Please select a single query",
	i18nn_4984348f709a96b5:"Add configuration details",
	i18nn_9779e0a1682e0564:"Delete configuration details",
	i18nn_b9221c0e13214d9c:"Please check the input data",
	i18nn_ea74b89efa9dbdb8:"Query rate",
	i18nn_a72f4a9e1170c63b:"Verified",
	i18nn_012206594fd339ef:"Confirm receipt submission",
	i18nn_d96afd0c0de3686c:"Are you sure to delete",
	i18nn_e766a8b8bb6dbb48:"Cancel the order",
	i18nn_87d3b81a6d6723d8:"Batch review",
	i18nn_1ec1ab466a8bbbdc:"Channel code value",
	i18nn_ab546359d111c7f4:"Import waybill template",
	i18nn_aa7237f43c313974:"reference",
	i18nn_48e17cdf3460fb7f:"minimum",
	i18nn_3775920d44088b96:"number",
	i18nn_7e1de493fb204260:"Formatting exception",
	i18nn_d50e79aeea569249:"Delivery warehouse",
	i18nn_8bf7c69409f84506:"Receiving warehouse",
	i18nn_0f85a73e30bce3c5:"Express channel",
	i18nn_83b1b50eb00a9fb6:"Third order number",
	i18nn_71c07829df8e6708:"View billing rate",
	i18nn_8eb6e92d013f9555:"intercept",
	i18nn_7a832466d6f61c60:"Express type",
	i18nn_13143652e467810d:"Are you sure to pause",
	i18nn_e89c7d698c8a29f3:"Query the corresponding Express billing rate",
	i18nn_f90b8bd6ccd76c9c:"Query interface",
	i18nn_199f5bded0b23976:"Returns",
	i18nn_9e1f4b4b91d2dff7:"Create return and bid exchange",
	i18nn_0ae081c3b8c4d4a1:"Submission date",
	i18nn_dc710cffe6313bb5:"View details",
	i18nn_a5ed1c98a5b6763f:"Waiting for sign in",
	i18nn_c2baf39462aa98d2:"Partial receipt",
	i18nn_4d4f1d7366ae2f12:"Sign for all",
	i18nn_868e09fc83c854d1:"Return and bid exchange details",
	i18nn_53b13e07568ca635:"commodity",
	i18nn_c6e98c83801b5198:"Trade name",
	i18nn_158db9252e21d1a6:"Associated doc No",
	i18nn_e4342b5989a0c5ac:"Warehousing status",
	i18nn_6b1945319eb18d5f:"PickUpStore",
	i18nn_66f0e38b9285894b:"Departure mode",
	i18nn_c2e3d79e5e3050f0:"Create transfer order",
	i18nn_51177b2d7ce0d883:"Transfer order details",
	i18nn_bdc361ba04506136:"Number of boxes",
	i18nn_4b2b4820019ef897:"Quantity of single box",
	i18nn_b19f7906dab47f9c:"Delivery method",
	i18nn_eb8af1b7e16e2069:"Outer box length",
	i18nn_64e6eb209a8a2cff:"Outer box width",
	i18nn_2a02dbcab12c9ee8:"Outer box Height",
	i18nn_55ed30102ca735c1:"Gross weight of container",
	i18nn_b5b5d2437dca1e76:"Warehouse code",
	i18nn_fa6ae0aebfee7efa:"Shipping barcode",
	i18nn_32df9cb3d1681477:"Shipping mark",
	i18nn_5fb84776e29f5b72:"Operation notes",
	i18nn_3374a2514ef7dc5d:"Transfer doc editing",
	i18nn_589bc0f7e4769a3e:"Inventory trend statistics",
	i18nn_dc75cd940064d714:"introduce",
	i18nn_74743cf1a9c41151:"Core module",
	i18nn_28d2fb938d6211b8:"The core module provides",
	i18nn_e630af5f633f55f2:"Use necessary interfaces",
	i18nn_a17b20dd7c448e3c:"Introduction of histogram chart",
	i18nn_074a3b8ff2bb7105:"The suffix of the chart is",
	i18nn_12b2d886127fcf1d:"Introduction prompt box",
	i18nn_72d9fd3ed1651acb:"Rectangular coordinate system",
	i18nn_09aff069f9e1394e:"data set",
	i18nn_2cf965200948068b:"Built in data converter component",
	i18nn_7fc6196a61028e73:"Component suffixes are",
	i18nn_fe22d580da31919f:"Automatic label layout",
	i18nn_c34d935dcb747d98:"Global transition animation and other properties",
	i18nn_bb74af49cc463d6d:"Renderer ",
	i18nn_c815335fb089323d:"Attention introduction",
	i18nn_f7f23935032a7fc4:"perhaps",
	i18nn_fe3bc9f05bdd89a0:"Is a necessary step",
	i18nn_0afba3e48cca9065:"Register required components",
	i18nn_2b17c40739835a66:"Based on prepared",
	i18nn_b9f264085ac2a89b:"example",
	i18nn_3eb5a90218d6c6d1:"Draw a chart",
	i18nn_89524efe3f5bdf69:"Change trend",
	i18nn_2594059036dfede5:"time",
	i18nn_2c5ae1686e1c4aa2:"Trend statistics",
	i18nn_90934a9c6e825ac3:"Trend statistics details",
	i18nn_9c7e841fa06de079:"Delivery report",
	i18nn_63ee7265156e13ac:"Statistical method",
	i18nn_f551589093f4bbfb:"Commodity keyword",
	i18nn_2b2633c44fbf02b8:"Outbound quantity",
	i18nn_716dcf4d38910c35:"Delivery time",
	i18nn_08ad990830d08ed0:"This month",
	i18nn_e1a818330d2d1b44:"Year to date",
	i18nn_9686abed4dde5fcc:"Last six months",
	i18nn_f762ff6f05589bdf:"Receiving",
	i18nn_e36deb57ab8f614f:"Self delivery",
	i18nn_dad7cb726e8a6ccb:"Not necessary",
	i18nn_c232abd55b5719fb:"monthly",
	i18nn_43d7de9d82345073:"By day",
	i18nn_9144ddc68d05d528:"start time",
	i18nn_27851bd33f35f524:"must",
	i18nn_8b5d709462c8e71e:"End time",
	i18nn_4ab929e1484af865:"Commodity code",
	i18nn_a3b6f85b4a9530ff:"Warehouse code",
	i18nn_a2314420eff2c3a6:"Statistical type",
	i18nn_d9415a5983a38e84:"Commodity ambiguity",
	i18nn_6ac98f81bd978520:"Please select a warehouse first",
	i18nn_904c04c9f6cc2ce8:"Details of warehouse rental expenses",
	i18nn_96e86c7f83dd7561:"Charging method",
	i18nn_9a02297fb30f5281:"Last seven days",
	i18nn_9bfcf061ccdd5c1c:"statement date ",
	i18nn_512e5b1abd89d790:"Warehouse rental expense summary",
	i18nn_375acecdfac5c204:"Fee date",
	i18nn_b83011b3bfea8797:"split",
	i18nn_bcad6e616b8de5af:"Third party",
	i18nn_4f26196e75e6eb1a:"Third party order",
	i18nn_b30717f74d1d91b3:"Third party order number",
	i18nn_737061cefdb6d0af:"E-commerce platform No",
	i18nn_b6b08a7daa1de1ab:"Shop name",
	i18nn_d2dd21a61c559695:"Third party platform",
	i18nn_ab29edcb476d7186:"Generate shipment doc",
	i18nn_b7f95c7fddc0d602:"city",
	i18nn_5b954c149f0b1bdf:"Third party platform name",
	i18nn_bb74b80c21dcc1a5:"Push date",
	i18nn_b49ec02534e4f9b9:"No order",
	i18nn_139eee335633c377:"Order placed",
	i18nn_386af249b524809e:"Production invoice",
	i18nn_b12f88738181c217:"Are you sure to generate shipment doc",
	i18nn_167e06b5d98fca7a:"Accepted",
	i18nn_181f014c35e15c8f:"Other work orders",
	i18nn_b56fdc27a5fdd70a:"Required",
	i18nn_31ce64e52ed71628:"Not required",
	i18nn_bf5ca810e576858e:"Selection box",
	i18nn_2e5e43583197a181:"Parent class code",
	i18nn_bf371176126571cf:"Operation code",
	i18nn_5d77e5949de8eef9:"Transfer doc import",
	i18nn_f6283c46ba925ab4:"Delivery warehouse",
	i18nn_ebcd402850731325:"When exporting all",
	i18nn_9b303c33a756ca45:"Not included",
	i18nn_a8e47905b55a7fd5:"One dimensional code picture",
	i18nn_083b4e410bb4174e:"To export one-dimensional code pictures",
	i18nn_8888f6735d4b5717:"Please export the current page",
	i18nn_de548b6f239487e4:"Warehousing",
	i18nn_3155418abb05309e:"Ex warehouse",
	i18nn_52687a58ca0645c3:"New split",
	i18nn_9700e172aec17e89:"Split number",
	i18nn_ce6f905c8859cf74:"remove split",
	i18nn_3a6fdc59b1cd84c1:"To be split",
	i18nn_6726a8914c9083a3:"Split",
	i18nn_382a3d27fb8dc70a:"Estimated number of splits",
	i18nn_0ae3aed4e3b5423d:"Processing",
	i18nn_3fe2f60f8a8b9214:"In storage",
	i18nn_0b8eb588850e50f4:"Are you sure to cancel splitting",
	i18nn_ab894a37bf61ef3b:"Are you sure to submit",
	i18nn_786593149c0bf9f8:"newly build",
	i18nn_0863222bb0aafdce:"To separate",
	i18nn_a43cedadf897a6eb:"After separation",
	i18nn_7f1242a9fcde8c48:"Replace characters that affect links",
	i18nn_82cdc82bd48e14e0:"American Standard Time",
	i18nn_2bb60de6c1743a1f:"Us daylight time",
	i18nn_726c51702f70c486:"Creation time",
	i18nn_6c837fa18c154f3e:"Shipping mark Download",
	i18nn_c67a0216d186c836:"Shipping mark callback succeeded",
	i18nn_cdfe6c485e90d1f5:"Print shipping mark",
	i18nn_45855d1e066e1764:"rotate",
	i18nn_9c17e9aea46e0c8a:"Step 2",
	i18nn_71be22ba63b84f22:"Enter rotation angle",
	i18nn_267cd7e59f3b0549:"One per line",
	i18nn_86c917b47c5fd789:"And in accordance with",
	i18nn_72e7588c2285cfd8:"order",
	i18nn_bd2ea60194a4a9dc:"input",
	i18nn_84858479e2b2e839:"Can be copied and pasted",
	i18nn_400156a53446e154:"Column data",
	i18nn_818f8e46c3baa256:"Please enter the document number",
	i18nn_cb48ac138393dec7:"One order number per line",
	i18nn_ec9fcd75e3d2b843:"And press",
	i18nn_fe072dfa8e77ac15:"Sequence matching",
	i18nn_c84e312946608267:"Total split",
	i18nn_e7318027d521dd7c:"Step 3",
	i18nn_423aaa7563e762bb:"Preview and download",
	i18nn_34051a0fa4c8b001:"And file name",
	i18nn_d71aefb39490bc0d:"Start Preview ",
	i18nn_d11719409666a169:"Display test",
	i18nn_dff5b3da08659f44:"Step 4",
	i18nn_bb77a02f2157ee4b:"Download and compress",
	i18nn_d5a306be96327a1c:"Compress and download",
	i18nn_1ca7506b642073ca:"Enter doc No",
	i18nn_e61eff8fdd759f9d:"List of order No",
	i18nn_4ba633ab624e491d:"Compression test",
	i18nn_afa4dddf5a6214d4:"Default other",
	i18nn_9a8a031ac6ac8473:"Direct printing",
	i18nn_05d718c95a72fa48:"Please upgrade or download first",
	i18nn_086a73016c53d692:"abnormal",
	i18nn_ddc9cdfdcae67820:"Set last printer value",
	i18nn_89df29c83fd68a9b:"Parse into",
	i18nn_797bcc6c4af7fa88:"Parsed data",
	i18nn_8ca9d59119d46247:"Upload failed",
	i18nn_4c6f792c953f85ae:"Get rotated",
	i18nn_c33c5939b8538193:"Rotation successful",
	i18nn_49ab0225e5564b8d:"Processing failed",
	i18nn_9be91633f0d169ea:"Initialize printer list",
	i18nn_105937855b43aef9:"Preparing",
	i18nn_f9070b0f5b16a182:"Exhibition",
	i18nn_07334594acbc2790:"canvas",
	i18nn_b28d38de28413af9:"No split files",
	i18nn_9c349f7ff56e5ca7:"File zip",
	i18nn_6db9264f0dd414cb:"No document",
	i18nn_4c370f83c891918a:"Save values for this printer",
	i18nn_52f18910f3b96487:"File split",
	i18nn_25687702e835ea72:"Start split",
	i18nn_ebb6eb97b8a2260a:"Preview loading is expected to be required in total",
	i18nn_7f7865ded377b432:"second",
	i18nn_b6bdc839bdc262f3:"Per file",
	i18nn_57998aee70aea45f:"Seconds or so",
	i18nn_c09ef1afcae8a14b:"Get split",
	i18nn_352e53736a4c4cda:"Split successfully",
	i18nn_3d6d2d670fa63b7c:"channel",
	i18nn_ab5902ee5c645e01:"Not available",
	i18nn_a9e1d1c2c5eb6525:"Refresh",
	i18nn_8dfbe229da70319b:"Specify specific channels",
	i18nn_23d809d3bce6b99a:"Failed to query information",
	i18nn_7a22cefca1bca21d:"single",
	i18nn_fa4075a55f397b88:"List information",
	i18nn_aaa9fd271d84c6e1:"Message failed",
	i18nn_8be4f7e9a9fa89e2:"List information interface",
	i18nn_f444ba8c0fa47753:"Download shipping mark",
	i18nn_3b513604a77443db:"Shipping mark printing",
	i18nn_4045c7be6edea082:"Customer fuzzy search",
	i18nn_8f574078dfe6e56c:"request",
	i18nn_398644c781d3f039:"Name search",
	i18nn_21b22a8018f3cba2:"Batch download of face orders",
	i18nn_1cbfbf4afdd75282:"Batch download attachments",
	i18nn_2de6c4eb2e40cf1f:"Please use Google Chrome",
	i18nn_c26d5c523b0b69d3:"No face-to-face list to download",
	i18nn_378153e9411327ea:"Single face",
	i18nn_b7819b9d931008b0:"Compressing",
	i18nn_45b0c5bc56871f57:"Query rate results",
	i18nn_ff89821d70424147:"Select this channel and save",
	i18nn_6f7b6a7194049eb4:"Select and save the queried rate",
	i18nn_89ca09994c8c4d74:"Save specified channel",
	i18nn_34b9e521d99ba4c5:"Currently selected",
	i18nn_33736cef5230e85b:"Is unavailable",
	i18nn_0c3e84f9f6aa0e52:"Reviewed",
	i18nn_076b387e20700c4c:"Only draft",
	i18nn_31b95602f46fe26e:"Only in approval status can a document be submitted for printing",
	i18nn_b758e3984e5d5a86:"change",
	i18nn_21e42bbe9894aafc:"Receipt and warehousing",
	i18nn_194d733acdedb737:"One piece consignment operation",
	i18nn_9695030363addb97:"One piece delivery",
	i18nn_5151fd7875058ddf:"Warehouse rent",
	i18nn_636d7192b222277c:"a surcharge",
	i18nn_45eae388d108d4fe:"Operation record status",
	i18nn_82b5bd967d494be3:"Operation type",
	i18nn_ceffcc237c2608b9:"total",
	i18nn_c2c4e3a31c61dc7f:"Number of records",
	i18nn_da35456de5403862:"Total amount",
	i18nn_ed6ece9e43eef321:"type",
	i18nn_656e30fd838de0c2:"Additional operations",
	i18nn_b0a8a31be81514ba:"charge",
	i18nn_16658a855f07d171:"amount of money",
	i18nn_b45b4338ee939ad0:"Details Popup",
	i18nn_6afbe4c6dcf87a6c:"Expense details",
	i18nn_851e87b9fe311af7:"Open selection box",
	i18nn_7f995eb43e1fc92b:"By volume",
	i18nn_02ad74c45e9271a0:"By weight",
	i18nn_9cee201b82dbc9cb:"Not started",
	i18nn_1ff1fd8ddb3e0e8d:"Picking",
	i18nn_a1f096b4fd6848be:"Total required parameters",
	i18nn_823f9bd6c61d2733:"Default volume",
	i18nn_9c4659d873c8b417:"Please select a date first",
	i18nn_cc1eef3b5ac02fff:"Resolve table misalignment",
	i18nn_199bdff7643b9385:"Request total data",
	i18nn_bc118f4faee1209f:"Total query failed",
	i18nn_8bf94e1ab6927bd0:"Open the details pop-up window",
	i18nn_9c0ace2b5d32f74c:"Error in request data",
	i18nn_5599155c3578c611:"Discard",
	i18nn_3acca92babc7df1c:"Are you sure to discard it",
	i18nn_f66fc986e2ae222f:"Abandoned successfully",
	i18nn_33e215cab320c32f:"View expense details",
	i18nn_ab7c1e41cdae24c5:"Recalculate",
	i18nn_9168144190f66f5d:"Association number",
	i18nn_6e9c4e33131b18a7:"Number of applications",
	i18nn_e3de3b77067f6a24:"singular",
	i18nn_5ffda681afa71861:"singleton ",
	i18nn_621091be3bb350a9:"Unit price volume",
	i18nn_14b9b51812811e15:"Total cost",
	i18nn_1595bdab4d7bf7c2:"Current day delivery",
	i18nn_396cb19960e15651:"Total unit quantity",
	i18nn_16d3ec8d8ecbac75:"Timed out",
	i18nn_7d814d9a1e592965:"Not processed",
	i18nn_4f5a6083d34bdccd:"Shared files",
	i18nn_591c1b2fa3e699cc:"Warehousing statistics",
	i18nn_af1a2cc986597e90:"Apply for stock in",
	i18nn_15aeae6cfcd5725a:"Quantity received",
	i18nn_a967bc023b5157c4:"Order statistics of this month",
	i18nn_5dfd274e7c6c5bc9:"Ex warehouse condition",
	i18nn_52fc37883d947301:"Delivery status data",
	i18nn_2f47c3d499a6e740:"Order statistics",
	i18nn_b3851508825cae52:"Warehousing application",
	i18nn_ee110e3c7304b62d:"Other services",
	i18nn_72917c44e787864a:"offer",
	i18nn_55806c9c59b87e47:"inquiry",
	i18nn_3b4e0cc90e264c6f:"take delivery of goods",
	i18nn_b5f81f005ec90ee8:"Quotation of overseas warehouse",
	i18nn_d8f295864833fc57:"Inquiry for truck end journey",
	i18nn_19918f72bcdca413:"Empty cabinet reservation",
	i18nn_c0eb5cb77d8024b6:"file",
	i18nn_fdb7b865dfa87747:"Resource pool",
	i18nn_d115f29ef6edb41f:"Sales Department",
	i18nn_7f62b4bd2d0342f6:"Truck department",
	i18nn_d4fa038149a29ca5:"Port container handling department",
	i18nn_c4be5125be4a0bb5:"Ministry of Personnel",
	i18nn_4bce523e57e94567:"Operation center",
	i18nn_b5e2fe92fe54ace2:"Headquarters telephone",
	i18nn_ab0778ba44a8f3c1:"Truck department telephone",
	i18nn_8861e53cbc04161f:"Overseas warehouse",
	i18nn_4c6841ce9043c9fe:"Trading warehouse",
	i18nn_e0a806b3165119e6:"Wechat official account",
	i18nn_0f63cc67b9017a81:"Scan wechat and pay attention",
	i18nn_c12ab070fe3cbeac:"Wechat scan add",
	i18nn_f600a6d1dbcfce47:"wechat number",
	i18nn_b33f004fe453ef3b:"Customer service mobile phone",
	i18nn_a4365f7edaab7453:"System optimization feedback",
	i18nn_7079c6a7861b3dca:"local",
	i18nn_74a5def3116ecd89:"division",
	i18nn_adea92839a4c22b0:"Create label",
	i18nn_a9eaee9d97fb6c43:"Paused to resume",
	i18nn_1a42987a0df962e9:"recovery",
	i18nn_80175af49375b10a:"Resume pause",
	i18nn_df7bb549078d49cd:"Recover",
	i18nn_5965027c91e708d0:"Submit entered waybill",
	i18nn_baf81ceae5191d42:"Count status quantity",
	i18nn_d4a2f8ababb9fa73:"Status statistics",
	i18nn_31519f8b1453a53e:"Issue type",
	i18nn_4429a015eeb36653:"Number of pallets",
	i18nn_ee57468801a2964d:"rescinded",
	i18nn_2f9954db5ba06447:"Picking type",
	i18nn_7b1927e9f5c2b1a1:"Picking time",
	i18nn_6fba545981478392:"Pictures of consignment completion and delivery completion",
	i18nn_6c1a30d60ca12a5f:"Finished picture",
	i18nn_8139c1fa7c3b121f:"Picture of finished delivery",
	i18nn_82d8e302e3c71782:"Picking completion picture",
	i18nn_87a1b283d9bd94b8:"Outbound completion picture",
	i18nn_6dfc48f51729ae2d:"Finished picture",
	i18nn_7bc8a7fd7ad68c07:"Monthly",
	i18nn_88ef88455ebf24a9:"One issued on behalf",
	i18nn_bebf7be502526462:"Here according to",
	i18nn_211115f8977940c3:"Fill in your jump logic",
	i18nn_4575044e90eb79cd:"Monthly statistics",
	i18nn_4a6ad688b6b0de73:"Display the current month data of the last month of the selected data",
	i18nn_6c29963675196a7f:"From to the last day of the month",
	i18nn_64d8e8d588effa0b:"Value clicked",
	i18nn_4c7aa2bda11fa67f:"Filter incoming",
	i18nn_1f9d21cb16e0c002:"Last month value",
	i18nn_52b4f481aab3e343:"Order statistics by month",
	i18nn_6e5d728ebf9703a8:"Order statistics by day",
	i18nn_7a702b4adddd528a:"Click statistics by month",
	i18nn_c44b77fdd0a0914d:"Query warehouse",
	i18nn_a0caf27419f2effd:"Failed to query warehouse",
	i18nn_866ffc98aa735561:"Service quotation",
	i18nn_1318271bf151d3b1:"Contact information of overseas warehouse",
	i18nn_eaf9e22fc96815be:"Customer service wechat",
	i18nn_76b639640b949a53:"Financial statements",
	i18nn_26f639f47de564bb:"Overflow hide",
	i18nn_5cb59a5613ecff7b:"nowrap ",
	i18nn_30eddd41470d7893:"Open view",
	i18nn_373dcbacd938fcf3:"When editing",
	i18nn_d6cecadea664b088:"The displayed data is for reference only",
	i18nn_58830b2f3e6b548e:"It is recommended to upload the template again",
	i18nn_08b0ac0e59879bb8:"On Edit save",
	i18nn_93425399dd0a7b54:"Recommended as per",
	i18nn_33325b78e48f38bb:"category",
	i18nn_98de889dff3208a6:"Specifications",
	i18nn_23ad9574b8ec9299:"style",
	i18nn_6e13ec00e5b30fcf:"Alphanumeric code composition",
	i18nn_e729e61f9c82621b:"And remain unique",
	i18nn_ea2e8aca44cc7471:"Keep unique",
	"c944a6686d996ab3":"warehouse",
	"2ad108ab2c560530":"please select",
	"hytxs0000001":"please input",
	"5acbec83efb27445":"warehouse",
	"7431e76029678ec7":"title",
	"hytxs0000060":"customer name",
	"209fdd8f47e20fbf":"customer no",
	"184333c81babf2f1":"type",
	"i18nn_90b7f7d6846dfdce":"export",
	"46c3f6e0f657e7a3":"fee type",
	"d7315fb8114eb446":"fee",
	"3775920d44088b96":"number",
	"96e86c7f83dd7561":"Charge way",
	"9bfcf061ccdd5c1c":"billing date",
	"hytxs0000035":"place",
	"539728f5dd9cb065":"title",
	"cc62f4bf31d661e3":"Tips",
	"i18nn_4e9fc68608c60999":"Close",
	"4e9fc68608c60999":"Close",
	"e33c9b93c36fd250":"delete",
	"5a9aefbc03c778f7":"please input",
	"44bd089554515c4d":"loading...",
	i18nn_a332a3ceebd61228:"When switching labels",
	i18nn_b31f6c49613389c2:"When the window changes",
	i18nn_7f4c86f986e10cea:"When data changes",
	i18nn_b0f975d8c101a08f:"Table redrawing",
	i18nn_acc7a553954902d7:"House appears",
	i18nn_b1b1b4f623b3e2f8:"It's dislocation",
	i18nn_c83ab8aa8360a6b4:"Registered in the acquisition page",
	i18nn_795e96d372b21378:"All subcomponents of",
	i18nn_a3502344f06f5b38:"according to",
	i18nn_51d903b15bb15ebc:"Method to determine whether the subcomponent is",
	i18nn_762cf41ecbb3ee58:"implement",
	i18nn_da8b893ddf2d9176:"method",
	i18nn_9927eadcc160b75c:"Authorization Page",
	i18nn_4b1ce14cae162e13:"Verify and get callback",
	i18nn_2ed49c526ba70768:"Separate authorization",
	i18nn_44e6c18367861a4d:"verification",
	i18nn_0f5c0ab6a4936ed4:"Callback acquisition",
	i18nn_59c724de61d75333:"Authorization callback page",
	i18nn_b9f099bf21b81e70:"Close this page",
	i18nn_0b32aa344b9d3cd8:"Callback page",
	i18nn_5791de4cb2735c47:"transmit",
	i18nn_7c6c9b6dfa3e651f:"You can close this page",
	i18nn_9eca3546a05b0150:"If the file to be uploaded",
	i18nn_9a39ba5b35433d6e:"No files to upload",
	i18nn_f11e64895a45d731:"Callback empty array",
	i18nn_e3460ccf4f5eba14:"Take the last time as the final file to be uploaded",
	i18nn_5e74b9788b2ceab8:"Clear files to be uploaded",
	i18nn_aa3b760147bd92e6:"subscript",
	i18nn_a0ba0a35323754ae:"Superscript",
	i18nn_eea71e4304e82893:"Clear display notification status",
	i18nn_b55874f01c0bc221:"Account balance",
	i18nn_51fd0a603ed58073:"Recharge",
	i18nn_a9c4ab3208bd8aab:"View recharge record",
	i18nn_61bd9a8fcc2ea788:"reminder",
	i18nn_f7547f82aa22dc24:"Prepaid account is currently offline recharge",
	i18nn_36098b1b6b9edf80:"Please keep the transfer record after transfer",
	i18nn_7d3286d3ba74a4b9:"Online application",
	i18nn_2b4508307cb41f8f:"Our company will handle the application as soon as possible after receiving it",
	i18nn_6ab4144b540a8a6a:"Change type",
	i18nn_b4e5993f97fe04ee:"revoke",
	i18nn_35ec768075eb8f4e:"Receipt No",
	i18nn_9be246e60da2a0a2:"Arrival date",
	i18nn_6f153d59e22a769f:"View receipt list",
	i18nn_10bc8a2b45aa5636:"Service feedback",
	i18nn_a7e730af52ab52b1:"Receipt list",
	i18nn_2f19f86ff8be446f:"After sales",
	i18nn_4c673b62fe72622a:"No warehousing",
	i18nn_75dd38674c2bbac2:"Only status data can be operated",
	i18nn_3dc377fecc5acdb1:"Receiving list",
	i18nn_3e8b33480ff7e0f7:"Outer box information",
	i18nn_913e044950ff558b:"Outer box volume",
	i18nn_228e58fddadbff82:"Upload attachments",
	i18nn_a2b42f1c9bf76168:"Current angle",
	i18nn_0abac39c74830494:"reset",
	i18nn_a9e9d8651e405af1:"Reset Reset",
	i18nn_dc197c8c018305f5:"Automatic addition",
	i18nn_f1c66f3de00226c1:"degree",
	i18nn_6b42f3e7ab6e4c7e:"More Filters",
	i18nn_c4ca802cf2230b0b:"screen",
	i18nn_559fed47b0e95407:"Transshipment order No",
	i18nn_a1399a08e08ff6b9:"Open more filters",
	i18nn_622d18905c5266b4:"Query more filters",
	i18nn_ecc3d56be2ae0baf:"Other parameters",
	i18nn_8ac049aa3fac8e9f:"Insurance",
	i18nn_ade48dee835e25c0:"autograph",
	i18nn_a8980879d3a4c82a:"Please enter and select",
	i18nn_e90caa8826c11146:"Research Default Data",
	i18nn_9ab1e759ed215b97:"Customer fuzzy request",
	i18nn_0fdb98c494be76c7:"Remove space",
	i18nn_e12ec0fbad09bac1:"Wrong keyword search data",
	i18nn_61d304ff72c5c155:"Keyword Search Data",
	i18nn_0c901139f9896f14:"Deleted",
	i18nn_a87302a8cd63eae8:"Template updated",
	i18nn_26ad8f1f07292f65:"Before exporting each bill",
	i18nn_21bae2bd339406af:"The more data, the slower",
	i18nn_5734a059a4622f20:"front",
	i18nn_5d6f695ebedef0a4:"Export all pages in batches",
	i18nn_f71701ed55a2e712:"Each batch",
	i18nn_8d8c79fcfaa246f5:"Before export",
	i18nn_018de66d9d708dc7:"Including total",
	i18nn_11c05b307e012228:"Default List",
	i18nn_68ceeb72fa5cbecf:"Total bill",
	i18nn_ac64d1d6f380ef6a:"The second tab displays the total data",
	i18nn_8921d880576fbe1e:"Filter Condition Consolidation",
	i18nn_0468e55d7cd0bf0e:"Filtering items of warehouse rent",
	i18nn_f4a17fe75237eecc:"Load all bill data",
	i18nn_d33d0afe39b5b1a2:"Warehouse rent summary by day",
	i18nn_65b02ebff64ee1a6:"Warehouse rent details",
	i18nn_b4c3f9fe658d58d2:"Data is loading",
	i18nn_d55802bcbb5f2661:"Simulate large amount of data",
	i18nn_36ed3657e60217a8:"Data prepared",
	i18nn_7be1fb8d3e601725:"statistical data",
	i18nn_d8644e8d60c8bd8a:"Unit Price",
	i18nn_de7090439acfce3f:"Trolley",
	i18nn_c65deeaa92c96268:"Total amount",
	i18nn_c1095f42b8f33816:"Summarize data",
	i18nn_37f3ec4a54bf2dbc:"Summary data prepared",
	i18nn_4d7265155f2745f2:"Incorrect summary data",
	i18nn_d60bf020cc97d43d:"Total number displayed",
	i18nn_e8ad5e493b3a3030:"Calculation pagination",
	i18nn_cbd2e4eb12b71c1e:"Do not calculate the first page",
	i18nn_bf090996761c9056:"Calculate how many pages there are",
	i18nn_283071229a9a6a70:"Start and end of calculation page",
	i18nn_c1bdca8d2544fee9:"Attachment uploaded",
	i18nn_775723b177f5e711:"No uploaded files",
	i18nn_a0658db22ed941f6:"Direct Submission",
	i18nn_b13659103f6bd76a:"Trigger callback",
	i18nn_52dbbd0472a2f8a8:"When resubmitting",
	i18nn_b9618676820a7be7:"Already uploaded files",
	i18nn_671663add1f18f05:"If the order has been generated for offline express delivery",
	i18nn_8e258808e2a13395:"Please upload the corresponding form here",
	i18nn_b6f0167ad2fd3cee:"The order number and file name must be the same before matching",
	i18nn_8f2e8ec212959586:"good",
	i18nn_7c3fc1953013745c:"Logistics track query",
	i18nn_2279b6465da730e9:"logistics company",
	i18nn_faf9f786f1c93a10:"E-warehouse pull query",
	i18nn_316e16926411681a:"Channel provider",
	i18nn_76e82afd9c2b6ca1:"Submission time",
	i18nn_e4f53ced9388c487:"Shipment Track Query",
	i18nn_7fa1fea309b0fd8d:"Cancel in progress",
	i18nn_5be51b56e8802896:"Shipment Order Track Query",
	i18nn_c136c2ffbc49c6e7:"Unknown express channel and order number",
	i18nn_2bfaf322a81c4e8c:"Select Address",
	i18nn_f1e789e4700e15e3:"Select sender address",
	i18nn_554342775fea1a93:"Fields to be transferred",
	i18nn_d81f561e7fe6dacc:"Shipping address cannot be edited",
	i18nn_f13b5a3276612c1e:"Shipping Address ",
	i18nn_97ec3bb319c68844:"Interface",
	i18nn_4d04102b6435ddef:"Upload data callback",
	i18nn_8b9618de2e85645e:"Express No",
	i18nn_380912455c6e2f06:"Multiple queries",
	i18nn_0deaa0a62ac136b6:"Service fee of sales partner",
	i18nn_d88844033912c1fc:"Error message",
	i18nn_2678054e53eeefdd:"Error code",
	i18nn_094903a0e1dd5849:"Logistics track",
	i18nn_f2896032570c4d41:"Address information",
	i18nn_eca5c29ad1a845c1:"Destination address",
	i18nn_93ba6023a4128d75:"Shipper information",
	i18nn_e29ea9e7cb029d6c:"Service information",
	i18nn_ca9b892a79dfa7ee:"Service command information",
	i18nn_50a95a5f20c112cf:"Package identification",
	i18nn_38482e62a277f304:"Latest status details",
	i18nn_5360545085fcff1f:"Other details",
	i18nn_86ba00a2be935c6d:"judge",
	i18nn_e7959874088344ea:"Is there a value for monitoring route changes",
	i18nn_e83442620acf37fb:"Missing query parameter",
	i18nn_d9145c2cbe66f3dd:"Please actively switch to bill details query",
	i18nn_a3a09445e8b04a4f:"Authorization test",
	i18nn_e9970bf947cf72e9:"Please enter the express number",
	i18nn_06ef5de26edd9e88:"Delivery information",
	i18nn_d721bca263c00924:"Date Node",
	i18nn_5c98d407627f383d:"Bill Summary",
	i18nn_5203265398bc2ae1:"Bill Details",
	i18nn_0d35fa57b3aab0f7:"Summary by customer",
	i18nn_e19ca5d2e0c83887:"View Summary Details",
	i18nn_ac53fd8c761abf7e:"Summary Details",
	i18nn_2f281726defd00db:"View bill details",
	i18nn_cf84be5a2f8c584b:"Summary by warehouse",
	i18nn_588fde1c3de565ab:"Statistical chart",
	i18nn_e8c72262204a35e8:"Summary statistics of all expenses",
	i18nn_2c98a37551a345ca:"Failed to query all summary data",
	i18nn_edecf12fe0855801:"Summary statistics by warehouse expense",
	i18nn_1b210b98ae740911:"Failed to query the summary data by warehouse",
	i18nn_bf3e50d53670c583:"Summary statistics by customer expense",
	i18nn_0c72f3afd482899d:"Failed to query summary data by customer",
	i18nn_a3e55db0637a9a7b:"Jump to bill",
	i18nn_18b633e513af5fbe:"Refresh Customer Summary",
	i18nn_8d8924dbda6971a9:"Search summary data by customer",
	i18nn_9468bb0475de16b2:"Summary Statistics of Customer Expenses by Warehouse",
	i18nn_44c439345b1a2d97:"Failed to query customer summary data by warehouse",
	i18nn_642e98272f60f411:"Summary and statistics of warehouse expenses by customer",
	i18nn_cf698047bdf5efae:"Failed to query summary data by customer's warehouse",
	i18nn_66e4a92601ee37e3:"Total data",
	i18nn_a528df5f63d891d5:"Summarize data by warehouse",
	i18nn_59a0a5ddc2dd4a09:"Only when the data length is consistent",
	i18nn_07d4648dfc7810d5:"Warehouse Bill",
	i18nn_e730a6add110453e:"Start querying render subcomponent data",
	i18nn_c2f3531c65acc369:"Export of consolidated calculation",
	i18nn_2cac9b8ca9e73c23:"Column names and",
	i18nn_677c64de2a9f928d:"Format calculated data",
	i18nn_723f3d8f11c640b1:"Maximum load bearing of pallet",
	i18nn_06b5d5b29cf6518c:"cube",
	i18nn_949033ac59c59100:"Calculation method",
	i18nn_c726626501fc8bc1:"Export Details",
	i18nn_748bca3d68c277f5:"billing cycle ",
	i18nn_3258bbe488af3e79:"day",
	i18nn_7d6f758a93cb33b5:"Notice",
	i18nn_a923152a83c4a077:"Notice on Logistics Claim",
	i18nn_028ab03ad6e22c6b:"All final logistics related claims must be made after shipment",
	i18nn_e8c6923ccbdd2223:"Submit to within days",
	i18nn_b5be565e0f3f0447:"Claim forms and procedures can be submitted to",
	i18nn_20050bcc9ae951ce:"Claim",
	i18nn_1c13ad39cb6e4d37:"The final logistics that has not been applied for over time will not be able to",
	i18nn_0bd3479de0572734:"Claim",
	i18nn_56ba0012c41d3d6f:"notice",
	i18nn_8adb10f3af5ebc2f:"suspension",
	i18nn_caf2eff6e258a06d:"Service record",
	i18nn_74e7a7ab1181812c:"Problem feedback",
	i18nn_4dc6f04018e0f73b:"Problem Type",
	i18nn_9666c59aedbfa70e:"Module Type",
	i18nn_3af42243b3f54e9b:"Created by",
	i18nn_f4c5829c71fcd8bf:"Number of progress nodes",
	i18nn_627df7b14df32cdb:"applicant",
	i18nn_e1911e9360047dcb:"to update",
	i18nn_10a6a2a6124bbb9a:"View Progress",
	i18nn_f80dedf7c601059f:"Value added services",
	i18nn_2b65059530ec3ed5:"Accepting",
	i18nn_1fb4422ebabb847a:"Normal end",
	i18nn_0eb9b2e0d01ad12b:"Abnormal end",
	i18nn_dd3266ec627e31e4:"Open Add",
	i18nn_02f57850a3029222:"Switch this page",
	i18nn_57d71ea1aa426464:"View, etc",
	i18nn_8f8b0799ae9827e0:"Convenient for customer service connection",
	i18nn_f085596674018f3e:"relation",
	i18nn_c62590f388b2ce71:"Service Description",
	i18nn_466677324278a9a1:"summary",
	i18nn_805c8f776e461e39:"Detailed description",
	i18nn_ad36057ff6a7ce6b:"Add Attachment",
	i18nn_74f0f436fa58dfe4:"Detailed Description Rich Text",
	i18nn_3d23fd1011cdc075:"Details",
	i18nn_9aeffaaba1370ae7:"Whether to enter from the module",
	i18nn_7d44b93791468bc3:"Calculate the amount of expense details",
	i18nn_75f524bffcbcff15:"Delete Attachment",
	i18nn_2ba96cbfad324580:"Pop up update",
	i18nn_5184f7a4033b4969:"Please select a customer",
	i18nn_1118edcaeb4c3679:"Please select a warehouse",
	i18nn_dad95a93a594366e:"cancellation",
	i18nn_994cb374e601fab3:"Edit succeeded",
	i18nn_77c109efc99c0028:"Edit detailed data",
	i18nn_2fc1c1e30d69c8c7:"Service progress",
	i18nn_3c7beea052b8230c:"New Progress",
	i18nn_354482714c59064a:"More",
	i18nn_722871fda2c6fb4e:"Open Message",
	i18nn_aae671b49dde2bba:"Basic information",
	i18nn_0f6916ca53218662:"Progress of new services",
	i18nn_b95047968d1358d4:"Progress status",
	i18nn_450e8e72d3a52ce2:"Details Rich Text",
	i18nn_d6ab1c29b36f35ae:"Please select submission status",
	i18nn_786280a019b8a1b7:"Detailed description of service",
	i18nn_efbfa769d113b9fb:"Please enter message information",
	i18nn_52a8c38184d84581:"System order No",
	i18nn_32b164c53fa35e6d:"Module Name",
	i18nn_b3e62dc191a3289e:"Return value-added service",
	i18nn_f1d6776242105f03:"Value added type",
	i18nn_6373cb1d1204d580:"Self lifting",
	i18nn_81f6eaf23b9c56eb:"express",
	i18nn_c39fd3d48389bea7:"Inbound forecast",
	i18nn_9dc7842043356291:"transfer",
	i18nn_f2fbaba1a43b29f6:"obsolete ",
	i18nn_1ba6fbd520bc16df:"Value added service record",
	i18nn_3f2ee05e57ec8c0b:"Generate label in batch",
	i18nn_8dc3003658b13cca:"Document processing",
	i18nn_b4296642bcc9f428:"Return warehouse",
	i18nn_e63fc078a7baf7c9:"Express information",
	i18nn_e83443dd6c718cf9:"Logistics track status",
	i18nn_41d020489ecacbf0:"Logistics update date",
	i18nn_3601b5a98803f4d7:"Order issuing method",
	i18nn_198d8fd115bfc3d9:"Self supply sheet",
	i18nn_15bc1e82df3428d8:"System issuing",
	i18nn_2582c3a44f7596c2:"Customer service records",
	i18nn_68ab250470bd3e4f:"Select Batch Print",
	i18nn_b74df69000311063:"Batch printing",
	i18nn_fdbfdd6b62e582b1:"The service level supported by batch printing is",
	i18nn_157f5b25af1adf94:"If the printing fails",
	i18nn_89d5f61bf8d059a1:"You can print a single document",
	i18nn_52def4f590ebd639:"Select the applicable service level",
	i18nn_7d9664098f76ed4f:"Details",
	i18nn_df8b4d2c8fd5548e:"Go to details",
	i18nn_1ca0e2fc087a178a:"Unknown express company and order number",
	i18nn_5715d8eb76147f98:"handle",
	i18nn_a544f42463e5840e:"Are you sure to intercept",
	i18nn_ec6e072b75b892b6:"Open pop-up selection",
	i18nn_44242c07a1f17e31:"Only draft status",
	i18nn_8f3b691fb1928c4a:"Only approved documents can be submitted for printing",
	i18nn_9c63dfa3ec2d8024:"Batch submission and printing",
	i18nn_7e94b4495b945416:"Different printing channels",
	i18nn_9404be77588b0259:"Data",
	i18nn_04c1c1452cb3359f:"Confirm to submit this",
	i18nn_422d8aa4b5756fa4:"Confirm to submit for printing",
	i18nn_48e4efd421dad5b7:"Return order No",
	i18nn_3e764f7ae7f5e850:"Return logistics document No",
	i18nn_ef712dc1dd7c6dac:"Sign in photo",
	i18nn_dc628291530d00b9:"Sign in attachment",
	i18nn_8291ced1c9213734:"Compatible with old",
	i18nn_3874de45d4018f3d:"View signed in attachments",
	i18nn_b1467e06606a8471:"Actual signed in quantity",
	i18nn_18c1c1a3ea76e9d3:"Number of pallets",
	i18nn_f88b93f4fd02974c:"Pallet number",
	i18nn_82dd2957184fa298:"Ex warehouse attachment",
	i18nn_b3cc9e7df0c87798:"Entrusted accessories",
	i18nn_b8906d9824b52a7b:"Message Board",
	i18nn_55a13021a8604d26:"Leaving a message.",
	i18nn_94d68bad9f60445f:"Underpinning",
	i18nn_2bea3f02bd4a8c8c:"Attachment preview",
	i18nn_67572c1c4fdfeab2:"View issue attachment",
	i18nn_0c0af12b8fb80f26:"Check the attachment",
	i18nn_ac3f40a45db7c05a:"Number of tags",
	i18nn_f7e99b8c203e2b07:"Order Details",
	i18nn_92f2ebbe14794f38:"Details of a consignment",
	i18nn_148c3451c7861e8f:"batch",
	i18nn_71a647a484c3f5c2:"Shipping List",
	i18nn_fc7e87b5dfa6e4b8:"Issuing quantity",
	i18nn_d5d972e6063a3180:"Length calculation",
	i18nn_dfd180c9fc784ac6:"Recipient Address",
	i18nn_15da46e90a2c3e3b:"Handling of abnormal parts",
	i18nn_44280840307aa95c:"From B/L",
	i18nn_5d4c76c6431c06ef:"Annually",
	i18nn_dae8d92a749abc4e:"Change Password",
	i18nn_cd85c52ecc16ffe2:"Transshipment management",
	i18nn_1f1bd51a9a045025:"Transfer warehousing",
	i18nn_65fe75ea422bdc50:"Transshipment ex warehouse",
	i18nn_0950e42c6423c2a7:"Transhipment stock",
	i18nn_90faee419c9a32ae:"Change warehouse",
	i18nn_a861c2514faa683b:"to configure",
	i18nn_020c236df168c388:"After sales management",
	i18nn_90db8de2b4a16c9d:"Order pull from e-warehouse platform",
	i18nn_3f9e9a6fe6f4f971:"Ex warehouse order status",
	i18nn_cd18d57f3bd7779f:"Enter one per line",
	i18nn_68a6337017ca7d67:"Sales order No",
	i18nn_a456e74e3c8a95f2:"Pull",
	i18nn_79205a47cd5d529c:"Pull the latest order",
	i18nn_e99e48ab45c5e800:"Update shipping warehouse",
	i18nn_5138d5a49a5cc5df:"Refresh Shipment Warehouse",
	i18nn_e9d55d41fee9a273:"Cancel Shipment Doc",
	i18nn_09124c6c5acf6c8a:"E-warehouse platform",
	i18nn_bdf9dc871938ad08:"Whether to synchronize",
	i18nn_2d78a37c0efc2723:"Pull of warehouse change order",
	i18nn_43e1cb7bb0962388:"Delete Order",
	i18nn_adfbc9ddacfd7f42:"Are you sure to cancel the shipment order",
	i18nn_50f004ee0fb07dbb:"Please select a proxy customer first",
	i18nn_a6abf1dd9e065e27:"New Configuration",
	i18nn_b306361a5aeda4e8:"Account remarks",
	i18nn_0e3b6b0b53dd94e8:"E-warehouse user name",
	i18nn_8af36bb95bcac474:"E-warehouse password",
	i18nn_ecb75613330d89fa:"Service provider",
	i18nn_27aaf0520109d72e:"Service Provider Code",
	i18nn_44b27e0a8476a256:"Update warehouse",
	i18nn_ea7a53fd80d5cb23:"Open warehouse update",
	i18nn_73fc38e67381ae73:"Outer box identification",
	i18nn_c8773d8d74202801:"Torus number",
	i18nn_70b5c883b227851b:"Box length",
	i18nn_0a389c3a5e3938d3:"Box width",
	i18nn_5c31dfdb8f4781ec:"Box height",
	i18nn_9e168db87b716866:"Single case weight",
	i18nn_c5ef0b068e92e23d:"Box",
	i18nn_8a43886ff356ed78:"Case identification",
	i18nn_53fa7ff1ea383b2f:"Number of boxes",
	i18nn_91263f8fa1a94da9:"Box weight",
	i18nn_20c39ac8a6aeb87d:"Right click the cell to display the menu",
	i18nn_382c5bafd600d2bd:"Insert Row Below",
	i18nn_8b456c5396713d9a:"Insert Row Above",
	i18nn_77054b0e5ea4d30d:"Insert Column Left",
	i18nn_1b2106ded89310ba:"Insert Column Right",
	i18nn_f04cf5507ecc5774:"Remove this row",
	i18nn_327aa3849b3d5c68:"Remove this column",
	i18nn_f7f39a6e6d9d26cb:"read-only",
	i18nn_0607472de4525358:"Alignment",
	i18nn_ea37e4e88aada736:"frame",
	i18nn_29f67f0644a78bf3:"copy",
	i18nn_6b7fbcd658f0ccdb:"shear",
	i18nn_c420ac9915ca288d:"Clear all cell data",
	i18nn_e92e98bf79f5667a:"Update List Configuration",
	i18nn_9c86859a48e19852:"Column Association Hiding",
	i18nn_950f48667a7ca29e:"Column Associated Display",
	i18nn_d2e73c04a7bba3a2:"Data dictionary formatting",
	i18nn_ffdca5df72a21b0e:"Initialize and load data",
	i18nn_d65010e7be5abfa7:"Array to Object",
	i18nn_536653d9057b6713:"get data",
	i18nn_f3da92af9fe55ee7:"wipe data ",
	i18nn_4e4e17df00c7c66c:"If there is value transfer",
	i18nn_6a800c36981ed32f:"No value",
	i18nn_ce1923a0c6c05200:"data dictionary",
	i18nn_4e4bec1fd140fcf7:"Number of inbound containers",
	i18nn_48bc6e8b56cba243:"Number of containers in warehouse",
	i18nn_0ddc3b0b28d12b29:"Number of locked cases",
	i18nn_51c7caf81e915eb4:"Available Cases",
	i18nn_53f9f6f694a5b0a8:"Single box size",
	i18nn_202e232a73f0abe1:"View issue details",
	i18nn_b1d3482f4406f513:"Delivery Details",
	i18nn_7f6c721b57ed0e7b:"Delivery No",
	i18nn_08e5062e3affb676:"Number of labels",
	i18nn_8af1fd820046d3c1:"Warehousing warehouse",
	i18nn_1db6434a8c2ba647:"Delivery mode",
	i18nn_e1e2b21c7b4b951f:"Transshipment mode",
	i18nn_33a85575edeaacaa:"Number of inbound pallets",
	i18nn_3d3bc466f13154d4:"Cabinet No",
	i18nn_696eca4149742942:"Whether the products in the box are displayed",
	i18nn_46b944e39511234b:"Additional services",
	i18nn_9e9277ad9980fd7d:"Expected arrival",
	i18nn_f904142c2f2c8106:"Create transfer issue",
	i18nn_ed74196efa90cded:"Edit transshipment order",
	i18nn_004bc5eedbcc75e0:"Add Transfer Doc",
	i18nn_35f54f309c93f9e8:"Received",
	i18nn_4413cff8f8a5be70:"Partial transshipment",
	i18nn_d58a804e17a620ae:"Transshipment",
	i18nn_6c1ebb1581a6828e:"Select goods to be transported",
	i18nn_5caaadc380029e3f:"Number of transfer cases",
	i18nn_d4b6bcb26128130e:"Warehousing Details",
	i18nn_e1363c8a04ba532e:"Number in stock",
	i18nn_678824ae3065f35b:"Number of locks",
	i18nn_94fb3830c62f2c1c:"Available",
	i18nn_fd6620afef3fab05:"Outbound warehouse",
	i18nn_e1d76a34da86da92:"whether",
	i18nn_1272ee58ec6ee1ad:"Application No",
	i18nn_7110aa855f2767a1:"Logistics No",
	i18nn_e98a6f2582037336:"shipment types ",
	i18nn_c301b10948889cb5:"Planned issue date",
	i18nn_e520453b3f22ad66:"Address selection",
	i18nn_59764ba919e3d70d:"Address Name",
	i18nn_fa4d50bc26e39b26:"Outer box mark",
	i18nn_4de0a112bb772da8:"Receipt Details",
	i18nn_7fba76c83eec6d09:"option",
	i18nn_e186babc2e9cadf4:"save submit",
	i18nn_b2d4fecb6418af59:"Selected data added",
	i18nn_3041594b4f6bcf64:"Whether there are duplicates",
	i18nn_98607e1e7d59bf2c:"existence",
	i18nn_a8bcab50b1eccec0:"Duplicate data is automatically filtered",
	i18nn_b66b629b8930650f:"Submit attachments first",
	i18nn_355cfd645261c0b5:"Submit new or edit methods after callback",
	i18nn_26968953ae05b4b7:"Outbound",
	i18nn_a68426dce989b497:"Warehouse No",
	i18nn_5ebc284d0e1e5cf4:"Please select the goods to be transported",
	i18nn_cbebee9d601695d5:"Source Type",
	i18nn_6e33a5e062b119ff:"Whether the articles in the box are displayed",
	i18nn_6ae7ca889532ec40:"cache",
	i18nn_ead881df31939a6e:"Original data",
	i18nn_62a3b0f685e9962c:"Table Selected Data",
	i18nn_9b0825015c2c49d9:"Display type",
	i18nn_96fb4a4cdd7de04b:"Jump to the first page",
	i18nn_ee62c49d9dae1b96:"Transfer Receipt Details",
	i18nn_7c27cb7b39aa1d82:"Goods transferred into warehouse",
	i18nn_0bc1b8fb20441d5e:"Get the selected data",
	i18nn_885206600df4fb3d:"Edit transfer receipt",
	i18nn_f6272b352b3f3061:"Add transfer receipt",
	i18nn_1f6b9a150926ba64:"Arrival time",
	i18nn_7836f25126b0e111:"If there are multiple logistics document numbers",
	i18nn_d9081425db9f2c5f:"Please separate the logistics order number with English commas",
	i18nn_21d58958f280d153:"Fast transshipment",
	i18nn_ffe3865fea625e35:"Query address",
	i18nn_266578312b060df9:"Transshipment cargo information",
	i18nn_1baf81dc415067f6:"Batch copying",
	i18nn_925eaf37d939c1f4:"Data filling in",
	i18nn_a449b9611b8c347c:"Right click to edit the table",
	i18nn_b05fb36dbc990e3b:"Please delete blank lines when saving",
	i18nn_2b3b632fb77f0712:"Can copy and paste",
	i18nn_705fc0e58821d525:"Middle data",
	i18nn_6172b2d3ca888226:"Right click to add",
	i18nn_898fe40df4262eea:"Edit Table",
	i18nn_6aada61bec5df499:"Please delete blank lines when saving",
	i18nn_2a52107e50015b89:"Double click to select or select data",
	i18nn_2063a37475d39272:"Add Case",
	i18nn_17768c25d95d04ca:"Transshipment type",
	i18nn_7669a6b7a38f1189:"Initialize attachment upload",
	i18nn_2b4625e017315798:"Change Column Hide",
	i18nn_b6cb663839a53fd9:"Transshipment order No",
	i18nn_b1131a0cc3945b5f:"Delivery order No",
	i18nn_eed89121cdfb33c3:"Completion date of goods preparation",
	i18nn_54bfc3e9958727d8:"Delivery completion date",
	i18nn_dcc830098ae4c9fd:"To be delivered",
	i18nn_a342c84e359f0d02:"Issued",
	i18nn_95fb447f1661affd:"Edit Issue",
	i18nn_bab35eeb688f9eae:"New Issue",
	i18nn_7cebbad6657bdbf4:"Expected arrival date",
	i18nn_d420ca5d51e2fc22:"Goods to be transported",
	i18nn_b498df927d973f01:"To select",
	i18nn_9d3249981866af60:"Source of goods",
	i18nn_9618edd493c9d6ea:"Current total",
	i18nn_1ab5fae021b6c8ff:"Rotate the goods to be transported",
	i18nn_53976ac09b57e51e:"Data of transfer receipt",
	i18nn_87daa7865e42c641:"System inventory",
	i18nn_b801e4587837fe15:"Empty the selected goods",
	i18nn_402840e712e7659c:"Open option drawer",
	i18nn_f217cfe6c6291a89:"Please select the outbound warehouse",
	i18nn_9de263ffbe229226:"Please select the source of goods",
	i18nn_378542a521437fed:"Query the corresponding warehouse data",
	i18nn_4e36adb4a6d6f9ff:"Transhipment cargo",
	i18nn_4958725d5bd39384:"Goods in stock",
	i18nn_c996cb376f253cb5:"Batch import",
	i18nn_844521f361060167:"Single entry",
	i18nn_7c5912ab3762973a:"Batch entry",
	i18nn_a476975987294b07:"returns management ",
	i18nn_429b4e0f15fd551d:"System problems",
	i18nn_f37cb25b8f6a5486:"After downloading the template",
	i18nn_a6278bd3526e47e6:"Direct import",
	i18nn_23b9e07b17dfefc2:"You can also copy and paste",
	i18nn_3644a90f9f13f0b8:"Work order information",
	i18nn_2a714652d0b17e0d:"Create value-added service",
	i18nn_32a62371f9d0aca8:"Order Number",
	i18nn_8b9b30a5bc0eab3a:"mail",
	i18nn_3e429a7c7cf3cab2:"Address 2",
	i18nn_976cf9b5668838a0:"State abbreviation two character code",
	i18nn_245a46426329f070:"Tracking sheet No",
	i18nn_8f5ce149c71f00d3:"Express abbreviation",
	i18nn_488e817afcd3b95e:"Whether to sign",
	i18nn_d7aef993e73ebf60:"Shipping Information ",
	i18nn_388b46f93fbfbf4d:"Tracking information",
	i18nn_9d3382d5a74e6e34:"Mandatory when self providing Label",
	i18nn_b2b1ac4f554665b1:"Insurance and signature services",
	i18nn_0ffca7433738453f:"Only used for platform issuing",
	i18nn_b74bad1a7a5403b1:"Warehousing information",
	i18nn_35b7a8c5deb39056:"Outer box specification",
	i18nn_1697895cfac3573c:"When one box for one piece",
	i18nn_cc492a06237b3169:"Specification of outer box can be left blank",
	i18nn_25528063f1b6a596:"assignment",
	i18nn_10c015e3241e842e:"State abbreviation",
	i18nn_e366dc49c78c8e3d:"Ship To Address",
	i18nn_514377f02a8481e6:"Save and Submit",
	i18nn_1e9e4c26111fc0e7:"Preview Only",
	i18nn_87d44ee5d0d34147:"Preview only",
	i18nn_9cdb5c847968eb64:"Can be imported directly",
	i18nn_563846f3c8bdd98f:"The specification of the outer box can be left blank",
	i18nn_a31d32ac4015d196:"Tracking No",
	i18nn_6aa78c268e87527b:"Bill No",
	i18nn_108c7dc6debca017:"Cabinet type",
	i18nn_43cebc877265943a:"Outer box specification unit",
	i18nn_4843108e7e424145:"Gross weight of outer box",
	i18nn_f56c111456a351be:"Arrival date",
	i18nn_4c72d6edbaf60363:"Receipt Doc",
	i18nn_a1ef1454c217a90d:"Manual entry",
	i18nn_07cac86ffb75046b:"Shipment No",
	i18nn_e6e818680d77e014:"Barcode box mark",
	i18nn_6d8f9b0c398319ff:"Whether battery is included",
	i18nn_e02b1be61269eb71:"Product created successfully",
	i18nn_65faa0f7b990ed30:"Please contact customer service and wait for review",
	i18nn_b0493e82a0426f5c:"Create inbound forecast",
	i18nn_a946a88e4f41f9c6:"Will return automatically in seconds",
	i18nn_726dda93aca054e6:"Before leaving",
	i18nn_b9ee0408e39cb451:"It is recommended to code the combination of numbers and letters",
	i18nn_5d819cd0462cbe89:"number",
	i18nn_dfa40fe9cb22797d:"letter",
	i18nn_1786e81d2ba2694a:"combination",
	i18nn_d9df5070c410e81d:"The system will",
	i18nn_418709fadc1e6c54:"Generation criteria",
	i18nn_cef61d13aefc83cf:"Warehouse will be as per standard",
	i18nn_411fdc693cb46984:"Unapproved",
	i18nn_528f3ff5da537927:"Get User Rights Menu",
	i18nn_48fea1870aa235fd:"Successfully obtained user permission menu",
	i18nn_725d750832726cc3:"Failed to obtain user permission menu",
	i18nn_747b33bfa850b075:"Failed to obtain user permission menu request",
	i18nn_fb7df0fd07958d28:"according to",
	i18nn_e11679a643689255:"Select Label file",
	i18nn_70bd0f4327eda581:"Resubmit data",
	i18nn_caed6cfc34b968aa:"Recipient name one",
	i18nn_f9c0ee1156f1e254:"Recipient name 2",
	i18nn_aa2bc6dc3cafeb11:"State two-character code",
	i18nn_2dfd6348829d984f:"Sender name 1",
	i18nn_e03f8082906c4de8:"Sender name 2",
	i18nn_edb1b11e6c5ceb01:"Minimum ten digits",
	i18nn_c481683ec9fca419:"Operation type code",
	i18nn_94a6bbd4c69bda08:"Expense type code",
	i18nn_51c53d67a11837ae:"Expense name code",
	i18nn_d8917510efdc82c9:"Sub-account management",
	i18nn_f3df8675ebf8aeb6:"Sub-account list",
	i18nn_22ffd923fd102ddf:"Sub-account menu",
	i18nn_c1cb84ac1ef13c77:"Setup Menu",
	i18nn_766bfac938d98e04:"Platform order pull",
	i18nn_898a2833fbeaf9e4:"State and province",
	i18nn_604a0b11e916f387:"password",
	i18nn_7a806e59f1e812b7:"Expense type configuration",
	i18nn_4b2d7b585777fa99:"Master account",
	i18nn_76dc341edac65885:"Business type",
	i18nn_c5400c966a9be32d:"Operation module",
	i18nn_cdd4901a665110a9:"Sub-account or not",
	i18nn_2489ac08975334e4:"Customer or company name",
	i18nn_e4842b8507dd415c:"Company abbreviation",
	i18nn_3e98a7801f9d8a6d:"Warehouse",
	i18nn_f5765bd7956321f2:"View password",
	i18nn_8a2be54a2e609dcc:"Sub-account account",
	i18nn_9ea9185268cf2519:"Auto account",
	i18nn_e78b4579ca11c47f:"Abbreviation of sub-account",
	i18nn_8664edb3936db8b0:"Auto generate sub-account",
	i18nn_4438ee6b6104e469:"Sub-account",
	i18nn_f1e02877d27204c8:"Open the account selection pop-up window",
	i18nn_c4418aa4798740f5:"Permission configuration",
	i18nn_d02053ea2ba9ff5d:"Customer menu configuration",
	i18nn_67cfb454fd187462:"Form prompt",
	i18nn_0b6ece8a034c7796:"You can add a permission group",
	i18nn_df812a0da6bcd476:"And name it",
	i18nn_7a9be273020481b8:"Easy to use when adding administrators",
	i18nn_d4573844836678d9:"You can select all functions in the title or select operation permissions one by one according to function modules",
	i18nn_124961995dbe464b:"Effective after submission and saving",
	i18nn_7732c57a7fb0fe19:"Subuser",
	i18nn_e4fce3e8821ac4e3:"Management background",
	i18nn_1848b65a9a76cccc:"Basic configuration",
	i18nn_e40dab1482ecbe86:"Permission group",
	i18nn_01f47f2bd0c79f92:"menu",
	i18nn_8931a531d877d8f7:"Array of",
	i18nn_3f92cfa1112482ad:"Please enter permission group",
	i18nn_b4bb025e18029ea2:"Array de-duplication",
	i18nn_1b34adde1d3c15c2:"Delete another array that exists in the array",
	i18nn_cb4296d31d8d364b:"Determine whether a value exists in the array",
	i18nn_9146c3ccdbe784d6:"Node selection value changes",
	i18nn_b30018eb83010fec:"Node selection",
	i18nn_40a663fc001afe0b:"First level menu",
	i18nn_fdc6f9307b183261:"Check",
	i18nn_12a4499cf846736f:"Child node",
	i18nn_1fe3d7b3eb1db3d8:"Select",
	i18nn_7a5458f8a98af422:"Select all the lower sub-nodes",
	i18nn_f6b7b613680677c5:"Uncheck",
	i18nn_f979c31ebd810e6e:"Deselect all the lower sub-nodes",
	i18nn_5edf9f72008ffba3:"Menu selection",
	i18nn_7d689a2358e2dca2:"Click the parent node",
	i18nn_781134873aebe2a7:"Child node exists",
	i18nn_7b4a77d9be8217c9:"Click child node",
	i18nn_dbdf33f6dce59a29:"open",
	i18nn_58f25b8feab039d3:"Set the checked value to display",
	i18nn_d47f3e0b7855dd5f:"Incorrect submission",
	i18nn_6a944013cd8a67ef:"Query menu interface",
	i18nn_e582b4c8c2db445e:"Expand Item",
	i18nn_86ac14b63f2ae88d:"Non-expanded items cannot be selected",
	i18nn_48557aa1d205c240:"Warehouse menu",
	i18nn_2c34971d7f66bc6e:"Secondary menu",
	i18nn_5d6fde2581708559:"Failed to query menu interface",
	i18nn_bba8d0a8bb054bf5:"Query details interface",
	i18nn_d5610f2f4144069b:"Query Menu",
	i18nn_5c4a773bafcfe7bd:"Tick",
	i18nn_4fd3a7662429a107:"Displayed value",
	i18nn_4c2c80cd8bb476a3:"User menu configuration",
	i18nn_b7f118c3e8c6d135:"Failed to query user details",
	i18nn_6783c8d6f7c9a7e8:"Only cabinet type",
	"i18nn_9f88e3e6b1e719bd": "Choose a channel",
	"i18nn_0b2cd5cfcfebe08e": "EC synchronization",
	"i18nn_0640e3814d107002": "Current location inventory",
	"i18nn_68b3bb1ef6931076": "Current locked stock",
	"i18nn_d91fd63094e82992": "Current available stock",
	"i18nn_dfd9cde6fbd425c7": "Is it synchronizable?",
	"i18nn_3bae5212138a7193": "Number of differences",
	"i18nn_ddecfa4a8d6bb743": "EC available number",
	"i18nn_73e14fb8e120ce61": "Source of inventory",
	"i18nn_07048291fdb26b6e": "synchronization",
	"i18nn_62519f438e8f64b5": "Whether to return the flyer number",
	"i18nn_296b2c6a46764af7": "Automatic synchronization or not",
	"i18nn_d165fe5a9a65fb8c": "Synchronization method",
	"i18nn_219c1f2a0f4526d5": "submenu",
	"i18nn_50b0646769862d34": "Check the parent menu",
	"i18nn_0d81640498b8d063": "View File",
	"i18nn_54e4d834b7e2c26a": "inventory shortage",
	"i18nn_38624a6aa0667143": "overage",
	i18nn_fd445581cff561bf:"Value-added service work order",
	i18nn_0174ec83eeb25914:"Are you sure to cancel",
	i18nn_2d34f0565cfc4473:"Select an existing inventory",
	i18nn_f0a1ff9d4d865b10:"Does this already exist",
	i18nn_650abf9b436446ba:"Select Inventory",
	i18nn_89304b37afa2f1b0:"current",
	i18nn_578842e17fea3b6a:"Line 1",
	i18nn_cb143acd6c929826:"China",
	i18nn_4fd9ca7a902c7edb:"Line 2",
	i18nn_09b2a7a32b3c8db1:"U.S.A",
	i18nn_4b52c5caf5562c6e:"Printing data",
	i18nn_ec5cc7f6883ccae7:"Selected channel",
	i18nn_7845bbfff6467299:"All channels",
	i18nn_03277940e613487c:"Data of current operation",
	i18nn_00b325af374656d9:"Channel query",
	i18nn_e04e7bab111a879b:"used",
	i18nn_ace012504bc529e0:"news",
	i18nn_c3525eb110db58aa:"more",
	i18nn_6e961a14e09d9784:"Message notification list",
	i18nn_b40debd910bb74f2:"Notification object",
	i18nn_2528332bfcac14b5:"Message type",
	i18nn_db1f861ffd10f028:"Notification Title",
	i18nn_bd0db0ad240cc83b:"content",
	i18nn_7df9df0664f60cd8:"Content viewing",
	i18nn_44f6051765cf57e0:"Select an existing",
	i18nn_824815ceb3f6c6db:"Selected",
	i18nn_1ff0f0c0745be010:"Displayed",
	i18nn_f7fac56017a0ca84:"More please",
	i18nn_34ef906a3a954ad8:"More please search",
	i18nn_9834e54432d0c672:"Loading completed",
	i18nn_08231eb7a4fcf2a3:"Don't load again",
	"i18nn_1bab2e4f2c65c40f": "pallet",
	"i18nn_5471f398d8acf5fc": "Article 1 Direct entry",
	"i18nn_4ddd2d2b6323a567": "Others insert new data",
	"i18nn_87f891127ffff1f8": "Warehouse volume statistics",
	"i18nn_c1b676e21af630d2": "All Stock",
	"i18nn_158ecb8cbcabc069": "Expense service",
	"i18nn_3107835382623271": "Use commas (,) to separate multiple tracking numbers",
	"i18nn_fc554f12d8734681": "Enable ",
	"i18nn_f4af8c42d7478c6f": "Update destination",
	"i18nn_c7948588841d75cc": "Inventory synchronization",
	"i18nn_65ff76c08629a2c8": "Check number box",
	"i18nn_74c2b69060be9bfe": "Receiving list",
	"i18nn_579f43c8c657bbbc": "Destination warehouse",
	"i18nn_81a7c94b5c510304": "Destination",
	"i18nn_8b3dbf4f5793930b": "Destination",
	"i18nn_f5ca68b04fa47a59": "Whether to fill in the destination",
	"i18nn_c6bf739e696c2bfc": "Name to choose next",
	"i18nn_af48c37c06629ec5": "Number of outgoing containers",
	"i18nn_f88c31ed3971ca4d": "step",
	"i18nn_1408336bd263e043": "Outbound list",
	"i18nn_4118399786a5251a": "Inventory list",
	"i18nn_54be52f6d5b4641a": "Out of storage",
	"i18nn_8e23aeb472e03f34": "Waiting list",
	"i18nn_6feb2a7c40070fa2": "Ordering information",
	"i18nn_80755d56db88fca2": "Please fill in the number of outgoing containers",
	"i18nn_f608e17fc712987a": "In-store inventory",
	"i18nn_92958dfd9227a641": "Tail path information",
	"i18nn_2fc069db0549ff40": "Inventory details",
	"i18nn_f1c73718e1c8fcb7": "Storage source",
	"i18nn_1829e02aab962bd5": "Receipt number",
	"i18nn_c62b0d9279f5e7cd": "Third inventory info",
	"i18nn_560595129574513f": "Processing log",
	"i18nn_e46ef79830a8e08c": "Viewing processing logs",
	"i18nn_cddbe40f9813ab94": "Receipt time",
	"i18nn_9db0c5231c087a3e": "Let the world manufacturers interact",
	"33bae59bb784a109": "sorry, submission failed, please try again! ",
	"cc62f4bf31d661e3": "prompt information",
	"i18nn_889b3ef0590d9f54": "start using",
	"i18nn_b6a8725d78c586be": "No online express waybill",
	"i18nn_8f89632c68d3b9a2": "Days deferred",
	"i18nn_7689a837889ed035": "express bill",
	"i18nn_28c0ca2da0ad71dc": "Not online ",
	"i18nn_e00cd157c0ac4d1f": "New Address ",
	"i18nn_4672c6113ffede32": "Booking out ",
	"i18nn_cc335ba8f4b2cc0d": "Reservation Number ",
	"i18nn_a29209bd396e4bdd": "Reservation Method ",
	"i18nn_5cd591b578350972": "Address number ",
	"i18nn_934695e82afbe756": "Is the delivery order issued? ",
	"i18nn_1d9d50fca9ffeb39": "The reservation is express bill is not completed ",
	"i18nn_c5e66971134b7300": "Reservation completion time ",
	"i18nn_b2d95e887d5425b7": "Reservation Details ",
	"i18nn_994551e2ae3176ca": "Detailed list ",
	"i18nn_dae2f81921b3b3c0": "Edit Appointment ",
	"i18nn_d992d16df3748947": "New Appointment ",
	"i18nn_59550e3d8c8088f9": "Reservation",
	i18nn_e93cb6cc8d5978ed:"Please enter the password",
	i18nn_9cb1c9c51e7503d6:"Confirm Password",
	i18nn_7f7ce15a429dbf34:"Please confirm the password",
	i18nn_b458f03c43a90ee9:"Picking list number",
	i18nn_56b48b3b6de43bde:"Batch transfer and warehousing",
	i18nn_e69eafd9946571ed:"Batch transfer and outbound",
	i18nn_5ddc19a148f5e5c1:"Return to warehouse",
	i18nn_886ea69af099215d:"Appointment of pallets",
	i18nn_611f87335433e0c3:"Arrival method",
	i18nn_b2e9a0ce00229898:"Clear operational instructions",
	i18nn_b195f5d99d869b3f:"such as",
	i18nn_43cef0e6de330163:"Labeling requirements",
	i18nn_137d90eaafe33693:"Labeling quantity",
	i18nn_0f03549000bc5f2d:"Labeling method",
	i18nn_b95b66c11ead8aac:"mark",
	i18nn_54f17bfe03d79b8a:"Box label",
	i18nn_1d5ba91957adcff9:"Pallet label",
	i18nn_c8c17c7e3fbf62f1:"etc.",
	i18nn_34cc4b37bb2d93a7:"Supporting requirements",
	i18nn_4721a0daf4241a40:"Do you need to take photos",
	i18nn_6c1e4bd2907c0a3d:"Volume measurement",
	i18nn_10abe7527d9d0f26:"Weight measurement and other matters that require warehouse operations",
	i18nn_fa02547543cff0f7:"Whether to change the label",
	i18nn_c617dfa4c0b5ecb4:"System number",
	i18nn_43f2b49eef6bda66:"Appointment Details",
	i18nn_515bc077445e40dc:"Appointment status",
	i18nn_61c33362d88eb0ba:"source",
	i18nn_b7a786a287eb00e4:"Whether to make an appointment",
	i18nn_fb6105f4b294a9d0:"Pickup time",
	i18nn_b7f9db36db186f72:"Appointment operation",
	i18nn_ea881f28aae703af:"Appointment Type",
	i18nn_67a39834582ad99a:"There is currently no data available",
	i18nn_e69b8beb7706725b:"Date of receipt",
	i18nn_da1ca7369f4bde15:"Appointment Form",
	i18nn_68fd8440c5a9dd35:"Delivery completed",
	i18nn_2bcdd4344979655f:"Appointment completed",
	i18nn_e90a876d882d595a:"Expand Appointment Details",
	i18nn_42274ccae6a91180:"Appointment outbound number",
	i18nn_db9b1bd30ea188f8:"Print container dismantling list",
	i18nn_e99e3c2ab95512c3:"Cabinet disassembly completed",
	i18nn_2928fee14cabb006:"Please proceed with the following steps",
	i18nn_13247dc8e601ddef:"Transfer and warehousing container number",
	i18nn_40859a22ab42e313:"Date of arrival at warehouse",
	i18nn_b6e1497fc95f6691:"Completion Date",
	i18nn_f87d23ab43f0b299:"Bookable outbound list",
	i18nn_888c8eeffccf0077:"Display zero inventory",
	i18nn_012297a66930e480:"Are you sure to update the password",
	i18nn_c0d09a392d8acf54:"Update successful",
	i18nn_6724e693fadf9a09:"Do you want to log in again",
	i18nn_b781fc794861218f:"Picking method",
	i18nn_84599b8cfb3aa3b9:"Warehouse cargo volume",
	i18nn_90056eff057da3d0:"Warehouse cargo volume and weight",
	i18nn_79d40c126a46dd61:"please",
	i18nn_56aa8ea768a4c6bd:"Right mouse button",
	i18nn_17700f3f4a7ee5f6:"Cells",
	i18nn_78897452dfd5c501:"If more actions are needed",
	i18nn_f1b23358832dfa49:"View Above",
	i18nn_12f42056f558e6f6:"If it is a private address",
	i18nn_05a8d5e71aabbcdd:"Please fill in the format",
	i18nn_5eb29146a398224e:"Kappa",
	i18nn_bfcb068b25558f57:"Appointment for delivery is",
	i18nn_96b7fef9ab4cc937:"At warehouse",
	i18nn_af757a29a5046ebe:"Unknown index data",
	i18nn_f6f129c36d0c471e:"Cannot exceed",
	i18nn_cc6963cfe06fc064:"Batches",
	i18nn_e19edf6996055dc5:"Are you sure to delete this item",
	i18nn_771752e23324e1c4:"Failed to update address",
	"i18nn_77c91da599d5b4b6": "Created successfully",
	i18nn_5b177a1b00c63503:"Port container pick-up",
	i18nn_9a716c393c296ea8:"Final Kapai",
	i18nn_600c6fad90d0d7b1:"Billing Status",
	i18nn_15a021996904c423:"Confirm Status",
	i18nn_8468ac93161085d6:"Priority operation",
	i18nn_27e24ff9191e254d:"have",
	i18nn_19ec51d5bd5fc832:"absence",
	i18nn_7a29cab1a4b8f231:"Priority operation",
	i18nn_20650eaf339e1fe6:"contacts",
	i18nn_c45d595e849aec6a:"Add Appointment Form",
	i18nn_7f0e36c0c32152b9:"Self pickup number",
	i18nn_a1c919aef2dec6d4:"Add details",
	i18nn_d256e16919f5fceb:"Appointment data",
	i18nn_4f5f83fc6780a29c:"Select goods details",
	i18nn_197e2039cf365afa:"Number of pallets in the warehouse",
	i18nn_83125000f5142ed1:"Number of pallets before warehousing",
	i18nn_6fd4956e676cec49:"merge",
	i18nn_0529f4753fb3c5a0:"size",
	i18nn_61ebcde89251413e:"Confirm merge",
	i18nn_facd05c043886557:"Merge Partial Rows",
	i18nn_1861b71eeabd88b9:"Do not merge",
	i18nn_54dc58d9d0377bb5:"Delete failed",
	i18nn_b8b6bfc4485da611:"correlation",
	i18nn_5b16dd13f1bc0e1c:"Multiple order number search",
	i18nn_33bb5e57aba192e1:"Order number input",
	i18nn_b92065474c13bf38:"Enter one order number per line",
	i18nn_b5511889be42a3da:"Confirm and search",
	i18nn_da7eef74bb8e0f6d:"Warehouse completion time",
	i18nn_98ea9b63e41e8543:"Length, width, and height",
	i18nn_d12a2c11456e7f47:"Arrival date",
	i18nn_c5378920e015b12f:"Select waybill",
	i18nn_b539914bf0af5314:"Verify and match",
	i18nn_470fa451f57e6fff:"Automatic matching of identification waybill number",
	i18nn_2428648cafd20f21:"Upload matching waybills",
	i18nn_eb680d44b667d34e:"Identify based on waybill number",
	i18nn_53f9c1e720d261f9:"Can automatically split the waybill to various orders",
	i18nn_39df26e0051d7903:"Confirm Save",
	i18nn_349ee56d6d895249:"Maximum file size",
	i18nn_1af19e9a72a235ba:"Submit and verify",
	i18nn_379c907d12f5c9f5:"Matching successful",
	i18nn_a5d008a482d2b005:"Number of pages",
	i18nn_61d278478046ceaf:"File stream",
	i18nn_b14b20e17d756cb2:"Matching failed",
	i18nn_cc3cb77aad104caa:"Re select",
	i18nn_7c9926ac60a726ac:"Upload successfully matched waybills",
	i18nn_8433ab19cdbad17e:"Upload status",
	i18nn_933692e63faf4555:"View matches",
	i18nn_f524f7dafdd60a7a:"Save matched waybills",
	i18nn_b109726c562cc092:"No matching data",
	i18nn_0bf3695809741cae:"Unknown",
	i18nn_247c878546702010:"Automatically identify and save based on waybill number",
	i18nn_dfaa3ac89594cfe8:"After uploading",
	i18nn_e7ce2fa6da443509:"Please wait for the result",
	i18nn_8054e6a00f7fb9c7:"expect",
	i18nn_ced2ef6d0ce80ac1:"Matching completed within seconds",
	i18nn_12fde6f4ac0bd2a7:"The larger the file, the longer the wait",
	i18nn_926b52e41c45d835:"Upload and match",
	i18nn_8aad52f19406c72d:"Historical matching records",
	i18nn_d426ae19e98559b5:"PageCount",
	i18nn_75247a5e05331f7f:"Matching details",
	i18nn_46d9c96bfde09a4a:"Please select a file",
	i18nn_60c521726fa38f44:"Maximum Display",
	i18nn_9e0c510eaed20bc7:"Refresh matching results",
	i18nn_e5e53c1aa28e6682:"File name matches order number",
	i18nn_127af9ed95c3c6be:"Match order number by file name",
	i18nn_2fe088ec1114c539:"Automatically match by waybill number",
	i18nn_3d189e6fa5541753:"Confirm synchronous import",
	i18nn_8b66deca86e6d66d:"Please enter a two character code",
	i18nn_40ac872c0aa6ff41:"Barcode download",
	i18nn_6c2519a4169a3bd4:"Download prompt",
	i18nn_83130aa2fa9470ae:"When there is a large amount of data",
	i18nn_f57015be50941d22:"The browser will get stuck",
	i18nn_e1214c4d8b4e9626:"Please wait a moment",
	i18nn_1c4cee3493d17175:"When the waiting time is too long",
	i18nn_35c67777896ab842:"Suggest closing the page and entering again",
	i18nn_c121c5915b23c201:"After filtering",
	i18nn_e1c83961e7a8f928:"Batch download",
	i18nn_f5e108e4d43c2a4b:"Only before downloading",
	i18nn_c11ab9cc6f2492e6:"After filtering, please",
	i18nn_4ea50b32216e5504:"When the code is too long",
	i18nn_dc88ac4413b2bf37:"Generated",
	i18nn_fb870f042cdc0b90:"The code image will also be very long",
	i18nn_7ce36744aeec211a:"Will be missing",
	i18nn_e4f56cecd0453bbf:"Suggest downloading",
	i18nn_375809e1d450d562:"Or right click on the code and save it as an image directly",
	i18nn_5f7f4143c0564b54:"Load page",
	i18nn_7215a6d646ce357e:"Currently available for download",
	i18nn_90aa9ed0f2de2aa5:"Operating instructions",
	i18nn_110b67a13dd38b1e:"primary",
	i18nn_788d9bdcf8ff660e:"Original quantity",
	i18nn_8544666ef2803c20:"To download",
	i18nn_ea80f4411a8408b8:"Target printer",
	i18nn_1cd45ccce46f619e:"Save as",
	i18nn_6f9c5b165f3bc662:"It can also be removed",
	i18nn_f54ebccbce73a3d6:"header footer",
	i18nn_7cbe47cdcaa28905:"List data",
	i18nn_cba3f5ca1278aa85:"Loading",
	i18nn_c38a2f9ec3b8d17b:"Failed to query data dictionary interface",
	i18nn_4bf00570023be25f:"Inviter account",
	i18nn_94a8da065245abe4:"account type",
	i18nn_474da87fdb795187:"Must be an email address",
	i18nn_2fca45d67fdcae33:"Email format error",
	i18nn_d49a93bb265a311d:"Verification code",


}