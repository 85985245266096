<template>
  <div class="HyUpLoadImgCon" v-loading="loading" :element-loading-text="$t('module.upload.uploading')">
    <!-- <form action="" class="form-horizontal"> -->
    <div class="HyUpLoadImgAll">
      <el-row class="hideUpImgCon" style="display: none;">
        <el-col :span="10">
          <label>{{ $t('module.upload.Choose_File') }}</label>
          <input type="file" id="" ref="file" @change="fileChange($event)" accept="image/gif,image/jpeg,image/jpg,image/png" />
        </el-col>
        <el-col :span="4"><input type="button" class="btn btn-primary" ref="file-button" id="" @click="upLoadAction()" value="上传" /></el-col>
      </el-row>
      <div class="showUpImgCon clearfix" style="">
        <div
          class="upImgCon fl"
          :style="{
            width: imgwidthS + 'px',
            height: imgheightS + 'px'
          }"
          @click="openFile"
        >
          <img v-if="imgSuccessUrl && '' != imgSuccessUrl && '0' != imgSuccessUrl && 'null' != imgSuccessUrl" :src="imgSuccessUrl" :width="imgwidthS" :height="imgheightS" />
          <!-- <img v-else src="../../assets/images/common/upimg.png" :width="imgwidthS" :height="imgheightS" /> -->
          <div v-else class="noImgContent" :style="{ width: '100%', height: '100%' }">
            <!-- <img src="../../assets/images/common/upimg.png" :width="'40%'" :height="'auto'" /> -->
          </div>
        </div>
        <div class="upImgBtnCon fl">
          <div class="imgDescCon"><slot name="imgDesc"></slot></div>
          <div class="upImgBtn" @click="openFile">
            <el-button type="primary" :size="btnSize">
              <!-- 点击上传 -->
              {{ $t('module.upload.Click_upload') }}
            </el-button>
          </div>
          <div class="upImgBtn" v-if="!!hasRemove" @click="removeImg">
            <el-button type="danger" :size="btnSize">
              <!-- 删除图片 -->
              {{ $t('module.upload.Delete_picture') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://www.promisejs.org/polyfills/promise-6.1.0.js"></script> -->
<!-- <script type="text/javascript" src="https://gosspublic.alicdn.com/aliyun-oss-sdk.min.js"></script> -->
<script>
// import aliyun from 'https://gosspublic.alicdn.com/aliyun-oss-sdk.min.js'
// import common from '../common.vue'
// import OSS from 'ali-oss';
	import {
		ossConfig,
		ossClient
	} from '@/config/ossConfig.js';
	
export default {
  name: 'HyUpLoadImg',

  //meta信息seo用
  // metaInfo: {
  //     title: '互易天下-厂家共享平台-求购大厅-棉', // set a title
  //     meta: [{                 // set meta
  //       name: '互易天下-求购大厅-棉',
  //       content: '互易天下-厂家共享平台-求购大厅-棉'
  //     }],
  //     // link: [{                 // set link
  //     //   rel: 'asstes',
  //     //   href: 'https://assets-cdn.github.com/'
  //     // }]
  //   },

  props: {
		folder:'',
    imgId: '',
    imgKey: '',
    hasInit: '',
    watchKey: '',
    bucket: '',
    imgwidth: '',
    imgheight: '',
    hasRemove: false,
    autoOrient: false,
    autoResize: false,
    //每个文件限定的大小
    maxSizeMB: {
      type: Number,
      default: 2
    },
    btnSize: {
      type: String,
      default: ''
    }
    // imgId:"",
  },

  watch: {
    watchKey: function(newVal, oldVal) {
      console.log('watchKey');
      // if (newVal) {
      // console.log('watch openDateTime HyUpLoadImg.vue');
      this.initData();
      // }
    }
  },
  data() {
    return {
      loading: false,
      imgwidthS: '',
      imgheightS: '',
      // OSS: OSS.Wrapper,
      client: {},
      imgSuccessUrl: ''
    };
  },
  created() {
    // this.getPageData();
    console.log('created');

    // this.imgwidth = this.imgwidth?this.imgwidth:200;
    // this.imgheight = this.imgheight?this.imgheight:200;
    // console.log("upLoadAction");
    // console.log(OSS);
    // console.log(this);
    // this.$parent.mNavActiveIndex = '3';
    this.initData();
  },
  mounted() {
    console.log('monted');
  },
  methods: {
    initData() {
      console.log('initData');
      if (!this.imgwidth) {
        this.imgwidthS = 200;
      } else {
        this.imgwidthS = this.imgwidth;
      }
      if (!this.imgheight) {
        this.imgheightS = 200;
      } else {
        this.imgheightS = this.imgheight;
      }
      this.imgSuccessUrl = '';
      // var bar = this.$refs['progress-bar'];
      // if(bar){
      //   bar.style.width = '0%';
      //   bar.innerHTML = '0%';
      // }
    },
    initUrl(imgsrc) {
      //阿里云 图片处理
      let myImgUrl_suffix = '';
      if (!!this.autoOrient || !!this.autoResize) {
        myImgUrl_suffix = '?x-oss-process=image';
        //阿里云 根据图片信息自动旋转
        if (this.autoOrient) {
          myImgUrl_suffix = myImgUrl_suffix + '/auto-orient,1';
        }
        //阿里云 图片自动裁剪
        if (this.autoResize) {
          let autoW = '',
            autoH = '';
          if (!isNaN(parseInt(this.imgwidth))) {
            autoW = ',w_' + parseInt(this.imgwidth);
          }
          if (!isNaN(parseInt(this.imgheight))) {
            autoH = ',h_' + parseInt(this.imgheight);
          }
          //参考： https://help.aliyun.com/document_detail/44688.html
          // myImgUrl_suffix = myImgUrl_suffix+"/resize,limit_0"+autoW+""+autoH+",m_fill";//裁剪
          myImgUrl_suffix = myImgUrl_suffix + '/resize,limit_0' + autoW + '' + autoH + ',m_pad'; //不裁剪，等比缩放，填充透明
        }
      }
      if (imgsrc) {
        this.imgSuccessUrl = imgsrc + myImgUrl_suffix;
      } else {
        this.imgSuccessUrl = '';
      }
    },
    //删除图片
    removeImg() {
      this.$confirm(this.$t('module.upload.sure_del_img'), this.$t('tips.tipsTitle'), {
        // //confirmButtonText: '确定',
        // //cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // this.initUrl("");
          this.$emit('removeImg', '');
        })
        .catch(() => {});
    },
    // toAdd(){
    //   this.$router.push({name:'ReleaseBuy'});
    // },
    openFile() {
      console.log(this.$refs);
      console.log(this.$refs.file);
      this.$refs.file.value = '';
      // this.$refs.file.dispatchEvent(new MouseEvent('click'));
      var comment = this.$refs.file;
      if (document.all) {
        // For IE
        comment.click();
      } else if (document.createEvent) {
        // 在ff中要为a标签添加click事件，并且侦听该事件
        var ev = document.createEvent('MouseEvents');
        ev.initEvent('click', false, true);
        comment.dispatchEvent(ev);
      } else {
        this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
          // //confirmButtonText: '确定',
        });
      }
    },

    fileChange(e) {
      this.loading = true;
      console.log(e);
      console.log(this.$refs.file);
      console.log(this.$refs.file.files);

      if (!this.$refs.file || !this.$refs.file.files) {
        this.$alert(this.$t('module.upload.open_browser_error_img'), this.$t('tips.tipsTitle'), {
          // //confirmButtonText: '确定',
        });
        this.loading = false;
        return;
      }
      // return;
      if (this.$refs.file.files[0]) {
        if (
          this.$refs.file.files[0].type === 'image/png' ||
          this.$refs.file.files[0].type === 'image/jpeg' ||
          this.$refs.file.files[0].type === 'image/jpg' ||
          this.$refs.file.files[0].type === 'image/gif'
        ) {
          if (this.$refs.file.files[0].size < 1024 * 1024 * this.maxSizeMB) {
            this.upLoadAction();
          } else {
            this.loading = false;
            this.$alert(this.$t('module.upload.uploaded_over') + this.maxSizeMB + 'M', this.$t('tips.tipsTitle'), {
              // //confirmButtonText: '确定',
            });
          }
        } else {
          this.loading = false;
          this.$alert(this.$t('module.upload.Unknown_image'), this.$t('tips.tipsTitle'), {
            //confirmButtonText: '确定',
          });
        }
      } else {
        //重复上传时出现
        this.loading = false;
        // this.$message('未知文件', this.$t('tips.tipsTitle'), {
        //   //confirmButtonText: '确定',
        // });
        // this.$message({
        //   message: '未知文件',
        //   type: 'warning'
        // });
        console.log('未知文件，重复上传时出现');
        // this.loading = false;
        return;
      }
    },
    // handleSelect(key, keyPath) {
    //     console.log(key, keyPath);
    //   },
    upLoadAction() {
      // window.applyTokenDo(window.uploadFile);
      console.log('upLoadAction');

      // let _this = this;

      // var Buffer = this.Buffer;
      // var OSS = this.OSS;
      // console.log('upLoadAction', OSS);

      let bucket = this.bucket;
      console.log(bucket);
      if (!bucket) {
        // huyi-oss-user // 认证，订单合同等
        // huyi-oss-headportrait //头像
        this.$alert(this.$t('module.upload.comp_Failed') + 'code:1', this.$t('tips.tipsTitle'), {
          // //confirmButtonText: '确定',
        });
        return;
      }

      //
      // huyi-oss-headportrait
      //
      //参数配置
      // var client = new OSS({
      //   region: 'oss-cn-hongkong',
      //   accessKeyId: 'LTAI5t9pUsVQBnwyGgeGGuc4',
      //   accessKeySecret: 'XljA667qnWvCmtwchv0mfyQPevtUsw',
      //   bucket: bucket,
      //   endpoint: 'oss-cn-hongkong.aliyuncs.com'
      // });
			
			let client = ossClient(bucket);

      // var client = this.client;

      //文件尺寸设置
      var progress = function(p) {
        return function(done) {
          // var bar = _this.$refs['progress-bar'];
          // bar.style.width = Math.floor(p * 100) + '%';
          // bar.innerHTML = Math.floor(p * 100) + '%';
          done();
        };
      };

      console.log(this.$refs);
      var file = this.$refs.file.files[0];

      console.log(file);

      if (!this.imgId) {
        this.$alert(this.$t('module.upload.comp_Failed') + 'code:2', this.$t('tips.tipsTitle'), {
          // //confirmButtonText: '确定',
        });
        return;
      }

      var key = '';
      var keyArr = [];
      if ('huyi-oss-headportrait' != bucket) {
        //非头像
        keyArr = ['image/'+this.folder, this.imgId, this.imgKey, new Date().getTime(), 'imgoss1', file.name.substr(file.name.lastIndexOf('.')).toLowerCase()];
        // key = '1_' + _this.imgId + '_' + _this.imgKey + '_' + new Date().getTime() + '_imgoss1_'+file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
      } else {
        // key = '1_' + _this.imgId + '_' + _this.imgKey + '_imgoss2_'+file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
        keyArr = ['image/'+this.folder, this.imgId, this.imgKey, new Date().getTime(), 'imgoss2', file.name.substr(file.name.lastIndexOf('.')).toLowerCase()];
      }

      key = keyArr.join('_');

      // var key = _this.$refs['object-key-file'].value.trim() || 'object';

      console.log('key', key);
      console.log(file.name + ' => ' + key);
      console.log(client);
      /*return */
      client
        .multipartUpload(key, file, {
          progress: progress
        })
        .then(res => {
          console.log('upload success: %j', res);
          console.log('list files', res.res.requestUrls[0]);

          // let myImgUrl = 'https://' + bucket + '.oss-cn-hongkong.aliyuncs.com/' + key;
					let myImgUrl = `https://${bucket}.${ossConfig.endpoint}/${key}`;
          //阿里云 图片处理
          let myImgUrl_suffix = '';
          if (!!this.autoOrient || !!this.autoResize) {
            myImgUrl_suffix = '?x-oss-process=image';
            //阿里云 根据图片信息自动旋转
            if (this.autoOrient) {
              myImgUrl_suffix = myImgUrl_suffix + '/auto-orient,1';
            }
            //阿里云 图片自动裁剪
            if (this.autoResize) {
              let autoW = '',
                autoH = '';
              if (!isNaN(parseInt(this.imgwidth))) {
                autoW = ',w_' + parseInt(this.imgwidth);
              }
              if (!isNaN(parseInt(this.imgheight))) {
                autoH = ',h_' + parseInt(this.imgheight);
              }
              // myImgUrl_suffix = myImgUrl_suffix+"/resize,limit_0"+autoW+""+autoH+",m_fill";
              //参考： https://help.aliyun.com/document_detail/44688.html
              // myImgUrl_suffix = myImgUrl_suffix+"/resize,limit_0"+autoW+""+autoH+",m_fill";//裁剪
              myImgUrl_suffix = myImgUrl_suffix + '/resize,limit_0' + autoW + '' + autoH + ',m_pad'; //不裁剪，等比缩放，填充透明
            }
          }
          let myImgUrlShow = myImgUrl + myImgUrl_suffix;
          console.log('myImgUrl', myImgUrl, myImgUrlShow);
          this.imgSuccessUrl = myImgUrlShow;

          console.log('imgSuccessUrl HyUpLoadImg');

          if (this.hasInit) {
            // setTimeout(function(){
            //   _this.initData();
            // },500)
          }

          console.log('imgSuccessUrl initData');
          //触发父组件方法
          // setTimeout(function(){
          // this.$emit('changeImgUrl', myImgUrlShow);
          this.$emit('changeImgUrl', myImgUrl);
          // },500);
          this.loading = false;
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
