
import OSS from 'ali-oss';

export const ossConfig = {
	// return {
		region: 'oss-cn-hongkong',
		// accessKeyId: 'LTAI5t9pUsVQBnwyGgeGGuc4',
		// accessKeySecret: 'XljA667qnWvCmtwchv0mfyQPevtUsw',
		//2023.05.19
		accessKeyId: 'LTAI5tMPbxqCUDpaxdiEWbqc',
		accessKeySecret: 'BYmS5ZcgCQYmM0p38SBtsvA7Exfg5c',
		// bucket: bucket,
		endpoint: 'oss-cn-hongkong.aliyuncs.com',
		// endpoint: 'hytx-oss-wh.oss-accelerate.aliyuncs.com',//加速
		secure: true //https
	// }
}

export const ossClient = (bucket) => {
	let config = ossConfig;
	config.bucket = bucket;
	return new OSS(config);
}