import Vue from 'vue'
import VueRouter from 'vue-router'
// const Vue = require('vue')
// const VueRouter = require('vue-router')

import store from '../vuex/store'

//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //这个样式必须引入

import AxiosPlugin from '../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

// import wxUtils from '../common/wxUtils.js'
// Vue.use(wxUtils)

import {
	MessageBox,
} from 'element-ui';

Vue.prototype.$confirm = MessageBox.confirm;

// import HelloWorld from '@/components/HelloWorld'
// import frame1 from '@/components/frame1'
// // import frame2 from '@/components/frame2'

// //路由懒加载
// // const HelloWorld = () => import('@/components/HelloWorld'], resolve)
// const frame2 = () => import('@/components/frame2'], resolve)


// import loginFrame from '@/components/Login/loginFrame'
// import login from '@/components/Login/login'
//登录注册
const loginFrame = resolve => require(['@/components/Login/loginFrame'], resolve)
// const Comp = resolve => require(['@/components//Comp.vue'], resolve)
const login = resolve => require(['@/components/Login/login'], resolve)
const register = resolve => require(['@/components/Login/register'], resolve)
const registerSuccess = resolve => require(['@/components/Login/registerSuccess'], resolve)
const forgotPassword = resolve => require(['@/components/Login/forgotPassword'], resolve)
// const registrationAgreement = resolve => require(['@/components/Login/registrationAgreement'], resolve)
//权限页面
const auth = resolve => require(['@/components/Login/auth'], resolve)
//主框架
// const MainFrame = resolve => require(['@/components/MainFrame'], resolve)

//找不到页面
const NotFound = resolve => require(['@/components/NotFound/NotFound'], resolve)


const StorageCenterFrame = resolve => require(['@/components/StorageCenter/StorageCenterFrame'], resolve)

// const InWarehouse = resolve => require(['@/components/StorageCenter/clp/InWarehouse'], resolve)
// const WarehouseFee = resolve => require(['@/components/StorageCenter/statement/WarehouseFee'], resolve)
// const WarehouseFeeCollect = resolve => require(['@/components/StorageCenter/statement/WarehouseFeeCollect'], resolve)
// const WarehouseShipments = resolve => require(['@/components/StorageCenter/shipments/WarehouseShipments'], resolve)
// // const WarehouseSku = resolve => require(['@/components/StorageCenter/WarehouseSku'], resolve)
// const WarehouseExchangeReturn = resolve => require(['@/components/StorageCenter/shipments/WarehouseExchangeReturn'], resolve)

//互易仓-版本2
// const WSkuInfo = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuInfo'], resolve)
// const WSkuInventories = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuInventories'], resolve)
// const WSkuRecord = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuRecord'], resolve)
// const WSkuSplit = resolve => require(['@/components/StorageCenter/WSkuProduct/WSkuSplit'], resolve)

// const WarehouseDropShipping = resolve => require(['@/components/StorageCenter/shipments/WarehouseDropShipping'], resolve)

// const ExceptionalRecord = resolve => require(['@/components/StorageCenter/ExceptionalThing/ExceptionalRecord'], resolve)

// const InventoryStatistics = resolve => require(['@/components/StorageCenter/statement/InventoryStatistics'], resolve)
// const InventoryStatisticsSkuGroup = resolve => require(['@/components/StorageCenter/statement/InventoryStatisticsSkuGroup'], resolve)

//打单明细
// const PriterOrderDetList = resolve => require(['@/components/StorageCenter/shipments/PriterOrderDetList'], resolve)

// const outBill = resolve => require(['@/components/StorageCenter/statement/outBill'], resolve)

//帮助文档
// const helpDoc = resolve => require(['@/components/Login/help/helpDoc'], resolve)
//亚马逊账号 帮助文档
const amazonHelpDoc = resolve => require(['@/components/Login/help/amazonHelpDoc'], resolve)
//网站地图
// const sitemap = resolve => require(['@/components/Login/sitemap/sitemap'], resolve)

//亚马逊授权
// const AmazonFrame = resolve => require(['@/components/Amazon/AmazonFrame'], resolve)
// const AmazonAuth = resolve => require(['@/components/Amazon/AmazonAuth'], resolve)
// const AmazonAuthList = resolve => require(['@/components/Amazon/AmazonAuthList'], resolve)

//亚马逊已授权列表
// const amazonUserList = resolve => require(['@/components/StorageCenter/amazon/amazonUserList'], resolve)
// //亚马逊订单
// const amazonOrder = resolve => require(['@/components/StorageCenter/amazon/amazonOrder'], resolve)

//用户代理
const agentUser = resolve => require(['@/components/StorageCenter/agent/agentUser'], resolve)

//账户
//充值记录
const RechargeRecord = resolve => require(['@/components/StorageCenter/account/RechargeRecord'], resolve)
//消费记录
const expenseRecord = resolve => require(['@/components/StorageCenter/account/expenseRecord'], resolve)
//账户信息
const accountInfo = resolve => require(['@/components/StorageCenter/account/accountInfo'], resolve)

//第三方订单
// const ThirdOrder = resolve => require(['@/components/StorageCenter/Thirdparty/ThirdOrder'], resolve)

//快递面单
const ExpressSheetList = resolve => require(['@/components/StorageCenter/ExpressSheet/ExpressSheetList'], resolve)
//快递面单明细
const ExpressSheetDetList = resolve => require(['@/components/StorageCenter/ExpressSheet/ExpressSheetDetList'], resolve)
const LgsTrackQuery = resolve => require(['@/components/StorageCenter/ExpressSheet/LgsTrackQuery'], resolve)

//QA 服务管理
const QARecList = resolve => require(['@/components/StorageCenter/QA/QARecList'], resolve)
const QARecAdd = resolve => require(['@/components/StorageCenter/QA/QARecAdd'], resolve)
const QARecProgress = resolve => require(['@/components/StorageCenter/QA/QARecProgress'], resolve)
//增值服务
const ValueAddServiceList = resolve => require(['@/components/StorageCenter/QA/ValueAddServiceList'], resolve)

//增值服务
const workOrderList = resolve => require(['@/components/StorageCenter/workOrder/workOrderList'],
	resolve)
const createWorkOrder = resolve => require(['@/components/StorageCenter/workOrder/createWorkOrder'], resolve)
// const createOrder = resolve => require(['@/components/StorageCenter/workOrder/createOrder'], resolve)



Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes: [{
			path: '*', //其他页面，强制跳转到找不到页面
			redirect: '/loginFrame/NotFound'
		},
		{
			path: '/',
			// name: 'auth',
			// component: auth,
			redirect: '/loginFrame/login'
		},

		{
			path: '/loginFrame',
			name: 'loginFrame',
			component: loginFrame,
			children: [{
					path: 'NotFound',
					name: 'NotFound',
					component: NotFound
				},{
					path: 'login',
					name: 'login',
					component: login
				},
				{
					path: 'register',
					name: 'register',
					component: register
				},
				{
					path: 'registerSuccess',
					name: 'registerSuccess',
					component: registerSuccess
				},
				{
					path: 'forgotPassword',
					name: 'forgotPassword',
					component: forgotPassword
				},
				{
					path: 'auth',
					name: 'auth',
					component: auth,
				},
				// {
				// 	path: 'helpDoc',
				// 	name: 'helpDoc',
				// 	component: helpDoc,
				// },
				{
					path: 'amazonHelpDoc',
					name: 'amazonHelpDoc',
					component: amazonHelpDoc,
				},
				// {
				// 	path: 'sitemap',
				// 	name: 'sitemap',
				// 	component: sitemap,
				// },
				
				// {
				// 	path: 'AmazonAuth',
				// 	name: 'AmazonAuth',
				// 	component: AmazonAuth,
				// },
				// {
				// 	path: 'AmazonAuthList',
				// 	name: 'AmazonAuthList',
				// 	component: AmazonAuthList,
				// },
				
			]
		},
		
		// {
		// 	path: '/AmazonFrame',
		// 	name: 'AmazonFrame',
		// 	component: AmazonFrame,
		// 	meta: {
		// 		isWh: true
		// 	},
		// 	children: [
		// 		{
		// 			path: 'AmazonAuth',
		// 			name: 'AmazonAuth',
		// 			component: AmazonAuth,
		// 		},
		// 		{
		// 			path: 'AmazonAuthList',
		// 			name: 'AmazonAuthList',
		// 			component: AmazonAuthList,
		// 		},
				
		// 	]
		// },


		{
			path: '/StorageCenterFrame',
			name: 'StorageCenterFrame',
			component: StorageCenterFrame,
			meta: {
				isWh: true
			},

			children: [

				// {
				// 	path: 'InWarehouse',
				// 	name: 'InWarehouse',
				// 	component: InWarehouse
				// },
				// {
				// 	path: 'WarehouseFee',
				// 	name: 'WarehouseFee',
				// 	component: WarehouseFee
				// },
				// {
				// 	path: 'WarehouseFeeCollect',
				// 	name: 'WarehouseFeeCollect',
				// 	component: WarehouseFeeCollect
				// },
				
				// {
				// 	path: 'WarehouseShipments',
				// 	name: 'WarehouseShipments',
				// 	component: WarehouseShipments
				// },
				// {
				// 	path: 'WarehouseExchangeReturn',
				// 	name: 'WarehouseExchangeReturn',
				// 	component: WarehouseExchangeReturn
				// },
				
				// {
				// 	path: 'WarehouseSku',
				// 	name: 'WarehouseSku',
				// 	component: WarehouseSku
				// },
				// {
				// 	path: 'WSkuInfo',
				// 	name: 'WSkuInfo',
				// 	component: WSkuInfo
				// },
				// {
				// 	path: 'WSkuInventories',
				// 	name: 'WSkuInventories',
				// 	component: WSkuInventories
				// },
				// {
				// 	path: 'WSkuRecord',
				// 	name: 'WSkuRecord',
				// 	component: WSkuRecord
				// },
				// {
				// 	path: 'WSkuSplit',
				// 	name: 'WSkuSplit',
				// 	component: WSkuSplit
				// },
				
				
				// {
				// 	path: 'WarehouseDropShipping',
				// 	name: 'WarehouseDropShipping',
				// 	component: WarehouseDropShipping
				// },
				// {
				// 	path: 'PriterOrderDetList',
				// 	name: 'PriterOrderDetList',
				// 	component: PriterOrderDetList
				// },
				
				{
					path: 'workOrderList',
					name: 'workOrderList',
					component: workOrderList
				},
				{
					path: 'createWorkOrder',
					name: 'createWorkOrder',
					component: createWorkOrder
				},
				// {
				// 	path: 'createOrder',
				// 	name: 'createOrder',
				// 	component: createOrder
				// },
				// {
				// 	path: 'ExceptionalRecord',
				// 	name: 'ExceptionalRecord',
				// 	component: ExceptionalRecord
				// },
    //     {
    //     	path: 'InventoryStatistics',
    //     	name: 'InventoryStatistics',
    //     	component: InventoryStatistics
    //     },
				// {
				// 	path: 'InventoryStatisticsSkuGroup',
				// 	name: 'InventoryStatisticsSkuGroup',
				// 	component: InventoryStatisticsSkuGroup
				// },
				// {
				// 	path: 'outBill',
				// 	name: 'outBill',
				// 	component: outBill
				// },
				
        {
        	path: 'agentUser',
        	name: 'agentUser',
        	component: agentUser
        },
				// {
				// 	path: 'amazonUserList',
				// 	name: 'amazonUserList',
				// 	component: amazonUserList
				// },
				// {
				// 	path: 'amazonOrder',
				// 	name: 'amazonOrder',
				// 	component: amazonOrder
				// },
				{
					path: 'RechargeRecord',
					name: 'RechargeRecord',
					component: RechargeRecord
				},
				{
					path: 'expenseRecord',
					name: 'expenseRecord',
					component: expenseRecord
				},
				{
					path: 'accountInfo',
					name: 'accountInfo',
					component: accountInfo
				},
				// {
				// 	path: 'ThirdOrder',
				// 	name: 'ThirdOrder',
				// 	component: ThirdOrder
				// },
				{
					path: 'ExpressSheetList',
					name: 'ExpressSheetList',
					component: ExpressSheetList
				},
				{
					path: 'ExpressSheetDetList',
					name: 'ExpressSheetDetList',
					component: ExpressSheetDetList
				},
				{
					path: 'LgsTrackQuery',
					name: 'LgsTrackQuery',
					component: LgsTrackQuery
				},
				{
					path: 'QARecList',
					name: 'QARecList',
					component: QARecList,
					meta: {
						isMenu: true
					}
				},
				{
					path: 'QARecAdd',
					name: 'QARecAdd',
					component: QARecAdd
				},
				{
					path: 'QARecProgress',
					name: 'QARecProgress',
					component: QARecProgress
				},
				{
					path: 'ValueAddServiceList',
					name: 'ValueAddServiceList',
					component: ValueAddServiceList,
					meta: {
						isMenu: true
					}
				},
				



			]
		},

	],
	//滚动行为
	scrollBehavior(to, from, savedPosition) {
		// return 期望滚动到哪个的位置
		if (savedPosition) { //浏览器前进后退
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});


// 加载进度条，简单配置
NProgress.inc(0.2)
NProgress.configure({
	easing: 'ease',
	speed: 500,
	showSpinner: false
})

// router.beforeEach((to,from,next) => {
//   NProgress.start()
//   next()
// })
//
// router.afterEach(() => {
//   NProgress.done()
// })

router.beforeEach((to, from, next) => {
	// 加载进度条
	NProgress.start();

	// console.log('beforeEach')

	// console.log('beforeEach', Vue.prototype);
	// console.log('process.env.VERSION', process.env.VERSION)
	/*百度统计,只有线上启用统计*/
	// if (!store.state.isTestDev) {
	//   setTimeout(() => {
	//     var _hmt = _hmt || [];
	//     (function() {
	//       //每次执行前，先移除上次插入的代码
	//       document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
	//       var hm = document.createElement("script");
	//       hm.src = "https://hm.baidu.com/hm.js?a58bcdb0a75a483d975d78044ee28939";
	//       hm.id = "baidu_tj";
	//       var s = document.getElementsByTagName("script")[0];
	//       s.parentNode.insertBefore(hm, s);
	//       // console.log('afterEach',document.getElementById('baidu_tj'));
	//     })();
	//   }, 0);
	// }

	// console.log(Vue.prototype);
	// console.log($axios);
	//判断版本-不需要，防止浏览器的缓存，nginx已经做处理
	// Vue.prototype.$http.get('/static/version.json?_=' + Math.random()).then(response => {
	//   console.log('判断版本', response);
	//   if (200 == response.status) {
	//     if (process.env.VERSION !== response.data.version) {
	//       window.location.reload(true);
	//       console.log('判断版本 不一样');
	//       //加载进度条结束
	//       NProgress.done();
	//       return;
	//     }
	//     // next();
	//   }
	// }).catch(err => {
	//   console.error(err);
	//   // next();
	// });

	// console.log('store', store);

	//刷新浏览器会执行此方法
	//更新最新登录信息
	// store.getters.updataCacheUserInfo();

	// console.log('this',this.a.app);
	// console.log('this',this.a.app.$store.getters.getUserInfo);
	// console.log('getUserInfo', store.getters.getUserInfo);
	// console.log('getRole', store.getters.getRole);
	// console.log('to', to);
	// // console.log('from',from);
	// // console.log('next',next);
	// console.log('to.matched', to.matched);
	// console.log('store.getters.getUserInfo', store.getters.getUserInfo);
	let userInfo = store.getters.getUserInfo;

	let showTips = store.getters.getLangMsg;

	// 权限判断
	// //官方写法
	if (to.matched.some(record =>
			record.meta.isUser ||
			record.meta.isWh)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		// console.log('to.matched.some(record =>record.meta.isUser');
		if (!userInfo || !userInfo.id) {
			// console.log('111该操作需要进行登录，是否立即去登录?');
			Vue.prototype.$confirm(showTips.noLogin, showTips.title, {
				// //confirmButtonText: '确定',
				// //cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				next({
					path: '/loginFrame/login',
					// query: { redirect: to.fullPath }
				});
			}).catch(() => {
				// window.location.reload(true);
				next(false);
				//加载进度条结束
				NProgress.done();
			});
			// next(false);
			//加载进度条结束
			NProgress.done();
			return;
		} else {
			// next();
			// console.log('2222该操作需要进行登录，是否立即去登录?');
		}
	} else {
		// next();
	}
	// console.log('22222222', store.getters.getUserInfo);
	/*if (to.matched.some(record => record.meta.isUser && !store.getters.getUserInfo || !store.getters.getUserInfo.id)) {
	  next({
	    path: '/loginFrame/login',
	    // query: { redirect: to.fullPath }
	  })
	} else*/
	console.log('权限认证');
	//合伙人扩展权限，有卖家中心的权限
	// if (to.matched.some(record => record.meta.isParnterAdd && record.meta.enterpriseState && '2' === userInfo
	// 		.isParnter)) {
	// 	next();
	// }
	// else if (to.matched.some(record => record.meta.isParnter && '2' !== userInfo.isParnter)) {
	//   Vue.prototype.$confirm(showTips.noParnter, showTips.title, {
	//     // //confirmButtonText: '确定',
	//     // //cancelButtonText: '取消',
	//     type: 'warning'
	//   }).then(() => {
	//     next({
	//       path: '/UserCenterFrame/UserAuthentication',
	//       // query: { redirect: to.fullPath }
	//     })
	//   }).catch(() => {
	//     // window.location.reload(true);
	//     next(false);
	//     //加载进度条结束
	//     NProgress.done();
	//   });
	//   // return;
	// } 
	// else if (to.matched.some(record => record.meta.enterpriseState && '2' !== userInfo.enterpriseState)) {

	//   Vue.prototype.$confirm(showTips.noEn, showTips.title, {
	//     // //confirmButtonText: '确定',
	//     // //cancelButtonText: '取消',
	//     type: 'warning'
	//   }).then(() => {
	//     next({
	//       path: '/UserCenterFrame/UserAuthentication',
	//     })
	//   }).catch(() => {
	//     // window.location.reload(true);
	//     next(false);
	//     //加载进度条结束
	//     NProgress.done();
	//   });
	//   // return;
	// } 
	// else 
	if (to.matched.some(record => record.meta.isWh && '1' !== userInfo.isWh)) {

		Vue.prototype.$confirm("您还没有互易仓权限，去申请吗？", showTips.title, {
			// //confirmButtonText: '确定',
			// //cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			next({
				path: '/loginFrame/auth',
			})
		}).catch(() => {
			// window.location.reload(true);
			next(false);
			//加载进度条结束
			NProgress.done();
		});
		// Vue.prototype.$confirm("您还没有互易仓权限，去申请吗？", '提示', {
		//                 // confirmButtonText: '去升级',
		//                 // //cancelButtonText: '取消',
		//                 type: 'warning'
		//               }).then(() => {
		//                 next({
		//                   path: '/UserCenterFrame/StorageAuthentication',
		//                 });
		//                 // next(false);
		//               }).catch(() => {
		//                 next(false);
		//               });
		// return;
	} else {
		next() // 确保一定要调用 next()
	}


	//判断是否存在 userInfo 内数据是否都存在，否则重新登录
	// if(to.path === '/Login'], resolve){
	//   console.log("此为登录页");
	//   next();
	// } else {
	//   let UserInfo = stores.getters.getUserInfo;
	//   // let UserInfo = stores.state.login.userInfo;
	//   console.log(UserInfo);
	//   if(UserInfo.userCode
	//     && UserInfo.password
	//     && UserInfo.token){
	//     // console.log("存在登录信息，正在跳转");
	//     next();
	//   } else {
	//     // console.log("登录失效，跳转到登录页");
	//     next({
	//       path: '/Login',
	//       query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
	//     })
	//   }
	// }
})

router.afterEach((to, from) => {
	NProgress.done();
	window.scrollTo(0, 0);
	//初始化微信分享
	// Vue.prototype.$wxUtils.initWxConfig(1);
	// if (to.matched.some(record =>record.meta.hasWxShare) {

	// 	})

	// if (!to.matched.some(record => record.meta.hasWxShare)){ // 判断是否需要公共的微信分享
	// 	//初始化微信分享
	// 	Vue.prototype.$wxUtils.initWxConfig(1);
	// }
	// else {
	// next();
	// }

});

export default router
