const cn = {
	Storage: {
		common: {
			title: '互易仓',
			title2: '互易物流',
			Logout: "退出"
		},
		nav: {
			product: "产品管理", //
			SKU_inventories: "SKU库存", //
			SKU_information: "SKU创建", //
			SKU_water: "SKU流水", //
			SKU_split: "SKU拆分", //
			// prediction : "预报",//
			shipments: "仓库管理", //
			My_packing_list: "入库预报", //
			My_invoice: "转运", //
			A_undertakes_to: "一件代发", //
			report: "仓库报表", //
			My_storage_fee: "我的仓储费", //
			prox: "代理管理",
			order: "订单管理",
			account: "资金&账户",
			workOrder: "工单管理",
		},
		tableColumn: {
			no: "序号", // 序号
			remark: "备注", // 备注
			operation: "操作", // 操作
		},
		skuInfo: {
			Add_SKU: "新建产品", // 添加SKU
			Import_SKU: "批量导入", // 导入SKU

			SKU_detail: "SKU详情", //SKU详情

			SKU_information: "SKU信息", // SKU信息

			cate_placeholder: "选择或搜索商品类目", // 选择或搜索商品类目
			cate_tips: "支持关键字模糊搜索", // 支持关键字模糊搜索
			pic_size_tips: "建议上传尺寸不小于100*100的图，", // 建议上传尺寸不小于100*100的图，
			pic_size_type: "支持png、jpg等格式，大小建议不超过500k;", // 支持png、jpg等格式，大小建议不超过500k;
			sku_tips: "只能是数字字母下划线，不超过30字符", // 只能是数字字母下划线，不超过30字符

			category: "产品分类", // 产品分类
			SKU_title: "名称", // SKU名称
			Chinese_title: "中文名称", // SKU中文标题
			English_title: "英文名称", // SKU英文标题
			Chinese: "中文",
			English: "英文",
			SKU_pictures: "SKU图片", // SKU图片
			price: "报关价值", // 价值
			weight: "重量", // 重量
			unit: "单位", // 重量/体积单位
			size: "尺寸", //尺寸
			length: "长", // 长度
			width: "宽", // 宽度
			height: "高", // 高度
			length_width_height: "长*宽*高", //长*宽*高
			safety_stock: "安全库存", // 安全库存

			warehouse: "仓库", // 仓库

			product_information: "产品信息", //产品信息
			Specification_information: "规格信息", //规格信息

			on_hand_inventory: "现有库存", // 现有库位库存
			Current_locked_inventory: "锁定库存", // 当前锁定库存
			Current_available_stock: "有效库存", // 当前有效库存
			unit_price: "单价", // 单价

			date: "时间", // 时间
			quantity: "数量", // 数量
			number: "单号", // 单号
			type: "类型", // 类型
			explain: "说明", // 说明
			sku_explain:"SKU建议按照'数字'、'字母'、'-'、'_'组合,不超过20个字符,且保持唯一",
			sku_export_img_explain:"导出全部时，不包含SKU CODE一维码图片；如需导出一维码图片，请导出当前页；",
		},
		packing_list: {
			status: "状态", //状态
			_no: "柜号/跟踪号/货物单号", // 货柜号
			Create: "创建装箱单", // 创建装箱单
			Import: "导入装箱单", // 导入装箱单
			Departure_port: "出发港口", //出发港口
			arrive_port: "到达港口", //到达港口
			Departure_Date: "出发日期", // 出发日期
			arrive_Date: "到达时间", // 到达时间
			Type: "装箱类型", // 装箱单类型
			quantity: "装箱件数", // 装箱件数
			Check_number: "复核件数", // 复核件数
			attachment: "附件", // 附件

			shipments: "发货",

			add: "增加", //增加

			basic_information: "基本信息", // 基本信息
			detail: "明细", // 明细
			packing_information: "装箱信息", // 装箱信息
			Forwarder_guests: "货代客人", // 货代客人

			Other: "其他", // 其他

			All: "全部", // 全部
			manuscript: "草稿", // 草稿
			// not_receive:"未收货",// 未收货
			shipped: "未入库", // 已发货
			received: "已入库", // 已收货

			sure_shipment: "确定发货吗?", // 确定发货吗?
			
			carton_spec:"外箱规格(当一箱一件时，外箱规格可不填)",
		},
		invoice_list: {
			delivery_number: "发货编号", //:发货编号
			Create_invoice: "创建发货单", //创建发货单
			warehouse_umber: "仓库编号", // 仓库编号
			out_car_Type: "出车方式", // 出车方式
			Expected_delivery_date: "预计到车日期", // 预计到车日期
			Delivery_Warehouse_Number: "发货仓库编号", //发货仓库编号
			Submit_instructions: "提交指令", //提交指令
			Download_lading_bill: "下载提货单", //下载提货单
			Import_SKU_details: "导入SKU明细", // 导入SKU明细
			arrive_storehouse: "到达仓库", // 到达仓库
			FBA_number: "FBA入库申请号", // FBA入库申请号
			Arrive_address: "到达区域", // 到达区域
			Arrive_detailed_address: "到达详细地址", // 到达详细地址
			Volume: "体积(长*宽*高)", // 体积(长*宽*高)
			Add_SKU_manually: "手动新增SKU", // 手动新增SKU

			All: "全部", //全部
			manuscript: "草稿", // 草稿
			submitted: "已提交", // 已提交
			Picking: "拣货中", // 拣货中
			Picking_complete: "拣货完成", // 拣货完成
			play_pack: "打托中", // 打托中
			For_outbound: "待出库", // 待出库
			Have_outbound: "已出库", // 已出库

			submit_sure: "确定 提交发货指令 吗?", //'确定 提交发货指令 吗?'
		},
		DropShipping: {
			Numbers: "单号", //Numbers单号
			Import: "导入代发单", // 导入代发单
			Batch_submission: "批量提交", // 批量提交
			quantity: "代发数量", // 代发数量

			no_SKU: "没有SKU", // 没有SKU
			date: "日期", // 日期
			place_dispatch: "发货地", // 发货地
			place_receipt: "收货地", // 收货地
			Addressee_name: "收件人名称", // 收件人名称
			company: "公司", //公司
			contact_way: "联系方式", //联系方式
			Address_1: "地址1", // 地址1
			Address_2: "地址2", // 地址2
			city: "城市", // 城市
			State: "州/省", // 州/省
			postal_code: "邮编", // 邮编
			country: "国家", // 国家
			submit: "提交", // 提交

			All: "全部", // 全部
			manuscript: "草稿", // 草稿
			submitted: "已提交", //已提交
			checked: "已审核", // 已审核
			Picking: "拣货中", // 拣货中
			shipped: "已发货", //已发货
			To_extract: "待提取", //待提取
			Already_extracted: "已提取", //已提取
			To_recall_returns: "待召回|退货", //待召回|退货
			suspend: "拦截", //暂停
			canceled: "已取消", //已取消

			sel_tips_1: "请先选择要操作的数据", // 请先选择要操作的数据
			sel_tips_2: "所有选中为草稿状态才可以提交", // 所有选中为草稿状态才可以提交~
			sure_submit_1: "确定提交", //Are you sure to submit 12 pieces of data?//确定提交
			sure_submit_2: "条数据吗?", //pieces of data//条数据吗?
			Parsing_failed: "解析失败，数据量过大", // 解析失败，数据量过大
		},
		whFee: {
			Customer_No: "客户编号", //客户编号
			Fee_Type: "费用类型", //费用类型
			Fee: "费用", //费用
			Fee_description: "费用描述", //费用描述
			quantity: "件数", //件数
			toll_collection_manner: "收费方式", //收费方式
			billing_date: "账单日期", //账单日期
		},
		chart:{
			'DialogInStSkuCharts_1':'SKU变化趋势',
			'DialogInStSkuCharts_2':'库存',
			'DialogInStSkuCharts_3':'总体积',
			'DialogInStSkuCharts_4':'时间',
			
			'OrderStatisticsByMon_1':'订单按月统计',
			'OrderStatisticsByMon_2':'订单按日统计',
			
			'OrderByCharts_1':'一件代发',
			'OrderByCharts_2':'自提单',
			'OrderByCharts_3':'时间',
		}



	}


}

export default cn
