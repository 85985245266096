<template>
  <div style="display: inline-block;">
    <div v-if="!!srcShow && ''!=srcShow && '0'!=srcShow && 'null'!=srcShow">
      <img :src="srcShow+myImgUrl_suffix" :width="width" :height="height" />
    </div>
    <div v-else>
      <div class="noImgContent" :style="{width:widthS,height:heightS}">
        <!-- <img src="../../assets/images/common/upimg.png" :width="'40%'" :height="'auto'" /> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {

  name: 'ImgShow',

  props: {
    src: {
      type: String,
      // required: true
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    autoOrient: {
      type: Boolean,
    },
    autoResize: {
      type: Boolean,
    },
  },
  data() {
    return {
      // dialogFormVisible: false,
      widthS: '100%',
      heightS: '100%',
      srcShow: '',
      myImgUrl_suffix:"",
    }
  },
  watch: {
      src: function (newVal, oldVal) {
        this.initData();
      }
    },
  created() {
    console.log("created");
  },
  mounted() {
    console.log('monted');
    this.initData();

  },
  methods: {
    initData() {
      if(!isNaN(parseInt(this.width))){
        this.widthS = this.width+'px';
      }
      if(!isNaN(parseInt(this.height))){
        this.heightS = this.height+'px';
      }
      //阿里云 图片处理
      // let _this = this;
        let myImgUrl_suffix = "";
        if(!!this.autoOrient || !!this.autoResize){
          myImgUrl_suffix = "?x-oss-process=image"
          //阿里云 根据图片信息自动旋转
          if(!!this.autoOrient){
            myImgUrl_suffix = myImgUrl_suffix+"/auto-orient,1";
          }
          //阿里云 图片自动裁剪
          if(!!this.autoResize){
            let autoW = '',autoH = '';
            if(!isNaN(parseInt(this.width))){
              autoW = ",w_"+parseInt(this.width);
            }
            if(!isNaN(parseInt(this.height))){
              autoH = ",h_"+parseInt(this.height);
            }
				//参考： https://help.aliyun.com/document_detail/44688.html
            // myImgUrl_suffix = myImgUrl_suffix+"/resize,limit_0"+autoW+""+autoH+",m_fill";//裁剪
				myImgUrl_suffix = myImgUrl_suffix+"/resize,limit_0"+autoW+""+autoH+",m_pad";//不裁剪，等比缩放，填充透明
          }
        }
        
        this.myImgUrl_suffix = myImgUrl_suffix;
        this.srcShow = this.src;
    },
    //切换显示tip
    // showImg(e){
    //   e.preventDefault();
    //   e.stopPropagation();
    //   this.dialogFormVisible = true;
    // },
  },

}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/

</style>
