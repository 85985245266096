/*全局组件*/
import Vue from 'vue'
// const Vue = require('vue')
import ImgShow from './ImgShow.vue'

const imgShow = {
  install:function (Vue) {
    Vue.component('imgShow',ImgShow)
  }
}

export default imgShow

