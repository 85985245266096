const en = {
	Storage: {
		common: {
			title: 'HYTX Storage',
			title2: 'HYTX Logistics',
			Logout: "Logout"
		},
		nav: {
			product: "Product", //
			SKU_inventories: "SKU inventories", //
			SKU_information: "SKU Create", //
			SKU_water: "SKU statement", //
			SKU_split: "SKU Split", //
			// prediction : "Inbound",//
			shipments: "Warehouse", //
			My_packing_list: "Inbound Forecast", //
			My_invoice: "Transshipment", //
			A_undertakes_to: "Drop shipping", //
			report: "Report", //
			My_storage_fee: "My storage fee", //
			prox: "Agent",
			order: "Order",
			account: "Funds & Accounts",
			workOrder: "Workorder",
		},
		tableColumn: {
			no: "No.", // 序号
			remark: "remark", // 备注
			operation: "operation", // 操作
		},
		skuInfo: {
			Add_SKU: "Add Product", // 添加SKU
			Import_SKU: "Bulk Import", // 导入SKU

			SKU_detail: "SKU detail", //SKU详情

			SKU_information: "SKU information", // SKU信息

			cate_placeholder: "Select or search for product categories", // 选择或搜索商品类目
			cate_tips: "Supports keyword fuzzy search", // 支持关键字模糊搜索
			pic_size_tips: "It is recommended to upload an image with size no less than 100*100.", // 建议上传尺寸不小于100*100的图，
			pic_size_type: "Support PNG, JPG and other formats, the size of the recommended not more than 500K;", // 支持png、jpg等格式，大小建议不超过500k;
			sku_tips: "Only alphanumeric underscores, no more than 30 characters", // 只能是数字字母下划线，不超过30字符

			category: "category", // 产品分类
			SKU_title: "Name", // SKU名称
			Chinese_title: "Chinese Name", // SKU中文标题
			English_title: "English Name", // SKU英文标题
			Chinese: "Chinese",
			English: "English",
			SKU_pictures: "SKU pictures", // SKU图片
			price: "price", // 价值
			weight: "weight", // 重量
			unit: "unit", // 重量/体积单位
			size: "size", //尺寸
			length: "length", // 长度
			width: "width", // 宽度
			height: "height", // 高度
			length_width_height: "L*W*H", //长*宽*高
			safety_stock: "safety stock", // 安全库存

			warehouse: "warehouse", // 仓库

			product_information: "Product information", //产品信息
			Specification_information: "Specification information", //规格信息

			on_hand_inventory: "on-hand inventory", // 现有库位库存
			Current_locked_inventory: "Current locked inventory", // 当前锁定库存
			Current_available_stock: "Current available stock", // 当前有效库存
			unit_price: "unit price", // 单价

			date: "date", // 时间
			quantity: "quantity", // 数量
			number: "number", // 单号
			type: "type", // 类型
			explain: "explain", // 说明
			sku_explain:"The SKU recommends a combination of 'number', 'letter', '-', '_', no more than 20 characters, and keep it unique",
			sku_export_img_explain:"When exporting all, SKU CODE one-dimensional code picture is not included; If you need to export one-dimensional code images, please export the current page;",
		},
		packing_list: {
			status: "status", //状态
			_no: "No.", // 货柜号
			Create: "Create", // 创建装箱单
			Import: "Import", // 导入装箱单
			Departure_port: "Departure port", //出发港口
			arrive_port: "arrive port", //到达港口
			Departure_Date: "Departure Date", // 出发日期
			arrive_Date: "arrive Date", // 到达时间
			Type: "Type", // 装箱单类型
			quantity: "quantity", // 装箱件数
			Check_number: "Check number", // 复核件数
			attachment: "attachment", // 附件

			shipments: "shipments", //发货

			add: "Add", //增加

			basic_information: "basic information", // 基本信息
			detail: "detail", // 明细
			packing_information: "packing information", // 装箱信息
			Forwarder_guests: "Forwarder guests", // 货代客人

			Other: "Other", // 其他

			All: "All", // 全部
			manuscript: "manuscript", // 草稿
			// not_receive:"not receive",// 未收货
			shipped: "Not In", // 已发货 未入库
			received: "Been In", // 已收货 已入库

			sure_shipment: "Are you sure of shipment?", // 确定发货吗?
			carton_spec:"Specifications of the outer case (when the case is one piece, the specifications of the outer case are not required)",
		},
		invoice_list: {
			delivery_number: "delivery number", //:发货编号
			Create_invoice: "Create invoice", //创建发货单
			warehouse_umber: "warehouse number", // 仓库编号
			out_car_Type: "out car Type", // 出车方式
			Expected_delivery_date: "Expected delivery date", // 预计到车日期
			Delivery_Warehouse_Number: "Delivery Warehouse Number", //发货仓库编号
			Submit_instructions: "Submit instructions", //提交指令
			Download_lading_bill: "Download lading bill", //下载提货单
			Import_SKU_details: "Import SKU details", // 导入SKU明细
			arrive_storehouse: "arrive storehouse", // 到达仓库
			FBA_number: "FBA number", // FBA入库申请号
			Arrive_address: "Arrive address", // 到达区域
			Arrive_detailed_address: "Arrive detailed address", // 到达详细地址
			Volume: "Volume (length * width * height)", // 体积(长*宽*高)
			Add_SKU_manually: "Add SKU manually", // 手动新增SKU

			All: "All", //全部
			manuscript: "manuscript", // 草稿
			submitted: "submitted", // 已提交
			Picking: "Picking", // 拣货中
			Picking_complete: "Picking complete", // 拣货完成
			play_pack: "play pack", // 打托中
			For_outbound: "For outbound", // 待出库
			Have_outbound: "Have outbound", // 已出库

			submit_sure: "Are you sure to submit the shipping order?", //'确定 提交发货指令 吗?'
		},
		DropShipping: {
			Numbers: "Numbers", //Numbers单号
			Import: "Import order", // 导入代发单
			Batch_submission: "Batch submission", // 批量提交
			quantity: "quantity", // 代发数量

			no_SKU: "no SKU", // 没有SKU
			date: "date", // 日期
			place_dispatch: "place of dispatch", // 发货地
			place_receipt: "place of receipt", // 收货地
			Addressee_name: "Addressee name", // 收件人名称
			company: "company", //公司
			contact_way: "contact way", //联系方式
			Address_1: "Address 1", // 地址1
			Address_2: "Address 2", // 地址2
			city: "city", // 城市
			State: "State", // 州/省
			postal_code: "postal code", // 邮编
			country: "country", // 国家
			submit: "submit", // 提交

			All: "All", // 全部
			manuscript: "manuscript", // 草稿
			submitted: "submitted", //已提交
			checked: "checked", // 已审核
			Picking: "Picking", // 拣货中
			shipped: "shipped", //已发货
			To_extract: "To extract", //待提取
			Already_extracted: "Already extracted", //已提取
			To_recall_returns: "To recall | returns", //待召回|退货
			suspend: "intercept", //暂停
			canceled: "canceled", //已取消

			sel_tips_1: "Select the data you want to operate on first", // 请先选择要操作的数据
			sel_tips_2: "All selected drafts can be submitted", // 所有选中为草稿状态才可以提交~
			sure_submit_1: "Are you sure to submit", //Are you sure to submit 12 pieces of data?//确定提交
			sure_submit_2: "pieces of data?", //pieces of data//条数据吗?
			Parsing_failed: "Parsing failed, data volume is too large", // 解析失败，数据量过大
		},
		whFee: {
			Customer_No: "Customer No", //客户编号
			Fee_Type: "Fee Type", //费用类型
			Fee: "Fee", //费用
			Fee_description: "Fee description", //费用描述
			quantity: "quantity", //件数
			toll_collection_manner: "toll collection manner", //收费方式
			billing_date: "billing date", //账单日期
		},
		chart:{
			'DialogInStSkuCharts_1':'SKU Change trend',
			'DialogInStSkuCharts_2':'stock',
			'DialogInStSkuCharts_3':'TotalVolume',
			'DialogInStSkuCharts_4':'date',
			
			'OrderStatisticsByMon_1':'Orders are counted monthly',
			'OrderStatisticsByMon_2':'Orders are counted daily',
			
			'OrderByCharts_1':'drop shipping',
			'OrderByCharts_2':'Self-delivery',
			'OrderByCharts_3':'date',
		}

	}
}

export default en
