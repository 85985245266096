/*全局组件*/
import Vue from 'vue'
import HyUpLoadImg from './HyUpLoadImg.vue'

const hyUpLoadImg = {
  install:function (Vue) {
    Vue.component('hyUpLoadImg',HyUpLoadImg)
  }
}

export default hyUpLoadImg

