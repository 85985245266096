import enLocale from 'element-ui/lib/locale/lang/en'
// import enUserCenter from './UserCenter/en.js'
import enStorage from './Storage/en.js'

// import enBuyerCenter from './BuyerCenter/en.js'
// import enSellerCenter from './SellerCenter/en.js'
// import enPartnerCenter from './PartnerCenter/en.js'
// import enCommercialTenant from './CommercialTenant/en.js'
import locale_en from '../../../i18n_locales/en.js'

const en = {
	addressManager: {
		title: 'address administration'
	},
	message: {
		'hello': 'hello, world',
	},
	meta: {
		main: "HYTX-WMS-Label",
	},
	tips: {
		loading: 'loading...',
		Submitting: "Submitting..",
		tipsTitle: 'Tips',

		errorData: "Sorry, the request data is incorrect.",
		submitSuccess: "Successful submission!",
		successfullyDelete: "successfully delete",

		requestErrorData: "Sorry, the request data is wrong. Please try again.",

		checkSubmitData: "Please check if there is any unfilled or incorrect information in the data.",

		submitError: "Submission failed, please try again",
		submitRequestError: "Submission failed, please try again!",

		queryErrorFailed: "Sorry, the inquiry failed",
		queryRequestFailed: "Sorry, the inquiry failed！",

		// successAddShopCart:"Added to shopping cart successfully",
		// ErrorAddShopCart:"Failed to add to shopping cart, please try again",
		// RequestFailedAddShopCart:"Failed to add to shopping cart, please try again!",

		no_data: "no data",
		no_null: "not available",
		Please_Enter: "please enter",
		confirmSubmit: "Are you sure to submit it?",
		Please_Sel: "please select",
		Please_Sel_date: "Please select date",

		no_match_label: "There's no matching list",
		no_sel_lgs_no_use: 'The currently selected KeyCode is not available',
		no_sel_express_company_order: 'Unknown delivery company and tracking number',

		noLoginUserMsg: "Must be logged in to operate. Do you log in immediately?",
		// noEnMsg: "Must be an enterprise to perform this operation. Is it certified as an enterprise?",
		// noLoginMsg: "You must be an enterprise to do this. Are you logged in?",
		// noShopMsg:"Business authentication can only be carried out after passing the enterprise authentication",
		// authChangeLogin:"The authentication information has changed, please log in again",
		long_TipsActionHotTable_1: "1. Download the template and import it directly to Excel; Operate with Excel, copy and paste data;",
		long_TipsActionHotTable_1_1: "1. Operate with Excel; You can also copy and paste data in Excel.",
		long_TipsActionHotTable_2: "2. the right mouse button can add, delete, edit the table;",
		long_TipsActionHotTable_3: "3. Delete blank lines when saving; Double-click to select or select data.",

		long_accountInfo: "The prepaid account is currently offline recharge, please keep the transfer record after the transfer is completed; Apply online [to recharge], our company will be the first time after receiving the application.",
		long_WarehouseShipments_1: 'Only if all of the state data is committed can it be manipulated',
		long_WarehouseDropShipping_1: 'Only the status of draft, submitted, approved can be submitted to make orders ',

		long_InWarehouse_1: 'Only all the data are not stored in the warehouse can be operated ',
	},
	common: {
		HYTX: 'HYTX',
		HeadPortrait: 'Head portrait',

		Commodity_types: "Commodity Types",
		All: "All",

		Storage: 'Storage',
		Other: 'Other',
	},
	FormMsg: {
		placeholder_commodity_name: "Up to 60 Chinese characters are supported for the title of the product. The content beyond the limit will no longer be displayed",
		Cancel: "Cancel",
		Sure: "OK",

		Save: "Save",
		Submit: "Submit",

		back: "Back",

		Modify: "Modify",
		Setting: "Setting",

		Open: "Open",
		Close: "Close",
		Refresh: "Refresh",

		Operation: "Operation", //操作
		Delete: "Delete", //删除
		Add: "Add", //添加
		Edit: "Edit", //编辑
		Select: "Select",
		// Modify:"Modify",//修改

		confirm_Delete: "Are you sure to delete it?",

		picture: "picture", //图片
		preview: "preview", //预览

		details_View: "details View", //查看明细
		upload_attachment: "upload attachment", //上传附件

		Mobile_phone: "Mobile phone",
		Verification_Code: "Verification Code",
		placeholder_Code: "Please enter the verification code.",
		Get_Code: "Get auth code",
		Sent: "Sent",
		seconds: "seconds",
		placeholder_must_pwd: "The password must be 6-18 bits and contain at least one letter and one digit.",
		placeholder_phone_msg: "Mobile phone verification code has been sent, please check!",

		Failed_captcha: "Failed to obtain captcha", //"获得验证码失败"
		error_captcha: "There was an error in the captcha", //验证码存在错误
		Password_inconsistency: "Password inconsistency", //密码不一致

		Please_Enter: "Please Enter",
		Please_select: "Please Select",
		Please_Enter_Sel: "Please enter or select",
		Please_upload: "Please Upload",
		Search: "Search",
		Query: "Query",

		ver_generation_card: "Please enter second generation id card",
		ver_number: "please enter number",
		ver_integer: "Please enter integer",
		ver_3_decimal: "Please keep up to three decimal places",
		ver_phone_number: "Please enter your mobile phone number",
		ver_place: "Please select the receiving city",

		Select_date: "Select date", //,"选择日期"
		Select_start_date: "Select start date", //,"选择开始日期"
		Select_end_date: "Select end date", //,"选择结束日期"
		Now: "Now", //今天
		yesterday: "Yesterday", //昨天
		A_Week_Ago: "A Week Ago", //一周前

		last_a_week: "last 7 days",
		last_a_mon: "last 30 days",
		last_three_mon: "last 90 days",

		Select_date_time: "Select date time", //,"选择日期"

		Select_data_msg: "Select the data to operate on first", //请先选择要操作的数据
		Select_long_pdfSplit: "Please enter the tracking number (one tracking number per line, and match in PDF order)",
		Select_long_whLabelFileUpload_1: "1. If the order has been completed offline express sheet generation, please upload the corresponding sheet here!",
		Select_long_whLabelFileUpload_2: "2. Order number and file name should be the same to match",
		Select_long_LgsFedexTrackQuery_1: "Multiple queries (one per row, up to 30)",
		Select_long_wh: 'Please select the repository first',
		Select_long_status: 'Please select status',
		Select_long_out_wh: "Please select the outbound warehouse",
		Select_long_goods_source: "Please select the source of the goods",
		Select_long_goods_transport: "Please select the goods to ship",
	},
	module: {
		HyAreaCodeSel: {
			"DefaMsg": 'Common Countries/Areas',
			"errorMsg": 'Mobile area code request failed',
		},

		HyCityCountyLinkage: {
			province: "province", //省份
			city: "city", //城市
			district_county: "district/county", //区/县
			warehouse: "warehouse", //仓库
			Failed_province_information: "Failed to obtain province information", //'获取省份信息失败'
			Failed_city_information: "Failed to obtain city information", //'获取城市信息失败'
			Failed_district_information: "Failed to obtain district information", //'获取区县信息失败'
			Failed_warehouse_information: "Failed to retrieve warehouse information", //'获取仓库信息失败'
		},
		upload: {
			Choose_File: "Choose File", //选取文件
			Upload_server: "Upload to server", //上传到服务器
			uploaded_over: "The size of the uploaded file cannot exceed", //上传文件大小不能超过 2M
			fail_upload: "fail to upload", //上传失败
			Please_upload_format: "Please upload files in appropriate format", //'请上传合适格式的文件'
			files_exceeded: "The number of files exceeded the limit", //'文件超出个数限制'
			Please_sel_file: "Please select the file first", //Please select the file first// 请先选择文件
			// Failed_district_information:"The size of the uploaded file cannot exceed",//'文件尺寸不能大于'
			Unknown_image: "Unknown image address", //'未知的图片地址'
			Unknown_file: "Unknown file", //this.$t('module.upload.Unknown_file')

			uploading: "uploading", //'正在上传'
			filename: "filename", //'文件名'
			Click_upload: "Click on the upload", //''点击上传
			open_error_img: "There is an error in opening the uploaded file. ", //''打开上传文件有误，
			open_browser_error_img: "The browser version is too low to support the file method, please upgrade the browser or contact customer service", //''浏览器版本过低不支持文件方法，请升级浏览器或联系客服
			comp_Failed: "Component loading error", //''组件加载有误，

			Import_success: 'Import success', //Import successthis.$t('module.upload.Import_success')

			Unknown_image: "Unknown image address", //''未知的图片地址
			Delete_picture: "Delete the picture", //删除图片
			sure_del_img: "Are you sure to delete this picture? Deletion will not be restored. Are you sure?", //确定删除此图片吗，删除将无法恢复。是否确定?
			Please_upload_format_img: "Please upload pictures in.png,.jpeg,.jpg,.gif format", //请上传 .png,.jpeg,.jpg,.gif格式的图片

			Select_Upload_Excel: "Upload Excel",
			Select_Excel: "Select Excel", //选择Excel
			Upload_preview: "Upload preview", //上传并预览
			Download_template: "Download template", //下载模板

			upload_el_wh_1: "notice",
			upload_el_wh_2: "up to upload",
			upload_el_wh_3: "file",
			upload_el_wh_4: "maximum",
			upload_el_wh_5: "file",

		},

	},

	unit: {

	},
	btn: {
		long_Export_excel: "Export excel",
	},
	topBar: {

		Logout: "Log Out",

		language: "Language",
		Chinese: "中文简体",
		English: "English",

		switchLangSuccess: "Switch Success",
	},
	home: {
		long_1: "Logistics Claim Notes",
		long_2: "All claims related to final logistics must be submitted to CS@hytxusa.com within 30 days after shipment;",
		long_3: "Claim forms and procedures can be obtained from CS@hytxusa.com;",
		long_4: "The tail logistics that fail to apply after the time limit will not be able to claim compensation from HYTX;"
	},
	register: {

		registerTitle: "Welcome to register",

		User_Registration: "User Registration",
		Mobile_phone: "Mobile phone",
		placeholder_phone: "Please enter your cell phone number.",
		China: "China",
		Verification_Code: "Verification Code",
		placeholder_Code: "Please enter the verification code.",
		Get_Code: "Get authentication code",
		seconds: "seconds",
		Password: "Password",
		Duplicate_password: "Duplicate password",
		Partner_phone: "Partner Mobile phone",
		Read_agree: "Read and agree",
		Registration_Agreement: "Registration Agreement",
		register: "register",
		account_msg: "Have you got a HYTX account?",
		Sign_in: "Log In",
		customer_service_msg: "If you have any questions, please contact customer service.",
		Telephone: "Telephone",
		Online_Service: "Online Service",
		Registration_Agreement: "Registration Agreement",
		placeholder_areaCode: "Please choose the international area code of mobile phone.",
		placeholder_must_phone: "Must be a cell phone number",
		placeholder_must_pwd: "The password must be 6-18 bits and contain at least one letter and one digit.",
		placeholder_phone_msg: "Mobile phone verification code has been sent, please check!",
		Please_Enter: "Please Enter",

		ver_re_pwd: "Keep your password the same.",

		register_was_successful: "Successful registration!",

		loginTitle: "Welcome to login",

		Password_Login: "Password login",
		Next_automatic_login: "Remember The Password",
		Sign_in: "Log In",
		Forget_the_password: "Forget Password?",
		New_user_registration: "Register",
		Mobile_scanner_secure_login: "Mobile scanner, secure login",

		app_msg_0: "Open",
		app_msg_1: "HYTX APP",
		app_msg_2: "Sweep login",
		ver_login_msg: "Please enter the correct cell phone number and password",

		login_was_successful: "Successful login！",
		login_was_fail: "Logon failure！",

		retrieve_password: "retrieve password", //"找回密码",
		authentication: "authentication", ////"验证身份",
		reset_passwords: "reset passwords", ////"重置密码",
		complete: "complete", ////"完成",
		next_step: "next step", //"下一步",
		new_password: "new password", //"新密码",
		confirm_password: "confirm password", //"确认密码",
		repass_success: "Congratulations! You have successfully retrieved your password!", //"恭喜您，找回密码成功！",//Congratulations! You have successfully retrieved your password! :"恭喜您，找回密码成功！",
		repass_tip1: "Please remember your new password", //"请牢记您的新密码",//Please remember your new password:"请牢记您的新密码",
		repass_tip2: "After seconds jump to the login page", //"秒后跳转到登录页",//After seconds jump to the login page :"秒后跳转到登录页",
		To_log_in: "To log in", //"去登录",
		repass_fial: "Failed to retrieve, please return and try again!", //"找回失败，请返回重试！",//Failed to retrieve, please return and try again!:"找回失败，请返回重试！",

		reg_success_1: "congratulations,",
		reg_success_2: "Successfully registered!",
		reg_success_3: "What would you like to do next?",
		reg_success_4: "After the opening of the warehouse, experience warehousing, logistics, transport, express and other one-stop services ",
		reg_success_5: "Please keep your account and password on the HYTX platform properly. Thank you for choosing HYTX.",
		reg_success_6: "Welcome to register",

	},

	...enStorage,
	...locale_en,
	// ...enUserCenter,
	// ...enBuyerCenter,
	// ...enSellerCenter,
	// ...enPartnerCenter,
	// ...enCommercialTenant,
	...enLocale
}

export default en