<template>

          <!-- <el-button slot class="pag_last_btn pag_frist_btn" size="small" @click="goPageNum('first')">{{$t('i18nn_f6e62f9642018cbb')}}</el-button> -->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.current_page"
            :page-sizes="pagination.page_sizes"
            :page-size="pagination.page_size"

            layout="prev, pager, next, slot, total, jumper, sizes"
            :total="isNaN(pagination.total)?0:pagination.total">
            <!-- <span slot>11</span> -->
            <!-- <el-button slot class="pag_last_btn" size="small" @click="goPageNum('last')">{{$t('i18nn_e56ddb7ad8d6586e')}}</el-button> -->
          </el-pagination>



</template>

<script>


export default {

  name: 'HyPagePagination',

  //meta信息seo用
  // metaInfo: {
  //     title: '互易天下-厂家共享平台-求购大厅-棉', // set a title
  //     meta: [{                 // set meta
  //       name: '互易天下-求购大厅-棉',
  //       content: '互易天下-厂家共享平台-求购大厅-棉'
  //     }],
  //     // link: [{                 // set link
  //     //   rel: 'asstes',
  //     //   href: 'https://assets-cdn.github.com/'
  //     // }]
  //   },
  created() {
    // this.getPageData();
    // console.log("created");
    // console.log(this);
    // this.$parent.mNavActiveIndex = '3';
    // this.init();
  },
  props: {
    // value: {
    //    // 绑定的值
    //    type: [Number, String, Boolean],
    //    default: true
    //  },
    pagination:{
        current_page: 1,
        page_sizes: [5, 10, 20, 30],
        page_size: 10,
        total:0
      },
  },
  data () {
    return {
      // activeIndex: '1',
      //   activeIndex2: '1',
      // //loading,表格数据
      // loading: false,
      // //表格数据
      // tableData: [],
      //分页数据
      // pagination:{
      //   current_page: 1,
      //   page_sizes: [5, 10, 20, 30],
      //   page_size: 10,
      //   total:0
      // },
      //查询，排序方式
      // filterData:{
      //   "sort": "id_",//排序字段
      //   "order": "desc",//desc降序，asc升序

      //   // "classic": "",//分类
      //   // "goodsName":"",//品名
      //   // "pickType":"",//采摘类型
      //   // "batchNumber":"",//批号
      //   // "color":"",//颜色
      //   // "thanStrong":"",//比强
      //   // "incompatibleMicronaire":"",//马克隆值
      //   // "goodResurgence":"",//回潮
      //   // "impurityWeight":"",//含杂
      //   // "name1": "",//交货地
      //   // "name2": "",//仓库
      //   // "goodsPrice_min": "",//价格区间最小
      //   // "goodsPrice_max": "",//价格区间最大
      // }
    }
  },
  methods: {
    // toAdd(){
    //   this.$router.push({name:'ReleaseBuy'});
    // },

    // handleSelect(key, keyPath) {
    //     console.log(key, keyPath);
    //   },

    //每页显示数目变化
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        //分页的条数
        this.pagination.page_size = val;
        // 请求数据
        // console.log("this.pagination.page_size",this.pagination.page_size);
        // this.getPageData();
        // 这里手动触发一下 分页更新 事件，并且把值传进去
        this.$emit('updatePageData', val)
      },
      //页数变化
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.current_page = val;
        // 请求数据
        // this.getPageData();
        // 这里手动触发一下 分页更新 事件，并且把值传进去
        this.$emit('updatePageData', val)
      },
      //请求分页数据
      // getPageData(){
      //   let _this = this;
      //   this.loading = true;
      //   this.$http.put(this.$urlConfig.SellerCotton,{

      //       "sort": this.filterData.sort,
      //       "order": this.filterData.order,
      //       "offset": (this.pagination.current_page-1)*this.pagination.page_size, //当前页
      //       "limit": this.pagination.page_size, //当前页显示数目
      //       // "pageIndex": this.pagination.current_page, //当前页
      //       // "pageSize": this.pagination.page_size, //当前页显示数目

      //       // "filter": "{}",
      //       // "memberId": 1111,
      //       "goodsState":"",//商品状态（0:下架 1:正常 2:在库待售 3:违规（禁售））
      //       "goodsVerify":"",//商品审核（0不通过，1通过，10审核中）
      //       "marketType":0,//市场类型（0 互易大宗，1 撮合集市）

      //       // "gcId":'0'
      //     }
      //   )
      //   .then(({data}) => {
      //     console.log("棉分页，请求成功");
      //     console.log(data);
      //     // this.$vux.loading.hide();
      //     this.loading = false;
      //     //表格显示数据
      //     this.tableData = data.rows;
      //     //当前数据总条数
      //     this.pagination.total = parseInt(data.total);
      //     //当前页数
      //     // this.pagination.current_page = parseInt(data.current);
      //     //当前页条数
      //     // this.pagination.page_size = parseInt(data.size);


      //   })
      //   .catch( (error) => {
      //     console.log(error);
      //     console.log("棉分页，请求失败");
      //     _this.loading = false;
      //   });
      // }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  // .el-pagination {

  //     // display: inline-block;
  //     // vertical-align: middle;
  //     // padding: 0;
  //     button {
  //       border: 1px solid #E6E6E6;
  //       padding: 0px 10px;
  //       // span {
  //       //   line-height: 25px;
  //       //   height: 25px;
  //       //   font-size: 12px;

  //       // }

  //     }
  //   }

    // .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
    //   border: 1px solid #E6E6E6;
    //   padding: 0px 10px;
    // }
</style>
