import Vue from 'vue'
// import store from '../vuex/store'
// import i18n from '@/i18n/i18n.js'
// import AxiosPlugin from '../axios/axios.js'
// Vue.use(AxiosPlugin, '$axios');
// import HYConfig from '../config/config.js'
// Vue.use(HYConfig)


// import {
// 	MessageBox,
// } from 'element-ui';

// Vue.prototype.$confirm = MessageBox.confirm;

const PickerDate = {
	pickerOptions_1() {
		return {
			shortcuts: [{
					text: '最近7天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '最近30天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '最近3个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}
			]
		}
	},
	pickerOptions_2() {
		return {
			shortcuts: [{
					text: 'Latest Week',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: 'Last Month',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}
			]
		}

	},
	// pickerOptions_3() {
	// 	return {
	// 		disabledDate(time) {
	// 			return time.getTime() < Date.now();
	// 		}
	// 	}
	// },
	pickerOptions_4() {
		return {
			shortcuts: [{
					text: '本月',
					onClick(picker) {
						picker.$emit('pick', [new Date(), new Date()]);
					}
				},
				{
					text: '今年至今',
					onClick(picker) {
						const end = new Date();
						const start = new Date(new Date().getFullYear(), 0);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '最近六个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setMonth(start.getMonth() - 6);
						picker.$emit('pick', [start, end]);
					}
				}
			]
		}
	},
	pickerOptions_5() {
		return {
			// disabledDate(time) {
			// 	return time.getTime() > Date.now();
			// },
			shortcuts: [{
				text: 'Today',
				onClick(picker) {
					picker.$emit('pick', new Date());
				}
			}, {
				text: 'Yesterday',
				onClick(picker) {
					const date = new Date();
					date.setTime(date.getTime() - 3600 * 1000 * 24);
					picker.$emit('pick', date);
				}
			}, {
				text: 'Latest Week',
				onClick(picker) {
					const date = new Date();
					date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
					picker.$emit('pick', date);
				}
			}]
		}
	}

}

export default {
	install: function(Vue) {
		Object.defineProperty(Vue.prototype, '$PickerDate', {
			value: PickerDate
		});
	}
}